import Avatar from "@atlaskit/avatar";
import Panel from "@atlaskit/panel";
import React from "react";
import { Col, Container, Row } from "react-grid-system";

import "./Persons.css";

const Persons = ({
  assignee,
  reporter,
  validator,
  expert,
  expertDsf,
  CDC_S,
  CDC_O,
  chef,
}) => {
  const validatorTest = { displayName: "BOUCEBHA Mohamed Charef Eddine" };

  const textTitle = {
    fontSize: 14,
    color: "#6b778c",
    textAlign: "left",
    fontWeight: "400",
    maxWidth: "35%",
    paddingTop: "1.5%",
    marginLeft: "-2%",
  };

  const textval = {
    fontSize: 14,
    color: "#172b4d",
    textAlign: "left",
    fontWeight: "400",
    paddingTop: "0.5%",
    float: "left",
  };
  const panelHeader = {
    fontSize: 14,
    color: "#0052CC",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "14px",
  };

  const Header = (
    <span className="personesHeader" style={panelHeader}>
      Personnes
    </span>
  );

  return (
    <div>
      <Panel
        header={Header}
        isDefaultExpanded={true}
        style={{ color: "#f4f5f7" }}
      >
        <Container>
          <Row>
            <Col style={textTitle}>Responsable:</Col>
            <Col style={textval}>
              <div>
                <div style={{ float: "left" }}>
                  <Avatar size="small" />
                </div>
                <div style={{ paddingTop: 5 }}> {assignee.displayName}</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col style={textTitle}>Rapporteur:</Col>
            <Col style={textval}>
              <div>
                <div style={{ float: "left" }}>
                  <Avatar size="small" />
                </div>
                <div style={{ paddingTop: 5 }}> {reporter.displayName}</div>
              </div>
            </Col>
          </Row>
          {validator && (
            <Row>
              <Col style={textTitle}>Dossier validé par:</Col>
              <Col style={textval}>
                <div>
                  <div style={{ float: "left" }}>
                    <Avatar size="small" />
                  </div>
                  <div style={{ paddingTop: 5 }}> {validator.displayName}</div>
                </div>
              </Col>
            </Row>
          )}
          {expert && (
            <Row>
              <Col style={textTitle}>Expert:</Col>
              <Col style={textval}>
                <div>
                  <div style={{ float: "left" }}>
                    <Avatar size="small" />
                  </div>
                  <div style={{ paddingTop: 5 }}> {expert.displayName}</div>
                </div>
              </Col>
            </Row>
          )}
          {expertDsf && (
            <Row>
              <Col style={textTitle}>Expert:</Col>
              <Col style={textval}>
                <div>
                  <div style={{ float: "left" }}>
                    <Avatar size="small" />
                  </div>
                  <div style={{ paddingTop: 5 }}> {expertDsf.displayName}</div>
                </div>
              </Col>
            </Row>
          )}
          {CDC_S && (
            <Row>
              <Col style={textTitle}>CDC Souscription:</Col>
              <Col style={textval}>
                <div>
                  <div style={{ float: "left" }}>
                    <Avatar size="small" />
                  </div>
                  <div style={{ paddingTop: 5 }}> {CDC_S.displayName}</div>
                </div>
              </Col>
            </Row>
          )}
          {CDC_O && (
            <Row>
              <Col style={textTitle}>CDC Orientation:</Col>
              <Col style={textval}>
                <div>
                  <div style={{ float: "left" }}>
                    <Avatar size="small" />
                  </div>
                  <div style={{ paddingTop: 5 }}> {CDC_O.displayName}</div>
                </div>
              </Col>
            </Row>
          )}
          {chef && (
            <Row>
              <Col style={textTitle}>Chef d'atelier:</Col>
              <Col style={textval}>
                <div>
                  <div style={{ float: "left" }}>
                    <Avatar size="small" />
                  </div>
                  <div style={{ paddingTop: 5 }}> {chef.displayName}</div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Panel>
    </div>
  );
};

export default Persons;
