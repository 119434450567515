import React from "react";
import CollapseComment from "../Comment/CollapseComment";
import Commentitem from "./CommentItem";

const ShowComment = ({ comments }) => {
  const DisplayComment = () => {
    if (comments.length > 5) {
      var listeCommment = comments.slice(comments.length - 5, comments.length);
      return listeCommment.map((com, i) => (
        <Commentitem comment={com} key={i} />
      ));
    }
    return comments.map((com, i) => <Commentitem comment={com} key={i} />);
  };

  const ShowMore = () => {
    if (comments.length > 5) {
      var listeCommment = comments.slice(0, comments.length - 5);
      var listeElemmentComment = listeCommment.map((com, i) => (
        <Commentitem comment={com} key={i} />
      ));
      return <CollapseComment elements={listeElemmentComment} />;
    }
    return null;
  };

  return (
    <div>
      {comments.length > 0 ? (
        <div>
          <ShowMore />
          <DisplayComment />
        </div>
      ) : (
        <div>
          <p>Pas encore de commentaires sur ce ticket.</p>
        </div>
      )}
    </div>
  );
};

export default ShowComment;
