import Button from "@atlaskit/button";
import Field from "@atlaskit/form";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@atlaskit/modal-dialog";
import Textfield from "@atlaskit/textfield";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { FormContext } from "../../../pages/FormContext";
import SelectUser from "../../SelectUser";

const UserSelector = ({
  field_id,
  field_label,
  val,
  required,
  hiden,
  description,
}) => {
  const [isOpen, setisOpen] = useState(false);
  const [value, setvalue] = useState("");
  const { handleChange, issue } = useContext(FormContext);
  console.log("label", field_label);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {}, []);
  const openModal = () => {
    setisOpen(!isOpen);
  };

  const change = (event) => {
    setvalue(event.target.value);
  };

  const selectuser = (user) => {
    setvalue(user);
    setisOpen(false);
    handleChange(field_id, null, user.username);
  };

  const chooseUser = () => {
    handleChange(field_id, null, value.username);
    setisOpen(false);
  };

  return (
    <div>
      <Field label={field_label} name={field_id} defaultValue="">
        {() => (
          <Fragment>
            <label name={field_id}>{field_label}</label>
            <div style={{ display: "flex" }}>
              <Textfield
                id={field_label}
                isCompact
                spacing="compact"
                data-testid="nativeFormValidationTest"
                value={value.name}
              />
              <Button
                iconBefore={<PeopleGroupIcon label="Star icon" size="small" />}
                appearance="default"
                onClick={openModal}
              ></Button>
            </div>
          </Fragment>
        )}
      </Field>
      {isOpen && (
        <Modal width="x-large">
          <ModalHeader>
            <ModalTitle>Sélecteur d'utilisateur</ModalTitle>
          </ModalHeader>
          <ModalBody className="modal-body">
            <SelectUser selectuser={selectuser} setUser={setvalue} />
          </ModalBody>
          <ModalFooter>
            <div className="buttonsContainer">
              <Button
                type="submit"
                appearance="link"
                style={{ float: "right" }}
                onClick={chooseUser}
              >
                Choisir
              </Button>
              <Button
                type="submit"
                appearance="link"
                style={{ float: "right" }}
                onClick={openModal}
              >
                Annuler
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default UserSelector;
