import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  frFR,
} from "@mui/x-data-grid";
import { v4 as uuid } from "uuid";
import "../../pages/User/users.css";
import Link from "@mui/material/Link";
import { padding } from "@mui/system";
import LongMenu from "../LongMenu";
import formatDate from "../../helpers/format-date-fr";

const Usertabl = ({ users, loading, changestate }) => {
  function setGroups(params) {
    console.log(params);
  }
  const columns = [
    {
      field: "fullname",
      headerName: "Nom complet",
      width: 250,
    },
    {
      field: "name",
      headerName: "Nom d'utilisateur",
      width: 250,
      renderCell: (params) => (
        <Link href={"/viewProfile?username=" + params.row.name}>
          {params.row.name}
        </Link>
      ),
    },
    {
      field: "group",
      headerName: "Nom du groupe",
      width: 250,
      renderCell: (params) => {
        return params.value.map((group) => (
          <div style={{ marginRight: "7px" }}>
            <Link href="#">{group}</Link>
          </div>
        ));
      },
    },
    { field: "active", headerName: "Actif", type: "boolean", width: 250 },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        return (
          <LongMenu
            username={params.row.name}
            state={params.row.active}
            changestate={changestate}
          />
        );
      },
      width: 250,
    },
  ];

  const rows = users.map((user) => {
    const fullname = user.firstname + " " + user.lastname;
    return {
      id: uuid(),
      fullname: fullname,
      name: user.name,
      group: user.group,
      active: user.active === "true" ? true : false,
      action: user.id_user,
    };
  });

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        initialState={{ pinnedColumns: { left: ["cle"] } }}
        loading={loading}
        loadingSpinnerSize="large"
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: "Liste des Utilisateurs-" + formatDate(new Date(), true),
          delimiter: ";",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
}

export default Usertabl;
