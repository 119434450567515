import Button from "@atlaskit/button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@atlaskit/modal-dialog";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import AddGroup from "./addGroup";
import "./profile.css";
import RemoveGroup from "./removeGroup";

const AssigneGroup = ({ username, setopenModal }) => {
  const [groups, setgroups] = useState([]);
  const [isOpen, setisOpen] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getgroups = async () => {
      const user = await UserService.searchUsers(username);
      setgroups(user[0].group);
    };
    getgroups();
  }, [username]);

  const handelClosModal = () => {
    setopenModal(false);
  };
  return (
    <Modal width="medium">
      <ModalHeader>
        <ModalTitle>Gérer les groupes d'utilisateurs</ModalTitle>
      </ModalHeader>
      <ModalBody className="modal-body">
        <AddGroup username={username} groups={groups} setgroups={setgroups} />
        <RemoveGroup
          username={username}
          groups={groups}
          setgroups={setgroups}
        />
      </ModalBody>
      <ModalFooter>
        <div className="buttonsContainer">
          <Button
            type="submit"
            appearance="link"
            style={{ float: "right" }}
            onClick={handelClosModal}
          >
            Annuler
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AssigneGroup;
