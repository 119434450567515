import axios from "axios";
import { encode as base64_encode } from "base-64";

class Sqlcustomfieldservice {
  getChild = async (idField, value, dpField) => {
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          "/plugin/getchild/" +
          idField +
          "/" +
          base64_encode(value) +
          "/" +
          base64_encode(JSON.stringify(dpField))
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  getSqlFieldsTypologie = async (idCustomField) => {
    return await axios.get(
      process.env.REACT_APP_API_URL + "/plugin/sqlcf/typologie/" + idCustomField
    );
  };

  getDF = async (fieldid) => {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/plugin/select/df/" + fieldid
    );
    const data = res.data;
    return data;
  };

  getOptions = async (field_id, df) => {
    const myObjStr = JSON.stringify(df);
    let dfEncoded = base64_encode(myObjStr);
    const res = await axios.get(
      process.env.REACT_APP_API_URL +
        "/plugin/select/resource/" +
        field_id +
        "/null/" +
        dfEncoded
    );
    const data = res.data;
    if (data) {
      const list = data
        .filter((d) => d.value !== "" && d.label !== "")
        .map((d) => ({
          value: d.value,
          label: d.label,
        }));
      return list;
    }
  };
}

export default new Sqlcustomfieldservice();
