import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import { SimpleTag as Tag } from "@atlaskit/tag";
import Lozenge from "@atlaskit/lozenge";
import "./checksRegionalBannk.css";
import IssueService from "../../../../services/IssueService";
import formatDate from "../../../../helpers/format-date-fr";
import Tablemessageerror from "../../../TableMessageError";

const ChecksRegionalBannk = ({ jql }) => {
  const [issues, setissues] = useState([]);
  const [loading, setloading] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      let jql = `project = DSC AND issuetype = Sinistre AND assignee in (touati.yassine) AND status not in (Clos, "Dossiers classés") AND assignee != Admin`;

      await IssueService.executeJql(jql)
        .then((respons) => {
          setissues(respons.data);
          setloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, []);

  const head = {
    cells: [
      {
        content: "Clé",
        width: 8,
      },
      {
        content: "Création",
        width: 10,
      },
      {
        content: "Rapporteur",
        width: 12,
      },
      {
        content: "Responsable",
      },
      {
        content: "Etat",
        width: 10,
      },
      {
        content: "Numéro de police",
        width: 10,
      },
      {
        content: "N° Sinistre",
        width: 10,
      },
      {
        content: "Nom et Prénom (Raison sociale)",
        width: 10,
      },
      {
        content: "Immatriculation véhicule",
        width: 10,
      },
      {
        content: "Date du sinistre",
        width: 10,
      },
      {
        content: "Lieu de sinistre",
        width: 10,
      },
    ],
  };

  const rows = issues.map((issue) => ({
    key: uuid(),
    cells: [
      {
        id: issue.key,
        key: issue.key,
        content: (
          <Link className="linkclass" to={"/issue/" + issue.key}>
            {issue.key}
          </Link>
        ),
      },
      {
        key: issue.created,
        content: formatDate(issue.created),
      },
      {
        key: issue.reporter,
        content: issue.reporter,
      },
      {
        key: issue.assignee,
        content: issue.assignee,
      },
      {
        key: issue.etat,
        content: <Lozenge appearance="inprogress">{issue.etat}</Lozenge>,
      },
      {
        key: issue.numero_police,
        content: issue.numero_police,
      },
      {
        key: issue.numero_sinistre,
        content: issue.numero_sinistre,
      },
      {
        key: issue.raison_sociale,
        content: issue.raison_sociale,
      },
      {
        key: issue.immatriculation_vehicule_txt,
        content: issue.immatriculation_vehicule_txt,
      },
      {
        key: issue.date_sinistre,
        content: formatDate(issue.date_sinistre),
      },
      {
        key: issue.lieu_sinistre,
        content: issue.lieu_sinistre,
      },
    ],
  }));

  return (
    <div className="tablecontentprocessed">
      <div className="dashboard-item-header-green">
        <h3 className="dashboard-item-titler">
          Résultats du filtre : DSC - Chèques envoyés par la caisse régionale
        </h3>
      </div>
      <DynamicTable
        className="tablecontent"
        head={issues.length > 0 ? head : null}
        rows={rows}
        defaultSortKey="number"
        defaultSortOrder="ASC"
        rowsPerPage={5}
        defaultPage={1}
        isLoading={loading}
        loadingSpinnerSize="large"
        emptyView={<Tablemessageerror />}
        isFixedSize="true"
      />
    </div>
  );
};
export default ChecksRegionalBannk;
