import React, { useContext, Fragment, useState, useEffect } from "react";
import { FormContext } from "../../pages/FormContext";
import Textfield from "@atlaskit/textfield";
import Form, { Field } from "@atlaskit/form";
import "./Input.css";
import ReactHtmlParser from "react-html-parser";

const InputNumeric = ({
  field_id,
  field_label,
  required,
  val,
  hiden,
  description,
}) => {
  const { handleChange, issue } = useContext(FormContext);
  const [value, setvalue] = useState("");

  const DescriptionElement = () => (
    <div className="description">{ReactHtmlParser(description)}</div>
  );

  return (
    <div>
      {!hiden && (
        <div>
          <Field label={field_label} name={field_id} defaultValue="">
            {() => (
              <Fragment>
                <Textfield
                  id={field_label}
                  isCompact
                  spacing="compact"
                  type="number"
                  min={0}
                  data-testid="nativeFormValidationTest"
                  onChange={(event) => {
                    handleChange(field_id, event);

                    setvalue(event.target.value);
                  }}
                  value={value}
                />
                <DescriptionElement />
              </Fragment>
            )}
          </Field>
        </div>
      )}
    </div>
  );
};

export default InputNumeric;
