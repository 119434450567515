import axios from "axios";
class ProjectService {
  async getProjectByUser(username) {
    return await axios.get(
      process.env.REACT_APP_API_URL + "/users?name=" + username
    );
  }
}

export default new ProjectService();
