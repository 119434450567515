import Button, { LoadingButton } from "@atlaskit/button";
import CommentIcon from "@atlaskit/icon/glyph/comment";
import { EditorState } from "draft-js";
import React, { useState } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import IssueService from "../../services/IssueService";
import UserService from "../../services/UserService";
import CommentEditor from "../Comment/CommentEditor";
import "./AddComment.css";

const Addcomment = ({ issuekey, setComments }) => {
  const [comment, setcomment] = useState("");
  const [showCommentButton, setshowCommentButton] = useState(true);
  const [showCommentFrom, setshowCommentFrom] = useState(false);
  const [disableSaveButton, setdisableSaveButton] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [users, setusers] = useState([]);
  const [editorState, seteditorState] = useState();

  const getusers = async (username) => {
    const users = await UserService.searchUsers(username);
    const trasfer = users.map((user) => {
      return {
        id: user.id_user,
        display: user.name,
      };
    });
    setusers(trasfer);
  };

  const displayCommentFrom = () => {
    setshowCommentButton(false);
    setshowCommentFrom(true);
  };

  const cancelCommentForm = () => {
    setshowCommentButton(true);
    setshowCommentFrom(false);
  };

  const saveComment = async () => {
    if (comment.length > 0) {
      setisloading(true);
      const data = {
        username: AuthenticationService.getCurrentUser().username,
        issuekey: issuekey,
        comment: comment,
      };
      await IssueService.addComment(data)
        .then((res) => {
          setComments(res.data.comments.filter((com) => !com.visibility));
          setcomment("");
          setisloading(false);
          seteditorState(EditorState.createEmpty());
          cancelCommentForm();
        })
        .catch((err) => {
          console.log(err.response);
          setisloading(false);
        });
    }
  };

  const handelChanegComment = (event) => {
    setcomment(event.target.value);
    comment.length > 0
      ? setdisableSaveButton(false)
      : setdisableSaveButton(true);
  };

  const CommentButn = () => (
    <Button
      appearance="default"
      onClick={displayCommentFrom}
      style={{ marginTop: "20px" }}
    >
      <div style={{ float: "left" }}>
        <CommentIcon label="Commentaire" size="medium" />
      </div>
      Commentaire
    </Button>
  );

  return (
    <div className="addComment">
      {showCommentButton ? <CommentButn /> : null}
      {showCommentFrom ? (
        <div className="CommentSection">
          <CommentEditor
            comment={comment}
            setcomment={setcomment}
            editorState={editorState}
            seteditorState={seteditorState}
          />
          <div className="buttonSave">
            <LoadingButton
              onClick={saveComment}
              appearance="primary"
              isLoading={isloading}
              isDisabled={comment.length > 0 ? false : true}
            >
              Ajouter
            </LoadingButton>
            <Button onClick={cancelCommentForm} appearance="link">
              Annuler
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Addcomment;
