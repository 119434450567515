import axios from "axios";
import AuthenticationService from "./AuthenticationService";

class GroupeService {
  async getGroups() {
    return await axios.get(process.env.REACT_APP_API_URL + "/group");
  }

  async assigneGroup(username, groups) {
    const data = {
      username: username,
      groupName: groups,
    };
    return await axios.post(
      process.env.REACT_APP_API_URL + "/users/addgroup",
      data
    );
  }

  async revokeGroups(username, groups) {
    const data = {
      username: username,
      groupName: groups,
    };
    console.log(data);
    return await axios.post(
      process.env.REACT_APP_API_URL + "/users/removegroup",
      data
    );
  }
}
export default new GroupeService();
