import axios from "axios";
import AuthenticationService from "./AuthenticationService";

// Add a request interceptor

axios.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    const token = "Bearer " + user.accessToken;
    config.headers.Authorization = token;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      error.response.headers["error-message"] === "Token expired" &&
      !originalRequest._retry
    ) {
      const user = JSON.parse(localStorage.getItem("user"));
      const refreshToken = user.refreshToken;
      await AuthenticationService.refresshToken(refreshToken);
      axios(originalRequest);
      window.location.reload(false);
    }
    return Promise.reject(error);
  }
);

class IssueService {
  async getIssueType(idproject) {
    return await axios
      .get(process.env.REACT_APP_API_URL + "/search/metadata/" + idproject)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async getFields(idProject, idIssueType) {
    return await axios.get(
      process.env.REACT_APP_API_URL +
        "/search/metadata/" +
        idProject +
        "/issuetype/" +
        idIssueType
    );
  }

  async createIssue(issue) {
    return await axios.post(
      process.env.REACT_APP_API_URL + "/search/addIssue",
      issue
    );
  }

  async getIssyeById(issueKey) {
    return await axios.get(
      process.env.REACT_APP_API_URL + "/search/" + issueKey
    );
  }

  async getSqlTypo(idCustomField) {
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          "/plugin/sqlcf/typologie/" +
          idCustomField
      )
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async getTransionScreen(idProject, idIssueType, idTransition) {
    return await axios.get(
      process.env.REACT_APP_API_URL +
        "/workflow/screen/" +
        idProject +
        "/" +
        idIssueType +
        "/" +
        idTransition
    );
  }

  async executeTransition(issueId, data) {
    console.log(data);
    return await axios.post(
      process.env.REACT_APP_API_URL + "/search/" + issueId + "/transitions",
      data
    );
  }

  async executeJql(jql) {
    return await axios.get(process.env.REACT_APP_API_URL + "/search/jql", {
      params: {
        query: jql,
      },
    });
  }

  async getAttacheFile(idAttache) {
    return await axios.get(
      process.env.REACT_APP_API_URL + "/search/attachment/" + idAttache
    );
  }

  async saveAttachment(idAttache, formData) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return await axios.post(
      process.env.REACT_APP_API_URL + "/search/addAttachement/" + idAttache,
      formData,
      config
    );
  }

  async addComment(data) {
    return await axios.post(
      process.env.REACT_APP_API_URL + "/search/comment",
      data
    );
  }

  sendcomment = async (issueId, comment) => {
    if (comment.length > 0) {
      const data = {
        username: AuthenticationService.getCurrentUser().username,
        issuekey: issueId,
        comment: comment,
      };
      await this.addComment(data)
        .then((res) => {
          console.log(res.data.comments);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  getimg = async () => {
    var config = {
      method: "get",
      url: "https://recette.ipassistance-dz.com/secure/attachment/472514/ipaDDLDiagrame.png",
      headers: {
        Authorization: "Basic YWRtaW46QGRtaW4vKi0xMjM=",
        Cookie:
          "JSESSIONID=C92428A94821E274226C73497AE554CD; atlassian.xsrf.token=BPPC-SPAN-TL46-3GTO_c7d4bfbdf201dc4e099a961fd539e53213c0ec46_lin",
      },
    };
    return await axios.get(axios(config));
  };
}
export default new IssueService();
