import axios from "axios";
import AuthenticationService from "./AuthenticationService";
import alias from "../helpers/alias.json";

class TransitionsService {
  async assigneeTransition(idIssue) {
    const data = {
      transition: {
        id: "151",
      },
      fields: {
        assignee: { name: AuthenticationService.getCurrentUser().username },
        customfield_15206: "Oui",
      },
    };
    return await axios.post(
      process.env.REACT_APP_API_URL + `/search/${idIssue}/transitions`,
      data
    );
  }

  async assignee(idIssue, username) {
    // customfield_15206: "Oui" prod,
    // customfield_14843: "Oui" recette,
    const data = {
      transition: {
        id: "151",
      },
      fields: {
        assignee: { name: username },
        customfield_15206: "Oui",
      },
    };
    return await axios.post(
      process.env.REACT_APP_API_URL + `/search/${idIssue}/transitions`,
      data
    );
  }

  async executeUpdateTrannstion(idIssue, fields) {
    const data = {
      transition: {
        id: "241",
      },
      fields: fields,
    };

    return await axios.post(
      process.env.REACT_APP_API_URL + `/search/${idIssue}/transitions`,
      data
    );
  }

  executeUpdateTrannstionById(idIssue, fields, idTransition) {
    const data = {
      transition: {
        id: idTransition,
      },
      fields: fields,
    };
    return axios.post(
      process.env.REACT_APP_API_URL + `/search/${idIssue}/transitions`,
      data
    );
  }
}
export default new TransitionsService();
