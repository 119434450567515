import React from "react";
import "./HeaderBanner.css";

const HeaderBanner = () => {
  return (
    <div className="headerBody">
      <header>
        <section className="headerSection">
          <p>
            Tous droits réservés. © 2022 Kepler Technologies SARL{"   "}
            {process.env.REACT_APP_ENV}
          </p>
        </section>
      </header>
    </div>
  );
};

export default HeaderBanner;
