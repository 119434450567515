import React, { useContext, Fragment, useState, useEffect } from "react";
import TextArea from "@atlaskit/textarea";
import { FormContext } from "../../pages/FormContext";
import Form, { Field } from "@atlaskit/form";
import "./Input.css";
import ReactHtmlParser from "react-html-parser";

const TextAreaForm = ({
  field_id,
  field_label,
  required,
  val,
  hiden,
  description,
}) => {
  const { handleChange, issue } = useContext(FormContext);
  const [value, setvalue] = useState("");

  useEffect(() => {}, []);

  const setinitValue = () => {
    if (issue) {
      // update From
      setvalue(issue.fields[field_id] ? issue.fields[field_id] : "");
      val = issue.fields[field_id] ? issue.fields[field_id] : "";
    }
  };

  const DescriptionElement = () => (
    <div className="description">{ReactHtmlParser(description)}</div>
  );

  return (
    <Field label={field_label} name={field_id} defaultValue="">
      {() => (
        <Fragment>
          <TextArea
            resize="auto"
            maxHeight="20vh"
            name="area"
            onChange={(event) => handleChange(field_id, event)}
            value={val}
          />
          <DescriptionElement />
        </Fragment>
      )}
    </Field>
  );
};

export default TextAreaForm;
