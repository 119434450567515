import React, { useState } from "react";
import { Row, Col } from "react-grid-system";
import { LoadingButton } from "@atlaskit/button";
import Form, { Field, FormFooter } from "@atlaskit/form";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const Searchform = ({ getUsers }) => {
  const [state, setstate] = useState({
    user: "",
    group: "",
    active: "",
  });
  const handleChange = (evt) => {
    const value = evt.target.value;
    console.log(value);
    setstate({
      ...state,
      [evt.target.name]: value,
    });
  };

  const search = () => {
    getUsers(state.user);
  };
  return (
    <div className="header-panel">
      <div className="searchHeader">
        <p
          style={{
            color: "#244fc9",
            paddingTop: "6%",
            paddingLeft: "1%",
            paddingBottom: "2%",
            marginBottom: "0px",
          }}
        >
          Recherche
        </p>
      </div>
      {/** 

      <form className="form">
        <Row className="headerForm">
          <Col>
            <FormControl fullWidth>
              <TextField
                id="user"
                label="Utilisateur"
                variant="standard"
                name="user"
                value={state.user}
                onChange={handleChange}
              />
            </FormControl>
          </Col>

          <Col>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="group"
                name="group"
                value={state.group}
                label="Groupe"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Col>

          <Col>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="active"
                value={state.active}
                name="active"
                label="Active"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>InActive</MenuItem>
              </Select>
            </FormControl>
          </Col>

          <Col>
            <LoadingButton
              appearance="primary"
              isLoading={false}
              onClick={search}
            >
              Recherche
            </LoadingButton>
          </Col>
        </Row>
      </form>
      */}
    </div>
  );
};

export default Searchform;
