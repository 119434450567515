import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import "./IssueTab.css";
import { ta } from "date-fns/locale";

const Issuetab = ({ projectId, issueType, isssueId }) => {
  const [screen, setScreen] = useState({});
  const [loading, setLoading] = useState(true);

  const textTitle = {
    fontSize: 13,
    color: "#97A0AF",
    textAlign: "left",
    fontWeight: "bold",
  };

  const textval = {
    fontSize: 12,
    color: "#5E6C84",
    textAlign: "left",
  };

  useEffect(() => {
    console.log("project", projectId);
    console.log("issueType", issueType);
    const fetchData = async () => {
      const res = await axios(
        process.env.REACT_APP_API_URL +
          "/screen/display/" +
          projectId +
          "/" +
          issueType +
          "/" +
          isssueId
      );
      console.log("res", res);

      setScreen(res.data[0]);
      setLoading(false);
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const circleColor = (val) => {
    // eslint-disable-next-line no-mixed-operators
    if (0 <= val && val <= 24) return "circle";
    // eslint-disable-next-line no-mixed-operators
    if (24.1 <= val && val <= 36.0) return "circle-yellow ";
    // eslint-disable-next-line no-mixed-operators
    if (36.1 <= val && val <= 48.0) return "circle-orange";
    if (48.1 <= val) return "circle-red";
  };

  function isNumeric(num) {
    num = "" + num;
    return !isNaN(num) && !isNaN(parseFloat(num));
  }

  const getTwoDecimals = (val) => {
    //Vérifier si c'est un nombre
    if (!isNumeric(val)) return val;

    //Vérifier si c'est pas égal à 0
    if (val === 0) return 0;

    //Vérifier si c'est un nombre avec patie décimale
    if (val % 1 !== 0) {
      return Number(val).toFixed(2);
    } else return val;
  };
  const ContentFields = (tab) => {
    if (tab.jiraFields.length > 0) return true;
    else return false;
  };

  const evaluationService = (tab) => {
    if (
      tab.name === "Evaluation du service" &&
      tab.jiraFields[0].allowedValues.value === "None" &&
      tab.jiraFields[1].allowedValues.value === "None"
    ) {
      console.log("service", tab);
      return false;
    } else return true;
  };

  const tabs = () => {
    const form = (
      <Tabs>
        <div className="infoTitle">
          <TabList>
            {screen.tabs
              .filter(
                (tab) =>
                  tab.id !== 12202 &&
                  tab.id !== 10811 &&
                  tab.id !== 10812 &&
                  tab.id !== 14603 &&
                  tab.name !== "Utilisateurs" &&
                  tab.name !== "Expertise" &&
                  ContentFields(tab) &&
                  evaluationService(tab)
              )
              .map((tab) => {
                console.log(tab);
                return (
                  <div key={tab.id} className="infoTitleText">
                    <Tab key={tab.id}>{tab.name}</Tab>
                  </div>
                );
              })}
          </TabList>
        </div>
        {screen.tabs
          .filter(
            (tab) =>
              tab.id !== 12202 &&
              tab.id !== 10811 &&
              tab.id !== 10812 &&
              tab.id !== 14603 &&
              tab.name !== "Utilisateurs" &&
              tab.name !== "Expertise" &&
              ContentFields(tab) &&
              evaluationService(tab)
          )
          .map((tab) => {
            return (
              <TabPanel key={tab.id} className="tabPanelContainer">
                <Container className="infoContainer">
                  {tab.jiraFields
                    .filter(
                      (field) =>
                        field.type !== "Sélecteur de date" &&
                        field.type !== "Date Picker" &&
                        field.name !== "Etat d'avancement BDG" &&
                        field.name !== "Etat d'avancement" &&
                        field.name !== "Dossier validé par" &&
                        field.name !== "Date validation expertise" &&
                        field.name !== "Flag validation DG" &&
                        field.id !== "security"
                    )
                    .map((field) => {
                      let val = "";
                      if (Array.isArray(field.allowedValues)) {
                        field.allowedValues.forEach((element) => {
                          val = val.concat("", element.value);
                        });
                      } else {
                        if (field.allowedValues.hasOwnProperty("value")) {
                          val = field.allowedValues.value;
                        } else {
                          val = field.allowedValues;
                        }
                      }

                      return (
                        <Row key={field.name}>
                          <Col width="25%">
                            <div className="fieldName">{field.name}:</div>
                          </Col>
                          <Col style={textval}>
                            <div className="fieldVal">
                              {tab.name === "Délais d'intervention" ? (
                                <div className={circleColor(val)}></div>
                              ) : null}
                              {field.name === "Motif de rejet" ? (
                                <p
                                  style={{
                                    color: "red",
                                    marginTop: 0,
                                    fontWeight: 700,
                                  }}
                                >
                                  {val}
                                </p>
                              ) : (
                                getTwoDecimals(val)
                              )}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                </Container>
              </TabPanel>
            );
          })}
      </Tabs>
    );

    return form;
  };

  return <div>{!loading ? tabs() : <p>loading </p>}</div>;
};

export default Issuetab;
