import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Settings } from "@atlaskit/atlassian-navigation";
import Popup from "@atlaskit/popup";
import { ButtonItem, PopupMenuGroup, Section } from "@atlaskit/menu";

const Settingsitem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const redirectToUsers = () => history.push("/users");
  const NestedPopup = () => {
    return (
      <div>
        <Section>
          <ButtonItem onClick={redirectToUsers}>
            Gestion des utilisateurs
          </ButtonItem>
        </Section>
      </div>
    );
  };
  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-start"
      content={() => <NestedPopup />}
      trigger={(triggerProps) => (
        <Settings
          {...triggerProps}
          onClick={() => setIsOpen(!isOpen)}
          tooltip="Product settings"
        />
      )}
    />
  );
};

export default Settingsitem;
