import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import React, { useContext, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { FormContext } from "../../pages/FormContext";
import "./Input.css";

const Selectelement = ({
  field_id,
  field_label,
  val,
  required,
  hiden,
  description,
}) => {
  const { handleChange, issue } = useContext(FormContext);
  const [value, setvalue] = useState({});

  useEffect(() => {
    initState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initState = () => {
    if (issue) {
      // update From
      const issueOpetion = issue.fields[field_id];
      if (issue.fields[field_id]) {
        const defaultOption = {
          value: issueOpetion.id,
          label: issueOpetion.value,
        };
        setvalue(defaultOption);
      }
    }
  };

  const options = () => {
    if (val) {
      const list = val.map((d) => ({
        id: d.value,
        value: d.id,
        label: d.value,
      }));
      return list;
    } else {
      return [];
    }
  };

  const DescriptionElement = () => (
    <div className="description">{ReactHtmlParser(description)}</div>
  );

  return (
    <div>
      {!hiden && (
        <Field label={field_label} name={field_id} defaultValue="">
          {() => (
            <div>
              <Select
                inputId={field_label}
                spacing="compact"
                value={value}
                className="single-select"
                classNamePrefix="react-select"
                options={options()}
                placeholder="Choisissez une valeur"
                onChange={(event) => {
                  setvalue(event);
                  handleChange(field_id, event);
                }}
              />
              <DescriptionElement />
            </div>
          )}
        </Field>
      )}
    </div>
  );
};

export default Selectelement;
