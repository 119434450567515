import React from "react";
import "../pages/Home/Homepage.css";

const Tablemessageerror = () => {
  return (
    <div className="no-body">
      Aucun{" "}
      <a href="/search" className="linkclass">
        {" "}
        ticket{" "}
      </a>{" "}
      correspondant.
    </div>
  );
};

export default Tablemessageerror;
