/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../components/Search/Header";
import Searchresult from "../../components/Search/SearchResult";
import IssueService from "../../services/IssueService";

const Search = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const param = queryParams.get("jql");

  const [jql, setjql] = useState("");
  const [project, setproject] = useState("");

  const [issues, setissues] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (param) {
      setjql(param);
      // renderRow(jql, "");
      renderRow(param, "");
    }
  }, []);

  const updatejql = (newVal) => {
    setjql(newVal);
  };

  const renderRow = async (jql, proj) => {
    setproject(proj);
    setloading(true);
    await IssueService.executeJql(jql)
      .then((respons) => {
        setissues(respons.data);
        setloading(false);
      })
      .catch((err) => {});
  };

  return (
    <div className="searchContent">
      <Header updatejql={updatejql} renderRow={renderRow} />
      <div>
        <Searchresult issues={issues} loading={loading} project={project} />
      </div>
    </div>
  );
};

export default Search;
