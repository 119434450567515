import React, { useEffect, useState } from "react";
import Button, { LoadingButton } from "@atlaskit/button";
import "./profile.css";
import Select from "@atlaskit/select";
import ContentWrapper from "../../ContentWrapper";
import GroupeService from "../../../services/GroupeService";
import async from "react-select/async";
import { Field } from "@atlaskit/form";

const AddGroup = ({ username, groups, setgroups }) => {
  const [options, setOptions] = useState([]);
  const [SelectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    GroupeService.getGroups()
      .then((response) => {
        const groupes = response.data.map((group) => {
          return {
            label: group.groupName,
            value: group.id_group,
          };
        });
        setOptions(groupes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handelChange = (valeu) => {
    setSelectedItems(valeu);
  };

  const submitGroups = async () => {
    const selectedgroups = SelectedItems.map((item) => item.label);
    await GroupeService.assigneGroup(username, selectedgroups)
      .then((response) => {
        const newArr = groups.concat(selectedgroups);
        setgroups(newArr);
      })
      .catch((erro) => {});
  };
  return (
    <div>
      <ContentWrapper>
        <label htmlFor="multi-select-example">
          Tapez pour commencer la recherche
        </label>
        <Select
          inputId="multi-select-example"
          className="multi-select"
          classNamePrefix="react-select"
          isMulti
          isSearchable={true}
          placeholder="Groupes"
          options={options}
          onChange={handelChange}
        />
      </ContentWrapper>
      <Button onClick={submitGroups}>Rejoignez les groupes sélectionnés</Button>
    </div>
  );
};

export default AddGroup;
