import React, { useState } from "react";
import { Search } from "@atlaskit/atlassian-navigation";
import "./NavMenu.css";
import IssueService from "../../services/IssueService";
import InlineDialog from "@atlaskit/inline-dialog";
import RowSearch from "./RowSearch";
import ShowAll from "./ShowAll";
import "./NavMenu.css";

const Searchitem = () => {
  const [valeu, setvaleu] = useState(null);
  const [jql, setjql] = useState(null);
  const [searchResult, setsearchResult] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  const onchange = async (event) => {
    setisOpen(true);
    var inputValue = event.target.value;
    setvaleu(event.target.value);

    if (inputValue.length > 1) {
      var keyList = [];
      keyList.push(inputValue);

      for (let index = 0; index < 8; index++) {
        keyList.push(inputValue + index);
      }

      var query = `key in (${keyList.join(
        ","
      )}) OR summary ~ "${inputValue}*" OR description ~ "${inputValue}*" ORDER BY lastViewed DESC`;
      setjql(query);
      await IssueService.executeJql(query)
        .then((respons) => {
          setsearchResult(respons.data);
          setisLoading(false);
        })
        .catch((err) => {});
    }
  };

  const content = (
    <div className="list-containt">
      <div>
        <div className="search-title">
          <p>TICKETS</p>
        </div>
        {searchResult
          .filter((elemenet, i) => i <= 4)
          .map((element, i) => (
            <RowSearch key={i} title={element.summary} issuekey={element.key} />
          ))}
        <ShowAll inputJql={jql} />
      </div>
    </div>
  );
  return (
    <InlineDialog
      id="dialog"
      onClose={() => {
        setisOpen(false);
      }}
      content={content}
      isOpen={isOpen}
    >
      <Search
        className="searchInput"
        placeholder="Recherche..."
        tooltip="Search"
        label="Search"
        value={valeu}
        onClick={onchange}
      />
    </InlineDialog>
  );
};

export default Searchitem;
