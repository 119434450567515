import React from "react";
import Panel from "@atlaskit/panel";

import "./StateProgress.css";

const StateProgress = ({ state = "" }) => {
  //chaine  d'affichage
  const tabState = ["ODR Envoyé", "ODR Validé", "Missioné", "Clos"];

  //Foncton de définition d'etat
  const defineClassState = (bruteState) => {
    const listState = bruteState.split("|");
    listState.pop();

    // Cas du dossier Envoyé
    if (listState[listState.length - 1] === "ODR envoyé") {
      return "ODR_ENVOYE";
    }

    // Cas du dossier validé
    if (listState[listState.length - 1] === "ODR validé") {
      return "ODR_VALIDE";
    }
    if (
      listState.length === 3 &&
      (listState[2] === "Missioné" || listState[2] === "Dossiers classés")
    ) {
      return "ODR_MISSIONE";
    }

    // Cas du dossier rejeté
    // if (!listState.includes("ODR validé") && listState.includes("Dossiers classés")) {
    //   return "ODR_REJETE";
    // }

    // Cas du dossier clos
    if (listState.length >= 4) {
      return "CLOS";
    }

    // Cas du dossier classé sans suite
    // if (listState.includes("ODR validé") && listState.includes("Dossiers classés")) {
    //   return "ODR_CLASSE_SANS_SUITE";
    // }
  };
  const etatGlobal = defineClassState(state);

  //fonction de Styling
  const handleStyleStateValide = (index) => {
    if (index === 0) {
      return {
        backgroundColor: "#41F462",
      };
    } else if (index === 1) {
      return {
        backgroundColor: "#F4C542",
      };
    } else {
      return {
        backgroundColor: "#808080",
      };
    }
  };

  const handleStyleStateMissionne = (index) => {
    if (index === 0) {
      return {
        backgroundColor: "#41F462",
      };
    } else if (index === 1) {
      return {
        backgroundColor: "#41F462",
      };
    } else if (index === 2) {
      return {
        backgroundColor: "#F4C542",
      };
    } else {
      return {
        backgroundColor: "#808080",
      };
    }
  };

  const handleStyleStateEnvoye = (index) => {
    if (index === 0) {
      return {
        backgroundColor: "#F4C542",
      };
    } else {
      return {
        backgroundColor: "#808080",
      };
    }
  };

  // const handleStyleStateRejete = (index) => {
  //   if (index === 0) {
  //     return {
  //       backgroundColor: "#41F462",
  //     };
  //   } else {
  //     return {
  //       backgroundColor: "#808080",
  //     };
  //   }
  // };

  const handleStyleStateClos = () => {
    return {
      backgroundColor: "#41F462",
    };
  };

  const handleStyleStateClasseSansSuite = () => {
    return {
      backgroundColor: "#41F462",
    };
  };

  const handleGlobalStyling = (index, etatGlobal) => {
    switch (etatGlobal) {
    case "ODR_ENVOYE":
      console.log("ODR_ENVOYE");
      return handleStyleStateEnvoye(index);

    case "ODR_VALIDE":
      console.log("ODR_VALIDE");
      return handleStyleStateValide(index);

    case "ODR_MISSIONE":
      console.log("ODR_MISSIONE");
      return handleStyleStateMissionne(index);
    case "CLOS":
      console.log("CLOS");
      return handleStyleStateClos();

    case "ODR_CLASSE_SANS_SUITE":
      console.log("ODR_CLASSE_SANS_SUITE");
      return handleStyleStateClasseSansSuite();

    default:
      console.log("No hope");
    }
  };

  const Header = <span className="panelHeader">Avancement des étapes</span>;

  return (
    <div style={{ marginLeft: "40px" }}>
      <Panel header={Header} isDefaultExpanded={true}>
        <div className="ContainerState">
          {tabState.map((state, index) => {
            return (
              <div>
                <div className="individualState">
                  <div className="drawState">
                    <div
                      className="circleSection"
                      style={handleGlobalStyling(index, etatGlobal)}
                    ></div>
                    {index < tabState.length - 1 && (
                      <div
                        className="rectangleSection"
                        style={handleGlobalStyling(index, etatGlobal)}
                      ></div>
                    )}
                  </div>
                </div>
                <div className="nameState">{state} </div>
              </div>
            );
          })}
        </div>
      </Panel>
    </div>
  );
};

export default StateProgress;
