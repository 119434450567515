import { Field } from "@atlaskit/form";
import { AsyncSelect } from "@atlaskit/select";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { FormContext } from "../../../pages/FormContext";
import "../Input.css";

const Autocomplitesql = ({ field_id, field_label, required, description }) => {
  const [data, setdata] = useState([]);
  const { handleChange, updateTabs, issue, initChilds } =
    useContext(FormContext);
  const [value, setvalue] = useState({});
  const [isPromaseEmpty, setisPromaseEmpty] = useState(false);

  const setinitvalue = () => {
    if (issue) {
      // update From
      const issueOpetion = issue.fields[field_id];
      const defaultOption = {
        value: issueOpetion,
        label: issueOpetion,
      };
      setvalue(defaultOption);
    }
  };

  useEffect(() => {
    setinitvalue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const promiseOptions = async (inputValue) => {
    if (inputValue.length >= 3) {
      handleChange(field_id, null, inputValue);
      const res = await getressource(inputValue);
      setdata(res);
      const mapOptions = res.map((el) => ({
        value: el.value,
        label: el.label,
        element: el,
      }));
      if (mapOptions.length === 0) {
        setvalue({
          value: inputValue,
          label: inputValue,
        });
        setisPromaseEmpty(true);
        updateTabs("empty");
      } else {
        setisPromaseEmpty(false);
        return mapOptions;
      }
    }
  };

  const getressource = async (input) => {
    let inputEncoded = base64_encode(input);
    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        "/plugin/autocompleteRessource/" +
        field_id +
        "/" +
        inputEncoded
    );
    return response.data;
  };

  const getSelectedOptional = (label) => {
    return data.filter((element) => element.label === label);
  };

  const DescriptionElement = () => (
    <div className="description">{ReactHtmlParser(description)}</div>
  );

  return (
    <div>
      <Field label={field_label} name={field_id} defaultValue="">
        {() => (
          <Fragment>
            <AsyncSelect
              isCompact
              spacing="compact"
              inputId={field_label}
              cacheOptions
              defaultOptions
              placeholder=""
              value={value}
              loadOptions={promiseOptions}
              onChange={(event) => {
                console.log("event", event);
                setvalue(event);
                if (!isPromaseEmpty) updateTabs(event.element.childs);
                handleChange(field_id, event);
              }}
            />
            <DescriptionElement />
          </Fragment>
        )}
      </Field>
    </div>
  );
};

export default Autocomplitesql;
