import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Button, { LoadingButton } from "@atlaskit/button";
import TransitionPage from "../../pages/Transition/TransitionPage";
import IssueService from "../../services/IssueService";

const Transitionbutton = ({
  title,
  idproject,
  issueType,
  transition,
  issueId,
  issueKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScreen, sethasScreen] = useState(true);
  const [model, setModel] = useState(null);
  const [isLoadingButton, setisLoadingButton] = useState(false);
  const [screen, setscreen] = useState(null);
  const [isDisabled, setisDisabled] = useState(true);
  const childFunc = React.useRef(null);

  useEffect(() => {
    getScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getScreen = async () => {
    await IssueService.getTransionScreen(idproject, issueType, transition)
      .then((response) => {
        const screenResponse = response.data;
        screenResponse === "No Screen"
          ? sethasScreen(false)
          : sethasScreen(true);
        setscreen(screenResponse);
      })
      .catch((error) => {
        throw error;
      });
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const showTranstion = () => {
    if (hasScreen === true) {
      return (
        <div>
          <Button onClick={toggleModal} isDisabled={isDisabled}>
            {title}
          </Button>
          <TransitionPage
            title={title}
            idProject={idproject}
            idIssueType={issueType}
            idTransition={transition}
            issueId={issueId}
            open={isOpen}
            toggle={toggleModal}
            screen={screen}
            issueKey={issueKey}
            showButton={setisDisabled}
          />
        </div>
      );
    } else {
      return (
        <div>
          <LoadingButton onClick={executeTranstionWithoutScreen}>
            {title}
          </LoadingButton>
        </div>
      );
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const executeTranstionWithoutScreen = async () => {
    setisLoadingButton(true);
    const data = {
      transition: {
        id: transition,
      },
      fields: {},
    };
    console.log(data);
    await IssueService.executeTransition(issueId, data)
      .then((response) => {
        setisLoadingButton(false);
        refreshPage();
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return <div>{showTranstion()}</div>;
};

export default Transitionbutton;
