import React, { Fragment, useEffect, useState } from "react";
import LoadingButton from "@atlaskit/button/loading-button";
import Form, { Field, FormHeader, FormSection } from "@atlaskit/form";
import TextField from "@atlaskit/textfield";
import { useHistory } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";
import CaptchaService from "../../services/CaptchaService";
import "./LoginForm.css";
import AlertComp from "../AlertComp";

const OtpForm = ({ username }) => {
  const history = useHistory();
  const [issend, setissend] = useState(false);
  const [erro, seterro] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [timer, setTimer] = useState(200);

  const onSubmit = async (data) => {
    let res = await AuthenticationService.otpVerfication(data.otp);
    try {
      console.log(res);
      if (res.accessToken) {
        CaptchaService.initTantative();
        history.push("/");
      } else {
        seterro(true);
      }
    } catch (err) {
      seterro(true);
    }
  };

  const sendOtp = async (event) => {
    setissend(true);
    let res = await AuthenticationService.sendOtp(username);
    setissend(false);
    setDisabled(true);
  };

  useEffect(() => {
    let interval;

    if (disabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [disabled]);

  useEffect(() => {
    if (timer === 0) {
      setDisabled(false);
      setTimer(200);
    }
  }, [timer]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${String(minutes).padStart(2, "0")} : ${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <section className="login-panel">
      <div className="FormHeader">
        <FormHeader>
          <p>Entrez le code de vérification.</p>
        </FormHeader>
      </div>
      {erro && <AlertComp message="OTP n'est pas valide" />}
      <div className="formContainer">
        <Form onSubmit={onSubmit}>
          {({ formProps, submitting }) => (
            <form {...formProps}>
              <FormSection style={{ marginTop: 0 }}>
                <div className="formFields">
                  <Field
                    aria-required={true}
                    name="otp"
                    label="Code de vérification"
                    defaultValue=""
                    isRequired={true}
                  >
                    {({ fieldProps }) => {
                      return (
                        <div className="textField">
                          <Fragment>
                            <TextField
                              className="fieldInput"
                              type="text"
                              {...fieldProps}
                            />
                          </Fragment>
                        </div>
                      );
                    }}
                  </Field>
                </div>
                <div>
                  Le code de validation a été envoyé à votre adresse e-mail.
                </div>
                <div>تم إرسال رمز التحقق إلى عنوان بريدك الإلكتروني</div>
                <div className="otp-menu-button">
                  <div className="loginButton">
                    <LoadingButton
                      type="submit"
                      appearance="primary"
                      isLoading={submitting}
                      id="connexionButton"
                    >
                      Connexion
                    </LoadingButton>
                  </div>
                  <div className="loginButton">
                    <LoadingButton
                      onClick={sendOtp}
                      appearance="primary"
                      isLoading={issend}
                      id="resent"
                      isDisabled={disabled}
                    >
                      {disabled
                        ? `Re-envoyer le code ${formatTime()}`
                        : "Re-envoyer le code"}
                    </LoadingButton>
                  </div>
                </div>
              </FormSection>
            </form>
          )}
        </Form>
      </div>
    </section>
  );
};

export default OtpForm;
