import Button from "@atlaskit/button";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import GroupeService from "../../../services/GroupeService";
import ContentWrapper from "../../ContentWrapper";

const RemoveGroup = ({ username, groups, setgroups }) => {
  const [selected, setselected] = useState([]);

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setselected(value);
  };

  const handelSubmit = async () => {
    await GroupeService.revokeGroups(username, selected)
      .then((response) => {
        removeElements();
      })
      .catch((erro) => {});
  };
  const removeElements = () => {
    const filteredArray = groups.filter(function (x) {
      return selected.indexOf(x) < 0;
    });

    console.log(filteredArray);
    setgroups(filteredArray);
  };
  return (
    <div>
      <ContentWrapper>
        <Select
          className="groups-table"
          value={selected}
          multiple
          native
          onChange={handleChangeMultiple}
        >
          {groups.map((group) => (
            <option key={group} value={group}>
              {group}
            </option>
          ))}
        </Select>
      </ContentWrapper>
      <Button onClick={handelSubmit}>Quittez les groupes sélectionnés</Button>
    </div>
  );
};

export default RemoveGroup;
