/* eslint-disable indent */
import Avatar from "@atlaskit/avatar";
import Button from "@atlaskit/button";
import Lozenge from "@atlaskit/lozenge";
import Link from "@mui/material/Link";
import React, { useEffect, useState } from "react";
import { Container } from "react-grid-system";
import Footer from "../../components/footer/Footer";
import AssigneGroup from "../../components/profil/Groups/assigneGroup";
import Propreties from "../../components/Propreties";
import formatDate from "../../helpers/format-date-fr";
import AuthenticationService from "../../services/AuthenticationService";
import UserService from "../../services/UserService";
import "./profile.css";

const Profile = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const username = queryParams.get("username");

  const [user, setuser] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    getuserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getuserProfile = async () => {
    const user = username
      ? username
      : AuthenticationService.getCurrentUser().username;
    const users = await UserService.getUserByName(user);
    setuser(users[0]);
    setisLoading(false);
  };

  const handleropenModal = () => {
    setopenModal(true);
  };

  const GroupButton = () => {
    <Button onClick={handleropenModal}>Gérer les groupes</Button>;
  };
  return (
    <div className="ContentPage">
      <section className="contentSection">
        {!isLoading && (
          <div>
            <div className="pageHeader">
              <div className="pageHeaderName">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Avatar size="large" />
                      </td>
                      <td>{user.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pagePanel">
              <div className="aui-page-panel-inner">
                <div className="aui-page-panel-nav"></div>
                <section className="auiPagePanelContent">
                  <div className="pageResume">Résumé</div>

                  <div className="group-aui ">
                    <div className="pageSectionTitle">Information</div>

                    <Container className="ContainerInfo">
                      <dl className="dlClass">
                        <dt className="dtClass">Avatar:</dt>
                        <dd className="ddClass">
                          <Avatar size="large" />
                          <br />
                          <Lozenge>GRAVATAR</Lozenge>
                        </dd>
                      </dl>

                      <dl className="dlClass">
                        <dt className="dtClass">Nom d'utilisateur:</dt>
                        <dd className="ddClass">{user.name}</dd>
                      </dl>

                      <dl className="dlClass">
                        <dt className="dtClass">Nom complet: </dt>
                        <dd className="ddClass">
                          {user.firstname + " " + user.lastname}
                        </dd>
                      </dl>
                      <dl className="dlClass">
                        <dt className="dtClass">Adresse électronique:</dt>
                        <dd className="ddClass">{user.emailAddress}</dd>
                      </dl>
                      <dl className="dlClass">
                        <dt className="dtClass">Mot de passe:</dt>
                        <dd className="ddClass">
                          <Link href="#">Modifier Mot de passe</Link>
                        </dd>
                      </dl>

                      <dl className="dlClass">
                        <dt className="dtClass">
                          Propriétés de l'utilisateur:{" "}
                        </dt>
                        <dd className="ddClass">
                          <Propreties propretiesList={user.properties} />
                        </dd>
                      </dl>

                      <dl className="dlClass">
                        <dt className="dtClass">Dernière connexion: </dt>
                        <dd className="ddClass">
                          {formatDate(user.lastConnexion, true, true)}
                        </dd>
                      </dl>
                      <dl className="dlClass">
                        <dt className="dtClass">Dernier échec de connexion</dt>
                        <dd className="ddClass">
                          {formatDate(user.lastLoginFailure, true, true)}{" "}
                        </dd>
                      </dl>
                      <dl className="dlClass">
                        <dt className="dtClass">
                          Échecs de connexion actuels:
                        </dt>
                        <dd className="ddClass">{user.loginFailures}</dd>
                      </dl>
                    </Container>

                    <div className="group-section">
                      <div className="pageSectionTitle">Groupes</div>
                      <div className="groupsButton">
                        {AuthenticationService.getclim("roles").includes(
                          "jira-administrators"
                        ) ? (
                          // eslint-disable-next-line indent
                          <GroupButton />
                        ) : // eslint-disable-next-line indent
                        // eslint-disable-next-line indent
                        null}
                      </div>

                      {openModal ? (
                        <AssigneGroup
                          username={user.name}
                          setopenModal={setopenModal}
                        />
                      ) : null}

                      <div>
                        <ul>
                          {user.group.map((group, i) => (
                            <li key={i} className="groupeList">
                              {group}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
      </section>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Profile;
