import React from "react";

import Footer from "../../components/footer/Footer";
import "./Homepage.css";
import ToBeProcessed from "../../components/home/homeDsc/toBeProcessed/ToBeProcessed";
import BlFolder from "../../components/home/homeDsc/blFolder/BlFolder";
import ToComplete from "../../components/home/homeDsc/toComplete/ToComplete";
import ChecksRegionalBannk from "../../components/home/homeDsc/cheksRegionalBank/ChecksRegionalBannk";
import DossiersBLAdversaires from "../../components/home/homeDsc/DossiersBLAdversaires/DossiersBLAdversaires";
import AuthenticationService from "../../services/AuthenticationService";
import HomeSideBare from "./HomeSideBare";

const HomePageDsc = () => {
  const projects = AuthenticationService.getclim("project");

  const HomeHeader = () => {
    return (
      <div className="homeHeader">
        <p>CNMA DSC - Espace BL</p>
      </div>
    );
  };

  const showSideBar = () => {
    if (projects.length >= 2) return true;
  };

  const tableStyle = () => {
    return showSideBar()
      ? { width: "82vw" }
      : { width: "95vw", marginLeft: "3vw" };
  };

  return (
    <div>
      <div>
        <div className="dashboardContentDSC">
          <table>
            <tr>
              <div layout="fluid">
                {showSideBar() && (
                  <td style={{ borderRight: "1px solid rgb(205, 205, 205)" }}>
                    <div medium={2}>
                      <div>
                        <HomeSideBare />
                      </div>
                    </div>
                  </td>
                )}
                <td>
                  <div medium={10}>
                    <div>
                      <div>{HomeHeader()}</div>
                    </div>
                    <div className="dashboard-shim"></div>
                    <div>
                      <ul className="column first sortable ui-sortable">
                        <li style={tableStyle()}>
                          <ToBeProcessed />
                        </li>
                        <li style={tableStyle()}>
                          <BlFolder />
                        </li>
                        <li style={tableStyle()}>
                          <ToComplete />
                        </li>
                        <li style={tableStyle()}>
                          <ChecksRegionalBannk />
                        </li>
                        <li style={tableStyle()}>
                          <DossiersBLAdversaires />
                        </li>
                      </ul>
                      <ul className="column second"></ul>
                      <ul className="column third"></ul>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageDsc;
