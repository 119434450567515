/* eslint-disable indent */
import React from "react";
import "./HomeSideBare.css";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useState } from "react";
import AuthenticationService from "../../services/AuthenticationService";

const HomeSideBare = () => {
  const projects = AuthenticationService.getclim("project");
  const history = useHistory();
  const redirectto = (url) => {
    history.push(url);
  };

  const [indexOfClickedItem, setindexOfClickedItem] = useState(
    history.location === "/dashboardDSC"
      ? "CNMA DSC - Espace BL"
      : "CNMA BDG - Espace de travail BL"
  );

  const items = [
    {
      name: "CNMA BDG - Espace de travail BL",
      label: "CNMA BDG - Espace de travail BL",
      dashboard: "/",
    },
    {
      name: "CNMA DSC - Espace BL",
      label: "CNMA DSC - Espace BL",
      dashboard: "/dashboardDSC",
    },
  ];

  const itemsBDG = [
    {
      name: "BDG - Espace de Travail SAA",
      label: "BDG - Espace de Travail SAA",
      dashboard: "/",
    },
    {
      name: "Stellantis - Espace CDC",
      label: "Stellantis - Espace CDC",
      dashboard: "/dashboardDSP",
    },
  ];

  const getSideBarItems = () => {
    if (projects.includes("Bris de glace")) return itemsBDG;
    else return items;
  };

  return (
    <div className="sidebar">
      <List disablePadding>
        {getSideBarItems().map(({ label, name, dashboard, ...rest }) => (
          <ListItem
            key={name}
            button
            {...rest}
            style={
              indexOfClickedItem === name
                ? {
                    fontWeight: "600",
                    background: "#E2E4E9",
                    borderRadius: "5px",
                    fontSize: "smaller !important",
                  }
                : { fontWeight: "400", fontSize: "smaller !important" }
            }
          >
            <ListItemText
              onClick={() => {
                setindexOfClickedItem(name);
                redirectto(dashboard);
              }}
            >
              <div
                onClick={() => {
                  setindexOfClickedItem(name);
                }}
                className="Element"
                style={
                  indexOfClickedItem === name
                    ? { fontWeight: "800", background: "#E2E4E9" }
                    : { fontWeight: "400" }
                }
              >
                {label}
              </div>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default HomeSideBare;
