/* eslint-disable indent */
import Button, { ButtonGroup, LoadingButton } from "@atlaskit/button";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import getnameProjectByUser from "../../helpers/project-by-user";
import UpdateIssue from "../../pages/Issue/update/UpdateIssue";
import UpdateIssueCnma from "../../pages/Issue/update/UpdateIssueCnma";
import UpdateIssueDsc from "../../pages/Issue/update/UpdateIssueDsc";
import UpdateIssueGiga from "../../pages/Issue/update/UpdateIssueGiga";
import AuthenticationService from "../../services/AuthenticationService";
import TransitionsService from "../../services/TransitionsService";
import Transitionbutton from "../Transaction/TransitionButton";
import "./IssueTab.css";
import UpdateIssueDsp from "../../pages/Issue/update/UpdateIssueDsp";
import UpdateIssueCAAR from "../../pages/Issue/update/UpdateIssueCAAR";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Menubuttons = ({
  issueId,
  IdIssueType,
  idproject,
  status,
  assignee,
  issue,
  issueTypeName,
}) => {
  const [transition, setTransition] = useState({});
  const [loading, setLoading] = useState(true);
  const [openModel, setopenModel] = useState(false);
  const [isLoadingButton, setisLoadingButton] = useState(false);
  const [openNotif, setopenNotif] = useState(false);
  const proejcts = AuthenticationService.getclim("project");

  const fetchData = async () => {
    const res = await axios(
      process.env.REACT_APP_API_URL +
        "/search/" +
        issue.key +
        "/transitions/" +
        AuthenticationService.getCurrentUser().username
    );
    setTransition(res.data);
    console.log(res.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    setopenModel(!openModel);
  };

  const executeAssignTranstion = async () => {
    setisLoadingButton(true);
    await TransitionsService.assignee(
      issueId,
      AuthenticationService.getCurrentUser().username
    )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setisLoadingButton(false);
          setopenNotif(true);
          refreshPage();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const updateIssueFrom = () => {
    switch (getnameProjectByUser()) {
      case "BDG":
        return (
          <div>
            <Button onClick={toggleModal}>Modifier</Button>
            <UpdateIssue
              openModel={openModel}
              toggleModal={toggleModal}
              issue={issue}
            />
          </div>
        );
      case "GIGA":
        return (
          <div>
            <Button onClick={toggleModal}>Modifier</Button>
            <UpdateIssueGiga
              openModel={openModel}
              toggleModal={toggleModal}
              issue={issue}
            />
          </div>
        );
      case "CAAR":
        return (
          <div>
            <Button onClick={toggleModal}>Modifier</Button>
            <UpdateIssueCAAR
              openModel={openModel}
              toggleModal={toggleModal}
              issue={issue}
            />
          </div>
        );
      case "CNMA":
        return (
          <div>
            <Button onClick={toggleModal}>Modifier</Button>
            <UpdateIssueCnma
              openModel={openModel}
              toggleModal={toggleModal}
              issue={issue}
            />
          </div>
        );
      default:
        return (
          <div className="homeHeader">
            <p>Unknown</p>
          </div>
        );
    }
  };

  const showTransotions = () => {
    //  BDG update issue transition
    if (
      status.name === "ODR rejeté" &&
      issueTypeName === "Dossier BDG" &&
      AuthenticationService.getCurrentUser().username === assignee.name
    ) {
      return updateIssueFrom();
    }

    // BDG  assign to me  transition
    if (
      status.name === "ODR rejeté" &&
      issueTypeName === "Dossier BDG" &&
      AuthenticationService.getCurrentUser().username !== assignee.name
    ) {
      return (
        <LoadingButton
          onClick={executeAssignTranstion}
          isLoading={isLoadingButton}
        >
          M'Attribuer
        </LoadingButton>
      );
    }

    if (issueTypeName !== "Dossier BDG") {
      return (
        transition.transitions
          .filter((trans) => !trans.name.startsWith("auto"))
          // eslint-disable-next-line array-callback-return
          .map((trans) => {
            if (trans.id === "871" && status.name === "Initié") {
              return updateFrame();
            }

            if (
              trans.id === "231" &&
              status.name === "Soumis CDC" &&
              AuthenticationService.getCurrentUser().username === assignee.name
            ) {
              return updateFormDsp();
            }
            if (trans.id !== "231") {
              return (
                <Transitionbutton
                  key={trans.id}
                  title={trans.name}
                  idproject={idproject}
                  issueType={IdIssueType}
                  transition={trans.id}
                  issueId={issueId}
                  issueKey={issue.key}
                />
              );
            }
          })
      );
    }
  };

  const handleCloseNotif = () => {
    setopenNotif(false);
  };

  const updateFrame = () => {
    return (
      <div>
        <Button onClick={toggleModal}>Modifier Dossier</Button>
        <UpdateIssueDsc
          openModel={openModel}
          toggleModal={toggleModal}
          issue={issue}
        />
      </div>
    );
  };

  const updateFormDsp = () => {
    return (
      <div>
        <Button onClick={toggleModal}>Modifier Dossier</Button>
        <UpdateIssueDsp
          openModel={openModel}
          toggleModal={toggleModal}
          issue={issue}
          idproject={idproject}
          issueType={IdIssueType}
        />
      </div>
    );
  };

  return (
    <div>
      <ButtonGroup>
        {!loading ? showTransotions() : <p>loading </p>}
      </ButtonGroup>
    </div>
  );
};

export default Menubuttons;
