import { DatePicker } from "@atlaskit/datetime-picker";
import Field from "@atlaskit/form";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { FormContext } from "../../pages/FormContext";

const DateSelecter = ({ field_id, field_label, val }) => {
  const { handleChange, issue } = useContext(FormContext);
  const [value, setvalue] = useState("");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initState = () => {
    if (issue) {
      // update From
      const defaultvalue = issue.fields[field_id];
      setvalue(dateFormat(defaultvalue));
    }
  };

  const dateFormat = (date) => {
    try {
      const dateString = date;
      const dateParts = dateString.split("/");

      const year = dateParts[2];
      const month = String(parseInt(dateParts[1]) - 1).padStart(2, "0");
      const day = dateParts[0].padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    } catch (eroor) {
      return date;
    }
  };

  return (
    <Field label={field_label} name={field_id} defaultValue="">
      {() => (
        <Fragment>
          <label name={field_id}>{field_label}</label>
          <DatePicker
            value={val}
            inputId={field_label}
            isCompact
            spacing="compact"
            dateFormat="DD/MM/YYYY"
            placeholder="Choisissez une date"
            onChange={(event) => {
              handleChange(field_id, event);
              setvalue(event);
            }}
          />
        </Fragment>
      )}
    </Field>
  );
};

export default DateSelecter;
