import React, { useState } from "react";
import useCollapse from "react-collapsed";
import "./collapse.css";

const CollapseComment = ({ elements }) => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [showMore, setshowMore] = useState(true);

  return (
    <div>
      {showMore && (
        <div
          className="datedAside"
          {...getToggleProps({
            onClick: () => {
              setExpanded((prevExpanded) => !prevExpanded);
              setshowMore(false);
            },
          })}
        >
          <span className="collapsed-comments-line"></span>
          <span className="collapsed-comments-line"></span>
          <span className="show-more-comments">
            {elements.length} commentaires plus anciens
          </span>
        </div>
      )}
      <section {...getCollapseProps()}>{elements}</section>
    </div>
  );
};

export default CollapseComment;
