/* eslint-disable indent */
import React from "react";
import Dossiersclassessanssuite from "../../components/home/DossiersClassesSansSuite";
import Dossiersclos from "../../components/home/DossiersClos";
import Orderrejete from "../../components/home/OrderRejete";
import Ordervalides from "../../components/home/OrderValides";
import Reparationorder from "../../components/home/ReparationOrder";
import Footer from "../../components/footer/Footer";
import "./Homepage.css";
import AuthenticationService from "../../services/AuthenticationService";
import HomePageDsc from "./HomePageDsc";
import HomeSideBare from "./HomeSideBare";
import getnameProjectByUser from "../../helpers/project-by-user";

const Homepage = () => {
  const projects = AuthenticationService.getclim("project");
  const HomeHeader = () => {
    switch (getnameProjectByUser()) {
      case "BDG":
        return (
          <div className="homeHeader">
            <p>BDG - Espace de Travail SAA</p>
          </div>
        );
      case "GIGA":
        return (
          <div className="homeHeader">
            <p>GIGA BDG - Espace de travail agence</p>
          </div>
        );
      case "CNMA":
        return (
          <div className="homeHeader">
            <p>CNMA BDG - Espace de travail BL</p>
          </div>
        );
      case "CAAR":
        return (
          <div className="homeHeader">
            <p>CAAR - Espace de travail CAAR</p>
          </div>
        );

      default:
        return (
          <div className="homeHeader">
            <p>CAAR - Espace de travail CAAR</p>
          </div>
        );
    }
  };

  const showDscDashboard = () => {
    console.log("porjects", projects);
    if (
      projects.length === 1 &&
      projects.includes("Déclaration des Sinistres  CNMA")
    )
      return true;
  };

  const showSideBar = () => {
    if (projects.length >= 2 || projects.includes("Bris de glace")) return true;
  };

  const tableStyle = () => {
    return showSideBar()
      ? { width: "84.3vw" }
      : { width: "95vw", marginLeft: "3vw" };
  };

  return (
    <div>
      <div>
        <div className="dashboardContent">
          <table>
            <tr>
              <div layout="fluid">
                {showSideBar() && (
                  <td style={{ borderRight: "1px solid rgb(205, 205, 205)" }}>
                    <div medium={1}>
                      <div>
                        <HomeSideBare />
                      </div>
                    </div>
                  </td>
                )}

                <td>
                  <div medium={10}>
                    {showDscDashboard() ? (
                      <HomePageDsc />
                    ) : (
                      <div>
                        <div>
                          <div>{HomeHeader()}</div>
                        </div>
                        <div className="dashboard-shim"></div>
                        <div>
                          <ul
                            className="column first sortable ui-sortable"
                            style={{ paddingLeft: "5px" }}
                          >
                            <li style={tableStyle()}>
                              <Reparationorder />
                            </li>

                            <li style={tableStyle()}>
                              <Ordervalides />
                            </li>

                            <li style={tableStyle()}>
                              <Orderrejete />
                            </li>
                            <li style={tableStyle()}>
                              <Dossiersclos />
                            </li>
                            <li style={tableStyle()}>
                              <Dossiersclassessanssuite />
                            </li>
                          </ul>
                          <ul className="column second"></ul>
                          <ul className="column third"></ul>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </div>
            </tr>
          </table>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
