/* eslint-disable indent */
import React, { useState } from "react";
import Input from "./elements/Input";
import DateSelecter from "./elements/DatePicker";
import TextAreaForm from "./elements/TextAreaForm";
import Selectelement from "./elements/Select";
import AttacheFile from "./elements/AttacheFile";
import SqlCustomField from "./elements/SqlCustomField";
import axios from "axios";
import Autocomplitesql from "./elements/sqlCustomFields/AutoCompliteSql";
import Spinner from "@atlaskit/spinner";
import CheckboxField from "./elements/CheckboxField";
import MultipleSelectNative from "./elements/MultipleSelectNative";
import UserSelector from "./elements/sqlCustomFields/UserSelector";
import InputNumeric from "./elements/InputNumeric";

const Element = ({
  field: {
    name,
    id,
    type,
    allowedValues,
    required,
    hiden,
    description,
    valeur,
  },
}) => {
  const [loading, setLoading] = useState(true);
  const [sqlType, setSqlType] = useState();

  const loadingspiner = () => (
    <div style={{ flexWrap: "wrap", alignContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDrection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner size="small" />
      </div>
    </div>
  );

  const getSqlFieldsTypologie = async (idCustomField) => {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/plugin/sqlcf/typologie/" + idCustomField
    );
    const data = res.data;
    setLoading(false);
    setSqlType(data.type);
  };

  const getsqlCustomField = () => {
    getSqlFieldsTypologie(id);
    if (!loading) {
      if (sqlType === "AUTOCOMPLETE") {
        return (
          <Autocomplitesql
            field_id={id}
            field_label={name}
            required={required}
            description={description}
          />
        );
      } else {
        return (
          <SqlCustomField
            field_id={id}
            field_label={name}
            required={required}
            val={allowedValues}
            description={description}
            selectedValeu={valeur}
          />
        );
      }
    } else {
      return loadingspiner();
    }
  };

  const buildElement = () => {
    switch (type) {
      case "Champ texte (mono-ligne)":
      case "Text Field (single line)":
        return (
          <Input
            field_id={id}
            field_label={name}
            required={required}
            val={allowedValues}
            hiden={hiden}
            description={description}
          />
        );
      case "SQL Customfield":
        return getsqlCustomField();
      case "Sélecteur de date":
      case "Date Picker":
        return (
          <DateSelecter
            field_id={id}
            field_label={name}
            required={required}
            description={description}
            val={allowedValues}
          />
        );
      case "Champ texte (multi-lignes)":
      case "Text Field (multi-line)":
        return (
          <TextAreaForm
            field_id={id}
            field_label={name}
            required={required}
            val={allowedValues}
            hiden={hiden}
            description={description}
          />
        );
      case "Champ de système":
      case "System field":
        if (id === "attachment") {
          return (
            <AttacheFile
              field_id={id}
              field_label={name}
              required={required}
              description={description}
            />
          );
        } else {
          return (
            <TextAreaForm
              field_id={id}
              field_label={name}
              required={required}
              description={description}
            />
          );
        }
      case "Liste de choix (à choix unique)":
      case "Select List (single choice)":
        return (
          <Selectelement
            field_id={id}
            field_label={name}
            val={allowedValues}
            required={required}
            hiden={hiden}
            description={description}
          />
        );
      case "Liste de choix (à choix multiples)":
        return (
          <MultipleSelectNative
            field_id={id}
            field_label={name}
            val={allowedValues}
            required={required}
            hiden={hiden}
            description={description}
          />
        );
      case "Cases à cocher":
      case "Checkboxes":
        return (
          <CheckboxField
            field_id={id}
            field_label={name}
            val={allowedValues}
            required={required}
            hiden={hiden}
            description={description}
          />
        );
      case "Sélectionneur d'utilisateur (utilisateur unique)":
      case "User Picker (single user)":
        return (
          <UserSelector
            field_id={id}
            field_label={name}
            val={allowedValues}
            required={required}
            hiden={hiden}
            description={description}
          />
        );
      case "Champ numérique":
        return (
          <InputNumeric
            field_id={id}
            field_label={name}
            required={required}
            val={allowedValues}
            hiden={hiden}
            description={description}
          />
        );
      case "Attach File Customfield":
        return (
          <AttacheFile
            field_id={id}
            field_label={name}
            required={required}
            description={description}
          />
        );
      default:
        return <p></p>;
    }
  };

  return <div>{buildElement()}</div>;
};

export default Element;
