function compactString(stringText, length) {
  let ShrededString = "";

  if (stringText.length > length + 5) {
    ShrededString = stringText.substring(0, length) + "...";
  } else {
    ShrededString = stringText;
  }

  return ShrededString;
}

export default compactString;
