import React, { Component } from "react";
import Page, { Grid, GridColumn } from "@atlaskit/page";
import { Container, Row, Col } from "react-grid-system";
import Panel from "@atlaskit/panel";
import Header from "../../../components/showIssueComponents/Header";
import Menubuttons from "../../../components/showIssueComponents/MenuButtons";
import Infoissue from "../../../components/showIssueComponents/InfoIssue";
import Issuetab from "../../../components/showIssueComponents/IssueTab";
import Description from "../../../components/showIssueComponents/Description";
import Attachement from "../../../components/showIssueComponents/Attachement";
import Activity from "../../../components/showIssueComponents/Activity";
import Persons from "../../../components/showIssueComponents/Persons";
import Dates from "../../../components/showIssueComponents/Dates";
import axios from "axios";
import Spinner from "@atlaskit/spinner";
import projectavatar from "../../../images/projectavatar.png";
import projectavatarCNMA from "../../../images/projectavatarCNMA.png";
import projectavatarGIGA from "../../../images/projectavatarGIGA.png";
import projectavatarDSP from "../../../images/FiatLogo.png";
import projectavatarDSPOpel from "../../../images/Opel.png";
import projectavatarCAAR from "../../../images/CAAR.png";
import "./showIssue.css";
import IssueService from "../../../services/IssueService";
import Errorpage from "../../../components/exception/ErrorPage";
import StateProgress from "../../../components/StateProgress/StateProgress";
import StepProgressNew from "../../../components/StateProgress/StepProgressNew";

const panelHeader = {
  fontSize: 16,
  color: "#0052CC",
  textAlign: "left",
  fontWeight: "600",
  marginLeft: 38,
};

const HeaderInfo = <span style={panelHeader}>Informations</span>;

class Showissuepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
      error: false,
    };
  }

  getIssueById = async () => {
    await IssueService.getIssyeById(this.props.match.params.id)
      .then((res) => {
        this.setState({
          data: res.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  };

  getIssueType = async () => {
    IssueService.getIssueType(10200)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  componentDidMount() {
    this.getIssueById();
    this.getIssueType();
  }

  loading = () => (
    <div style={{ flexWrap: "wrap", alignContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDrection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner size="large" />
      </div>
    </div>
  );

  showStateProgress = () => {
    if (this.state.data.fields.project.name === "BDG") {
      return <StateProgress state={this.state.data.fields.customfield_10799} />;
    } else if (this.state.data.fields.project.name === "GIGA") {
      return <StateProgress state={this.state.data.fields.customfield_10799} />;
    } else {
      return <StateProgress state={this.state.data.fields.customfield_10799} />;
    }
  };

  projectlogo = () => {
    if (this.state.data.fields.project.key === "BDG") {
      return <img src={projectavatar} alt="Logo" />;
    }
    if (this.state.data.fields.project.key === "CAAR") {
      return <img src={projectavatarCAAR} width={40} height={40} alt="Logo" />;
    }
    if (this.state.data.fields.project.key === "GIGA") {
      return <img src={projectavatarGIGA} alt="Logo" />;
    }
    if (this.state.data.fields.project.key === "CNMA") {
      return <img src={projectavatarCNMA} alt="Logo" />;
    }
    if (this.state.data.fields.project.key === "DSC") {
      return <img src={projectavatarCNMA} alt="Logo" />;
    }
    if (this.state.data.fields.project.key === "DSF") {
      if (this.state.data.fields.customfield_11455 === "FIAT")
        return <img src={projectavatarDSP} alt="Logo" />;
      else
        return (
          <img src={projectavatarDSPOpel} width={40} height={40} alt="Logo" />
        );
    }
  };

  render() {
    if (this.state.loading) {
      return this.loading();
    } else {
      return (
        <div className="bodyIssue">
          {this.state.error && <Errorpage />}
          {!this.state.error && (
            <Page>
              <div className="issueHeader">
                <div>{this.projectlogo()}</div>
                <Header
                  summry={this.state.data.fields.summary}
                  projectName={this.state.data.fields.project.name}
                  IssueId={this.state.data.key}
                />
              </div>

              <div className="issueMenubuttons">
                <Menubuttons
                  issueId={this.state.data.id}
                  IdIssueType={this.state.data.fields.issuetype.id}
                  idproject={this.state.data.fields.project.id}
                  status={this.state.data.fields.status}
                  assignee={this.state.data.fields.assignee}
                  issue={this.state.data}
                  issueTypeName={this.state.data.fields.issuetype.name}
                />
              </div>

              <div className="issueTabs">
                <Grid layout="fluid">
                  <GridColumn medium={8}>
                    <Panel header={HeaderInfo} isDefaultExpanded={true}>
                      <Grid layout="fluid">
                        <GridColumn medium={8}>
                          <Infoissue
                            issueType={this.state.data.fields.issuetype}
                            issueState={this.state.data.fields.status}
                            security={this.state.data.fields.security}
                          />
                        </GridColumn>

                        <GridColumn medium={8}>
                          <Issuetab
                            projectId={this.state.data.fields.project.id}
                            issueType={this.state.data.fields.issuetype.id}
                            isssueId={this.state.data.id}
                          />
                        </GridColumn>
                        <GridColumn medium={8}>
                          <div className="state-progress">
                            <StepProgressNew
                              issueType={this.state.data.fields.issuetype.name}
                              status={this.state.data.fields.status.name}
                              activeStep={
                                this.state.data.fields.customfield_10030
                                  ? this.state.data.fields.customfield_10030
                                  : this.state.data.fields.customfield_10799
                              }
                              flagDG={this.state.data.fields.customfield_16001}
                              project={this.state.data.fields.project.key}
                            />
                          </div>
                        </GridColumn>
                      </Grid>
                    </Panel>
                  </GridColumn>

                  <GridColumn medium={4}>
                    <Container>
                      {/*Persssons*/}
                      <Row>
                        <Col>
                          <Persons
                            assignee={this.state.data.fields.assignee}
                            reporter={this.state.data.fields.reporter}
                            validator={this.state.data.fields.customfield_13700}
                            expert={this.state.data.fields.customfield_14766}
                            expertDsf={
                              this.state.data.fields.customfield_16610
                                ? this.state.data.fields.customfield_16610
                                : this.state.data.fields.customfield_14615
                            }
                            CDC_S={this.state.data.fields.customfield_11482}
                            CDC_O={this.state.data.fields.customfield_11483}
                            chef={this.state.data.fields.customfield_10032}
                          />
                        </Col>
                      </Row>
                      {/*  Dates*/}
                      <Row>
                        <Col>
                          <Dates
                            created={this.state.data.fields.created}
                            updated={this.state.data.fields.updated}
                            effect={this.state.data.fields.customfield_11102}
                            echeance={this.state.data.fields.customfield_11103}
                            validation={
                              this.state.data.fields.customfield_13500
                            }
                            sinistreDate={
                              this.state.data.fields.customfield_10021
                            }
                            expertiseDate={
                              this.state.data.fields.customfield_14626
                            }
                            admissionDate={
                              this.state.data.fields.customfield_11468
                            }
                            recupreDate={
                              this.state.data.fields.customfield_11476
                            }
                          />
                        </Col>
                      </Row>
                    </Container>
                  </GridColumn>
                </Grid>
              </div>
              <div className="issueAttachActivi">
                {/* Pieces joints  */}
                <Row className="row-ali" style={{ display: "block" }}>
                  <Attachement
                    attachment={this.state.data.fields.attachment}
                    issueKey={this.state.data.key}
                  />
                </Row>

                {/* Activité */}
                <Row className="row-ali">
                  <Activity
                    comment={this.state.data.fields.comment}
                    issuekey={this.state.data.key}
                  />
                </Row>

                {/*  Date and persons Grid*/}
              </div>
            </Page>
          )}
        </div>
      );
    }
  }
}

export default Showissuepage;
