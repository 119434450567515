/* eslint-disable indent */
import React, { Component } from "react";
import Button, { LoadingButton } from "@atlaskit/button";
import ContentWrapper from "../../../components/ContentWrapper";
import Select from "@atlaskit/select";
import axios from "axios";
import Element from "../../../components/Element";
import { FormContext } from "../../FormContext";
import Form, { FormFooter } from "@atlaskit/form";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import Banner from "@atlaskit/banner";
import ProjectService from "../../../services/ProjectService";
import SqlCustomFieldService from "../../../services/SqlCustomFieldService";
import { Link } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import Spinner from "@atlaskit/spinner";
import AuthenticationService from "../../../services/AuthenticationService";
import "./CreateIssuePage.css";
import CreateIssuePageBDG from "./BDG_Issue/CreateIssuePageBDG";
import CreateIssueFormGIGA from "./GIGA_Issue/CreateIssueFormGIGA";
import CreateIssueDSC from "./DSC_issue/CreateIssueDSC";
import CreateIssueFormCNMA from "./CNMA_Issue/CreateIssueFormCNMA";
import CreateIssueFormCAAR from "./CAAR_Issue/CreateIssuePageCAAR";
import AlertComp from "../../../components/AlertComp";

export default class CreateIssuePage extends Component {
  static contextType = FormContext;
  constructor(props) {
    super(props);
    this.state = {
      disableCreate: true,
      showSpin: false,
      project: [],
      loading: true,
      selectOptions: [],
      id: "",
      name: "",
      selectOptionsProject: [],
      idProject: "",
      nameproject: "",
      fields: [],
      tabs: [],
      formelements: [],
      open: false,
      error: null,
      successful: false,
      message: null,
      isLoadingButton: false,
      showsuivant: true,
      projectforom: null,
      showform: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getOptionsProject();
  }

  async getOptionsProject() {
    const res = await ProjectService.getProjectByUser(
      AuthenticationService.getCurrentUser().username
    );
    const data = res.data;
    console.log("projects ", data);
    const first = data[0];
    const listProject = first.projects;

    const options = listProject.map((d) => ({
      value: d.id_project,
      label: d.name,
    }));

    const projects = AuthenticationService.getclim("project");
    if (projects.includes("Bris de glace")) {
      this.setState(
        {
          project: listProject,
          selectOptionsProject: options,
          idProject: 10200,
          nameproject: "Bris de glace",
          name: "Dossier BDG",
          id: 10301,
        },
        () => {
          this.getIssueTypes(this.state.nameproject);
        }
      );
    }
    if (projects.includes("GIGA Bris de glace")) {
      this.setState(
        {
          project: listProject,
          selectOptionsProject: options,
          idProject: 10600,
          nameproject: "GIGA Bris de glace",
          name: "Dossier BDG",
          id: 10301,
        },
        () => {
          this.getIssueTypes(this.state.nameproject);
        }
      );
    }
    if (projects.includes("CNMA Bris de Glace")) {
      this.setState(
        {
          project: listProject,
          selectOptionsProject: options,
          idProject: 10500,
          nameproject: "CNMA Bris de Glace",
          name: "Dossier BDG",
          id: 10301,
        },
        () => {
          this.getIssueTypes(this.state.nameproject);
        }
      );
    }
    if (projects.includes("CAAR Bris de glace")) {
      this.setState(
        {
          project: listProject,
          selectOptionsProject: options,
          idProject: 11200,
          nameproject: "CAAR Bris de glace",
          name: "Dossier BDG",
          id: 10301,
        },
        () => {
          this.getIssueTypes(this.state.nameproject);
        }
      );
    }
  }

  async getIssueTypes(label) {
    let p = this.state.project.filter((pro) => pro.name === label)[0];
    const data = p.issueTypes;

    const options = data
      .filter((issue) => !issue.subtask && issue.display)
      .map((d) => ({
        value: d.id_issue_type,
        label: d.name,
      }));
    this.setState({ selectOptions: options });
  }

  async getFields() {
    this.setState({ showSpin: true });
    const res1 = await axios.get(
      process.env.REACT_APP_API_URL +
        "/screen/form/" +
        this.state.idProject +
        "/" +
        this.state.id
    );
    const data1 = res1.data;
    const screen = data1[0];

    this.sqlCustomFieldInitValue(screen.tabs);
    this.addHidentoFields(screen.tabs);
    this.setState({
      tabs: screen.tabs,
      disableCreate: false,
      showSpin: false,
      showsuivant: false,
    });
    this.saveFields();
  }

  addHidentoFields(tabs) {
    tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (
          field.name === "Autre Modèle" ||
          field.name === "Autre Marque" ||
          field.name === "Type du véhicule"
        ) {
          field.hiden = true;
        } else {
          field.hiden = false;
        }
      });
    });
  }

  getvalue(idField) {
    const field = this.state.formelements.filter((f) => f.id === idField);
    return field[0].value;
  }

  showMarqueAndAutre() {
    const vlMarque = this.getvalue("customfield_14821");
    const vlModele = this.getvalue("customfield_14822");
    const newtabs = this.state.tabs;

    if (vlMarque === "Autre") {
      newtabs.forEach((tab) => {
        tab.jiraFields.forEach((field) => {
          if (
            field.name === "Autre Modèle" ||
            field.name === "Autre Marque" ||
            field.name === "Type du véhicule"
          ) {
            field.hiden = false;
          }
        });
      });
    } else {
      if (vlMarque !== "Autre" && vlModele === "Autre") {
        newtabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            if (field.name === "Autre Modèle") {
              field.hiden = false;
            }
            if (field.name === "Autre Marque") {
              field.hiden = true;
            }
            if (field.name === "Type du véhicule") {
              field.hiden = false;
            }
          });
        });
      } else {
        newtabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            if (
              field.name === "Autre Modèle" ||
              field.name === "Autre Marque" ||
              field.name === "Type du véhicule"
            ) {
              field.hiden = true;
              if (
                field.name === "Autre Modèle" ||
                field.name === "Autre Marque"
              ) {
                field.allowedValues = "";
              }
            }
          });
        });
      }
    }
    this.setState({ tabs: newtabs });
  }

  async initValeu(tabs) {
    tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        // if element is sqlCustomField
        if (field.type === "SQL Customfield") {
          //  get typologie
          const typo = this.gettypo(field.id);

          if (typo.type === "SELECT") {
            // select allowedValue
            const df = SqlCustomFieldService.getDF(field.id).then(
              (response) => {
                return response.data;
              }
            );
            const value = SqlCustomFieldService.getOptions(field.id, df);
          }
        }
      });
    });
  }

  async gettypo(fieldid) {
    const typo = await SqlCustomFieldService.getSqlFieldsTypologie(fieldid);
    return typo;
  }

  handleCNMA(e) {
    if (e.label === "CNMA Bris de Glace") {
      this.setState({ name: "Dossier BDG", id: 10301 });
    }
    if (e.label === "Déclaration des Sinistres  CNMA") {
      this.setState({ name: "Sinistre", id: 10002 });
    }
    this.setDefaultIssueType();
  }

  handleChangeProject(e) {
    this.setState({ idProject: e.value, nameproject: e.label });
    this.getIssueTypes(e.label);
    this.handleCNMA(e);
  }

  handleChangeIssueType(e) {
    this.setState({ id: e.value, name: e.label });
  }

  getModel = async (car) => {
    const listModels = await SqlCustomFieldService.getOptions(
      "customfield_14822",
      car
    );

    return listModels;
  };

  handleChange = async (id, event, val) => {
    const newElements = [...this.state.formelements];

    if (id === "customfield_14821") {
      const car = [{ id: "customfield_14821", value: event.value }];
      const models = await this.getModel(car);
      const newTab = this.state.tabs;
      newTab.forEach((tab) =>
        tab.jiraFields
          .filter((field) => field.type === "SQL Customfield")
          .forEach((field) => {
            if (field.id === "customfield_14822") {
              field.allowedValues = models;
            }
          })
      );
      this.setState({ tabs: newTab });
    }

    newElements.forEach((field) => {
      if (id === field.id) {
        switch (field.type) {
          // eslint-disable-next-line
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "SQL Customfield":
            if (event) {
              field["value"] = event.value;
            } else {
              field["value"] = val;
            }
            break;
          case "Champ de système":
            if (id === "attachment") {
              field["value"] = val;
            } else {
              field["value"] = event.value;
            }
            break;
          case "Liste de choix (à choix unique)":
            field["value"] = { value: event.label };
            break;
          case "Liste de choix (à choix multiples)":
            field["value"] = { value: val };
            break;

          case "Sélecteur de date":
            field["value"] = event;
            break;
          default:
            field["value"] = event.target.value;
            this.changeFieldValinTab(id, event.target.value);
            break;
        }
      }
    });
    this.setState({ formelements: newElements });
    this.showMarqueAndAutre();
  };

  updateTabsState = (childs) => {
    this.updateChild(childs);
  };

  updateChild(childs) {
    let newTabs = this.state.tabs;
    let newFormElement = this.state.formelements;
    if (childs === "empty") {
      newTabs.forEach((tab) => {
        tab.jiraFields.forEach((field) => {
          if (
            field.name === "Numéro de police" ||
            field.name === "Date d'effet" ||
            field.name === "Date d'échéance" ||
            field.name === "Nom" ||
            field.name === "Prénom" ||
            field.name === "Adresse du client"
          ) {
            field.allowedValues = "";
          }
        });
      });
    } else {
      childs.forEach((child) => {
        newTabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            let childId = "customfield_" + child.id;
            if (
              childId === field.id &&
              field.type !== "Liste de choix (à choix unique)"
            ) {
              field.allowedValues = child.value;
              newFormElement.forEach((el) => {
                if (el.id === field.id) {
                  el.value = field.allowedValues;
                }
              });
            }
          });
        });
      });
    }
    this.setState({ tabs: newTabs, formelements: newFormElement });
  }

  changeFieldValinTab(idField, val) {
    let newTabs = this.state.tabs;
    newTabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (field.id === idField) {
          field.allowedValues = val;
        }
      });
    });

    this.setState({ tabs: newTabs });
  }

  tabs() {
    const form = (
      <Tabs>
        <TabList>
          {this.state.tabs.length <= 1 ? (
            <Tab></Tab>
          ) : (
            this.state.tabs.map((tab) => {
              return <Tab>{tab.name}</Tab>;
            })
          )}
        </TabList>

        {this.state.tabs.map((tab) => {
          return (
            <TabPanel key={tab.id}>
              <div className="aui-page-panel-content">
                {tab.jiraFields.map((field) => {
                  return (
                    <div className="field-group">
                      <Element key={field.id} field={field} />
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          );
        })}
      </Tabs>
    );

    return form;
  }

  saveFields() {
    const elements = [];
    this.state.tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        const el = { id: field.id, value: "", type: field.type };
        elements.push(el);
      });
    });
    this.setState({ formelements: elements, loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState(
      { open: false, error: null, isLoadingButton: true },
      () => {}
    );

    let fields = {
      project: {
        id: this.state.idProject,
      },
      summary: "resumé",
      issuetype: {
        name: this.state.name,
      },
      reporter: { name: AuthenticationService.getCurrentUser().username },
    };

    this.state.formelements
      .filter((field) => field.id !== "attachment" && field.value !== "")
      .forEach((field) => {
        fields = { ...fields, [field.id]: field.value };
      });

    const file = this.state.formelements.filter(
      (field) => field.id === "attachment"
    );
    const data = { fields: fields };

    let formData = new FormData();

    if (file[0].value !== "") {
      file[0].value.forEach((fileElement) => {
        formData.append("file", fileElement);
      });
    }
    formData.append("issue", JSON.stringify(data));

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + "/search/addIssue",
        formData,
        config
      )
      .then(
        (response) => {
          if (response.data === "") {
            this.setState({ open: true, error: "-" }, () => {});
          } else {
            if ("errors" in response.data) {
              const erro = response.data.errors;
              // this.setState({ open: true, error: erro }, () => {});
            } else {
              this.setState({
                successful: true,
                message: response.data,
                isLoadingButton: false,
                open: false,
                error: "",
              });
              this.redirectToShowISsue(response.data.key);
            }
          }
        },
        (err) => {
          this.setState({
            open: true,
            error: err.response.data,
            isLoadingButton: false,
          });
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  showerror = () => {
    if (
      Array.isArray(this.state.error.errorMessages) &&
      this.state.error.errorMessages.length > 0
    ) {
      return (
        <div className="error-panel">
          <AlertComp message={this.state.error.errorMessages[0]} />
        </div>
      );
    } else {
      if (this.state.error.errors) {
        return (
          <div>
            {Object.entries(this.state.error.errors).map(
              ([key, value], index) => (
                <div key={key} className="error-panel-array">
                  {index === 0 && <AlertComp message={value} />}
                </div>
              )
            )}
          </div>
        );
      } else {
        return (
          <div className="error-panel">
            <AlertComp message={this.state.error} />
          </div>
        );
      }
    }
  };

  displayError = (errors) => {
    console.log(errors);
    this.setState({
      open: true,
      error: errors,
    });
    this.scroolUp();
  };

  scroolUp = () => {
    window.scrollTo(0, 0);
  };

  showSuccessful = () => {
    return (
      <Banner appearance="announcement" isOpen>
        <p>save issue with id </p>
        {this.state.message.id}
        <p> and key </p>
        {this.state.message.key}
      </Banner>
    );
  };

  async sqlCustomFieldInitValue(tabs) {
    tabs.forEach((tab) =>
      tab.jiraFields
        .filter((field) => field.type === "SQL Customfield")
        .forEach((field) => {
          const res = SqlCustomFieldService.getSqlFieldsTypologie(field.id);
          res
            .then((val) => {
              const typo = val.data.type;
              if (typo === "SELECT") {
                const depandFields = SqlCustomFieldService.getDF(field.id).then(
                  (dfs) => {
                    return dfs.map((df) => {
                      df.value = this.getCustomFieldValue(df.id);
                      return df;
                    });
                  }
                );
                const options = depandFields.then((val) => {
                  return SqlCustomFieldService.getOptions(field.id, val);
                });

                options.then((val) => {
                  field.allowedValues = val;
                });
              }
            })
            .catch((err) => console.log("error"));
        })
    );

    return tabs;
  }

  async sqlCustomFieldInitValueState() {
    const newTab = this.state.tabs;
    newTab.forEach((tab) =>
      tab.jiraFields
        .filter((field) => field.type === "SQL Customfield")
        .forEach((field) => {
          const res = SqlCustomFieldService.getSqlFieldsTypologie(field.id);
          res
            .then((val) => {
              const typo = val.data.type;
              if (typo === "SELECT") {
                const depandFields = SqlCustomFieldService.getDF(field.id).then(
                  (dfs) => {
                    return dfs.map((df) => {
                      df.value = this.getCustomFieldValue(df.id);
                      return df;
                    });
                  }
                );
                const options = depandFields.then((val) => {
                  return SqlCustomFieldService.getOptions(field.id, val);
                });

                options.then((val) => {
                  field.allowedValues = val;
                });
              }
            })
            .catch((err) => {});
        })
    );
    this.setState({ tabs: newTab });
  }

  getCustomFieldValue(idField) {
    const field = this.state.formelements.filter((elem) => elem.id === idField);
    return field[0].value;
  }

  loading = () => (
    <div style={{ flexWrap: "wrap", alignContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDrection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner size="large" />
      </div>
    </div>
  );

  redirectToShowISsue(id) {
    this.props.history.push("issue/" + id);
  }

  showProjectForm() {
    if (this.state.idProject === 10200 && this.state.id === 10301) {
      return (
        <CreateIssuePageBDG
          idProject="10200"
          projectName="Bris de glace"
          idIssueType="10301"
          nameIssueType="Dossier BDG"
          displayError={this.displayError}
          history={this.props.history}
        />
      );
    }
    if (this.state.idProject === 10600 && this.state.id === 10301) {
      return (
        <CreateIssueFormGIGA
          idProject="10600"
          projectName="GIGA Bris de glace"
          idIssueType="10301"
          nameIssueType="Dossier BDG"
          displayError={this.displayError}
          history={this.props.history}
        />
      );
    }
    if (this.state.idProject === 11200 && this.state.id === 10301) {
      return (
        <CreateIssueFormCAAR
          idProject="11200"
          projectName="CAAR Bris de glace"
          idIssueType="10301"
          nameIssueType="Dossier BDG"
          displayError={this.displayError}
          history={this.props.history}
        />
      );
    }

    if (this.state.idProject === 10500 && this.state.id === 10301) {
      return (
        <CreateIssueFormCNMA
          idProject="10500"
          projectName="CNMA Bris de Glace"
          idIssueType="10301"
          nameIssueType="Dossier BDG"
          displayError={this.displayError}
          history={this.props.history}
        />
      );
    }

    if (this.state.idProject === 10601 && this.state.id === 10002) {
      return (
        <CreateIssueDSC
          idProject="10700"
          projectName="Déclaration des Sinistres  CNMA"
          idIssueType="10002"
          nameIssueType="Sinistre"
          displayError={this.displayError}
          history={this.props.history}
        />
      );
    }
  }

  nextStape() {
    this.setState({
      showform: true,
      showsuivant: false,
    });
  }

  setDefaultProject() {
    const projects = AuthenticationService.getclim("project");
    if (projects.includes("Bris de glace")) {
      return {
        label: "Bris de glace",
        value: 10200,
      };
    }
    if (projects.includes("GIGA Bris de glace")) {
      return {
        label: "GIGA Bris de glace",
        value: 10600,
      };
    }

    if (projects.includes("Déclaration des Sinistres  CNMA")) {
      return {
        label: "Déclaration des Sinistres  CNMA",
        value: 10700,
      };
    }
    if (projects.includes("CNMA Bris de Glace")) {
      return {
        label: "CNMA Bris de Glace",
        value: 10500,
      };
    }

    if (projects.includes("CAAR Bris de glace")) {
      return {
        label: "CAAR Bris de glace",
        value: 10500,
      };
    }
  }

  setDefaultIssueType() {
    if (
      this.state.nameproject === "CNMA Bris de Glace" ||
      this.state.nameproject === "GIGA Bris de glace" ||
      this.state.nameproject === "Bris de glace" ||
      this.state.nameproject === "CAAR Bris de glace"
    ) {
      return {
        label: "Dossier BDG",
        id: 10301,
      };
    }
    if (this.state.nameproject === "Déclaration des Sinistres  CNMA") {
      return {
        label: "Sinistre",
        id: 10002,
      };
    }
  }

  render() {
    return (
      <div className="createContent">
        <table align="center">
          <tbody>
            <tr>
              <td>
                <div className="headerCreateIssue">
                  <p>Créer un ticket</p>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <section className="contentSection">
                  <div className="createPanel">
                    <ContentWrapper className="innerContent">
                      <div className="innerContentHead">
                        {this.state.open ? this.showerror() : null}
                        <label
                          htmlFor="single-select-example"
                          className="lineField"
                        >
                          Projet
                        </label>
                        <Select
                          inputId="Project"
                          className="singleSelect"
                          options={this.state.selectOptionsProject}
                          onChange={this.handleChangeProject.bind(this)}
                          defaultValue={this.setDefaultProject()}
                          placeholder="Projet"
                          spacing="compact"
                        />
                        <label
                          htmlFor="single-select-example"
                          className="lineField"
                        >
                          Type de ticket
                        </label>
                        <Select
                          value={this.setDefaultIssueType()}
                          inputId="IssueType"
                          className="singleSelect"
                          options={this.state.selectOptions}
                          onChange={this.handleChangeIssueType.bind(this)}
                          placeholder="Type de Ticket"
                          spacing="compact"
                        />
                      </div>
                      <div>
                        {this.state.showSpin && this.loading()}
                        <Form>
                          {({ formProps }) => (
                            <form {...formProps}>
                              {this.state.showform === true
                                ? this.showProjectForm()
                                : null}
                              <FormFooter align="start" className="formFooter">
                                <div className="buttonsContainer">
                                  {this.state.showsuivant ? (
                                    <LoadingButton
                                      id="Suivant_CreateIssue"
                                      className="auiButton"
                                      onClick={() => this.nextStape()}
                                    >
                                      Suivant
                                    </LoadingButton>
                                  ) : null}
                                </div>
                              </FormFooter>
                            </form>
                          )}
                        </Form>
                      </div>
                    </ContentWrapper>
                  </div>
                </section>
              </td>
            </tr>

            <tr>
              <td>
                <Footer />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
