import Breadcrumbs from "@atlaskit/breadcrumbs";
import { AtlassianIcon } from "@atlaskit/logo";
import PageHeader from "@atlaskit/page-header";
import { B200, B400 } from "@atlaskit/theme/colors";
import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Logomark = (
  <AtlassianIcon
    iconColor={B200}
    iconGradientStart={B400}
    iconGradientStop={B200}
  />
);

const Header = ({ summry, projectName, IssueId }) => {
  const breadcrumbs = (
    <Breadcrumbs onExpand={() => {}}>
      {/* <BreadcrumbsItem text={projectName} key="summry" /> */}
      {/* <BreadcrumbsItem text={IssueId} key="issueid" /> */}
      <table>
        <tbody>
          <tr>
            <td>
              <Link className="linkclass" to={"/issue/" + IssueId}>
                {projectName}
              </Link>
            </td>
            <td>/</td>
            <td>
              <Link className="linkclass" to={"/issue/" + IssueId}>
                {IssueId}
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </Breadcrumbs>
  );
  const Logomark = (
    <AtlassianIcon
      iconColor={B200}
      iconGradientStart={B400}
      iconGradientStop={B200}
    />
  );
  return (
    <div>
      <PageHeader breadcrumbs={breadcrumbs}>
        <span className="headerSummary">{summry}</span>
      </PageHeader>
    </div>
  );
};

export default Header;
