import Button from "@atlaskit/button/standard-button";
import EmptyState from "@atlaskit/empty-state";
import React from "react";
import { useHistory } from "react-router-dom";
import LockClosedImage from "../images/LockClosed.png";
import "./notfound.css";

const Notfoundpage = () => {
  const history = useHistory();

  const redirectToHome = () => history.push("/");

  return (
    <div className="first-container">
      <EmptyState
        header="Oups, vous avez trouvé un lien mort."
        primaryAction={
          <Button appearance="primary" onClick={redirectToHome}>
            Aller à la Page d'Accueil
          </Button>
        }
        imageUrl={LockClosedImage}
      />
    </div>
  );
};

export default Notfoundpage;
