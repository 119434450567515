/* eslint-disable indent */
import React, { Component } from "react";
import Button, { LoadingButton } from "@atlaskit/button";
import ContentWrapper from "../../../components/ContentWrapper";
import axios from "axios";
import Element from "../../../components/Element";
import { FormContext } from "../../FormContext";
import Form, { FormFooter } from "@atlaskit/form";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import Banner from "@atlaskit/banner";
import ProjectService from "../../../services/ProjectService";
import SqlCustomFieldService from "../../../services/SqlCustomFieldService";
import Spinner from "@atlaskit/spinner";
import AuthenticationService from "../../../services/AuthenticationService";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@atlaskit/modal-dialog";
import TransitionsService from "../../../services/TransitionsService";
import IssueService from "../../../services/IssueService";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./UpdateIssue.css";
import CommentField from "../../../components/elements/CommentField";
import async from "react-select/async";
import alias from "../../../helpers/alias.json";
import ConfigurationService from "../../../services/ConfigurationService";
import convertSize from "convert-size";
import maxFileSizeByList from "../../../helpers/max-file-size-ByList";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class UpdateIssueDsc extends Component {
  static contextType = FormContext;
  constructor(props) {
    super(props);
    this.state = {
      disableCreate: true,
      showSpin: false,
      loading: true,
      id: "",
      name: "",
      idProject: "",
      nameproject: "",
      fields: [],
      tabs: [],
      formelements: [],
      open: false,
      error: null,
      successful: false,
      message: null,
      isLoadingButton: false,
      openNotif: false,
      comment: "",
      MaxFileSize: 0,
      MaxFileSizeString: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async getFields() {
    const res1 = await axios.get(
      process.env.REACT_APP_API_URL + "/screen/form/" + 10700 + "/" + 10002
    );
    const data1 = res1.data;
    const screen = data1[0];

    var MaxFileSizeString = "";

    const res = await ConfigurationService.getConfig()
      .then((res) => {
        MaxFileSizeString = res.data.maxfilesize;
      })
      .catch((error) => {});

    MaxFileSizeString =
      MaxFileSizeString.slice(0, -2) + " " + MaxFileSizeString.slice(-2);

    const maxFileSize = convertSize(MaxFileSizeString);

    screen.tabs[0].jiraFields.push({
      id: "customfield_15001",
      name: "motif modification dossier",
      type: "Champ texte (mono-ligne)",
      description: null,
      required: false,
      hasDefaultValue: false,
      allowedValues: "motif",
    });

    this.addHidentoFields(screen.tabs);
    screen.tabs.forEach((tab) => {
      tab.jiraFields
        .filter(
          (field) =>
            field.type === "Champ texte (mono-ligne)" ||
            field.type === "Champ texte (multi-lignes)" ||
            field.type === "Text Field (single line)" ||
            field.type === "Text Field (multi-line)"
        )
        .forEach((field) => {
          field.allowedValues = this.props.issue.fields[field.id];
        });
    });

    this.setState({
      tabs: screen.tabs,
      disableCreate: false,
      showSpin: false,
      MaxFileSize: maxFileSize,
      MaxFileSizeString: MaxFileSizeString,
    });
    this.saveFields();
    this.sqlCustomFieldInitValueState();

    this.selectedValeuSql(screen.tabs, this.getFieldByName("Marque").id);
    this.selectedValeuSql(screen.tabs, this.getFieldByName("Modèle").id);

    this.selectedValeuSql(
      screen.tabs,
      this.getFieldByName("Companie d’assurance adverse").id
    );
    this.selectedValeuSql(screen.tabs, this.getFieldByName("Agence").id);

    this.selectedValeuSql(screen.tabs, this.getFieldByName("Garanties").id);

    this.selectedValeuSql(
      screen.tabs,
      this.getFieldByName("Marque du véhicule").id
    );
    this.selectedValeuSql(
      screen.tabs,
      this.getFieldByName("Modèle du véhicule").id
    );
  }
  async sqlCustomFieldInitValueState() {
    this.initSqlModel("Marque");
    this.initSqlModel("Companie d’assurance adverse");
    this.initSqlModel("Marque du véhicule");
    const garanties = this.getFieldByName("Garanties").id;
    const df = [
      {
        id: "customfield_11600",
        value: "",
      },
      {
        id: "customfield_14602",
        value: "",
      },
    ];
    const garantiesOptions = await SqlCustomFieldService.getOptions(
      garanties,
      df
    );
    this.setAllwedValues(garanties, garantiesOptions);

    await this.sqlCustomFieldChange(
      this.props.issue.fields[this.getFieldByName("Marque").id],
      this.getFieldByName("Marque").id,
      this.getFieldByName("Modèle").id
    );

    await this.sqlCustomFieldChange(
      this.props.issue.fields[
        this.getFieldByName("Companie d’assurance adverse").id
      ],
      this.getFieldByName("Companie d’assurance adverse").id,
      this.getFieldByName("Agence").id
    );

    await this.sqlCustomFieldChange(
      this.props.issue.fields[this.getFieldByName("Marque du véhicule").id],
      this.getFieldByName("Marque du véhicule").id,
      this.getFieldByName("Modèle du véhicule").id
    );
  }

  initSqlModel = async (fieldName) => {
    const listMarques = await SqlCustomFieldService.getOptions(
      this.getFieldByName(fieldName).id,
      []
    );
    this.setAllwedValues(this.getFieldByName(fieldName).id, listMarques);
  };

  setAllwedValues = (id, values) => {
    const newTab = this.state.tabs;
    newTab.forEach((tab) =>
      tab.jiraFields
        .filter((field) => field.type === "SQL Customfield")
        .forEach((field) => {
          if (field.id === id) {
            field.allowedValues = values;
          }
        })
    );
    this.setState({ tabs: newTab });
  };

  addHidentoFields(tabs) {
    tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (
          field.name === "Autre Modèle" ||
          field.name === "Autre Marque" ||
          field.name === "Autre" ||
          field.name === "Autre agence" ||
          field.name === "Type du véhicule" ||
          field.name === "motif modification dossier"
        ) {
          field.hiden = true;
        } else {
          field.hiden = false;
        }
      });
    });

    tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (field.type === "SQL Customfield") {
          field.valeur = null;
        }
      });
    });
  }

  selectedValeuSql(tabs, id) {
    tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (field.id === id) {
          field.valeur = {
            label: this.props.issue.fields[field.id],
            valeu: this.props.issue.fields[field.id],
          };
        }
      });
    });
  }

  getvalue(idField) {
    const field = this.state.formelements.filter((f) => f.id === idField);
    return field[0].value;
  }

  showMarqueAndAutre() {
    const vlMarque = this.getvalue("customfield_14821");
    const vlModele = this.getvalue("customfield_14822");
    const newtabs = this.state.tabs;

    if (vlMarque === "Autre") {
      newtabs.forEach((tab) => {
        tab.jiraFields.forEach((field) => {
          if (
            field.name === "Autre Modèle" ||
            field.name === "Autre Marque" ||
            field.name === "Type du véhicule"
          ) {
            field.hiden = false;
          }
        });
      });
    } else {
      if (vlMarque !== "Autre" && vlModele === "Autre") {
        newtabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            if (field.name === "Autre Modèle") {
              field.hiden = false;
            }
            if (field.name === "Autre Marque") {
              field.hiden = true;
            }
            if (field.name === "Type du véhicule") {
              field.hiden = false;
            }
          });
        });
      } else {
        newtabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            if (
              field.name === "Autre Modèle" ||
              field.name === "Autre Marque" ||
              field.name === "Type du véhicule"
            ) {
              field.hiden = true;
            }
          });
        });
      }
    }
    this.setState({ tabs: newtabs }, console.log(this.state.tabs));
  }

  showHidenField(
    idMarque,
    idModel,
    idAutreModel,
    idAutreMarque,
    idtypeVehicule
  ) {
    const vlMarque = this.getvalue(idMarque);
    const vlModele = this.getvalue(idModel);
    const newtabs = this.state.tabs;

    if (vlMarque === "Autre") {
      newtabs.forEach((tab) => {
        tab.jiraFields.forEach((field) => {
          if (
            field.id === idAutreModel ||
            field.id === idAutreMarque ||
            field.id === idtypeVehicule
          ) {
            field.hiden = false;
          }
        });
      });
    } else {
      if (vlMarque !== "Autre" && vlModele === "Autre") {
        newtabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            if (field.id === idAutreModel) {
              field.hiden = false;
            }
            if (field.id === idAutreMarque) {
              field.hiden = true;
            }
            if (field.id === idtypeVehicule) {
              field.hiden = false;
            }
          });
        });
      } else {
        newtabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            if (
              field.id === idAutreModel ||
              field.id === idAutreMarque ||
              field.id === idtypeVehicule
            ) {
              field.hiden = true;
              if (field.id === idAutreModel || field.id === idAutreMarque) {
                field.allowedValues = "";
              }
            }
          });
        });
      }
    }
    this.setState({ tabs: newtabs }, console.log(this.state.tabs));
  }

  async initValeu(tabs) {
    tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        // if element is sqlCustomField
        if (field.type === "SQL Customfield") {
          //  get typologie
          const typo = this.gettypo(field.id);
          console.log(typo);
          if (typo.type === "SELECT") {
            console.log("select");
            // select allowedValue
            const df = SqlCustomFieldService.getDF(field.id).then(
              (response) => {
                return response.data;
              }
            );
            const value = SqlCustomFieldService.getOptions(field.id, df);
          }
        }
      });
    });
  }

  async gettypo(fieldid) {
    const typo = await SqlCustomFieldService.getSqlFieldsTypologie(fieldid);
    return typo;
  }

  UNSAFE_componentWillMount() {
    this.getFields();
  }

  getModel = async (car, idCustomfieldModel) => {
    const listModels = await SqlCustomFieldService.getOptions(
      idCustomfieldModel,
      car
    );
    return listModels;
  };

  sqlCustomFieldChange = async (value, idMarque, idModel) => {
    let car = null;
    if (idModel === this.getFieldByName("Agence").id) {
      car = [
        { id: idMarque, value: value },
        { id: idMarque, value: value },
        { id: idMarque, value: value },
        { id: idMarque, value: value },
      ];
    } else {
      car = [{ id: idMarque, value: value }];
    }
    const models = await this.getModel(car, idModel);
    this.setAllwedValues(idModel, models);
  };

  getFieldByName = (name) => {
    var newField = null;
    this.state.tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (field.name === name) return (newField = field);
      });
    });
    return newField;
  };

  handleChange = async (id, event, val) => {
    const newElements = [...this.state.formelements];

    if (id === this.getFieldByName("Marque").id) {
      this.sqlCustomFieldChange(
        event.value,
        this.getFieldByName("Marque").id,
        this.getFieldByName("Modèle").id
      );

      this.setSelectedSqlValeu(this.getFieldByName("Modèle").id, null);
    }

    if (id === this.getFieldByName("Companie d’assurance adverse").id) {
      this.sqlCustomFieldChange(
        event.value,
        this.getFieldByName("Companie d’assurance adverse").id,
        this.getFieldByName("Agence").id
      );
      this.setSelectedSqlValeu(this.getFieldByName("Agence").id, null);
    }

    if (id === this.getFieldByName("Marque du véhicule").id) {
      this.sqlCustomFieldChange(
        event.value,
        this.getFieldByName("Marque du véhicule").id,
        this.getFieldByName("Modèle du véhicule").id
      );
      this.setSelectedSqlValeu(
        this.getFieldByName("Modèle du véhicule").id,
        null
      );
    }

    newElements.forEach((field) => {
      if (id === field.id) {
        switch (field.type) {
          // eslint-disable-next-line
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "SQL Customfield":
            if (event) {
              field["value"] = event.value;
            } else {
              field["value"] = val;
            }
            break;
          case "Champ de système":
            if (id === "attachment") {
              field["value"] = val;
            } else {
              field["value"] = event.value;
            }
            break;
          case "Liste de choix (à choix unique)":
          case "Select List (single choice)":
            field["value"] = { value: event.label };
            break;
          case "Liste de choix (à choix multiples)":
            field["value"] = { value: val };
            break;

          case "Sélecteur de date":
          case "Date Picker":
            field["value"] = event;
            break;
          case "Sélectionneur d'utilisateur (utilisateur unique)":
          case "User Picker (single user)":
            field["value"] = { name: val };
            break;

          case "Cases à cocher":
          case "Checkboxes":
            field["value"] = [{ value: event.target.name }];
            break;
          default:
            field["value"] = event.target.value;
            this.changeFieldValinTab(id, event.target.value);
            break;
        }
      }
    });

    this.setState({ formelements: newElements });
    //  Assuré Tab
    this.showHidenField(
      this.getFieldByName("Marque").id,
      this.getFieldByName("Modèle").id,
      alias.DSC.assure_autre_marque,
      alias.DSC.assure_autre_modele,
      alias.DSC.assure_Type_vehicule
    );

    // Tiers Tab
    this.showHidenField(
      this.getFieldByName("Marque du véhicule").id,
      this.getFieldByName("Modèle du véhicule").id,
      alias.DSC.tiers_autre_marque,
      alias.DSC.tiers_autre_modele,
      alias.DSC.tiers_Type_vehicule
    );

    // Sinstre Tab
    this.showHidenField(
      this.getFieldByName("Companie d’assurance adverse").id,
      this.getFieldByName("Agence").id,
      this.getFieldByName("Autre").id,
      this.getFieldByName("Autre agence").id,
      null
    );
  };

  changeFieldValinTab(idField, val) {
    let newTabs = this.state.tabs;
    newTabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (field.id === idField) {
          field.allowedValues = val;
        }
      });
    });

    this.setState({ tabs: newTabs });
  }

  changeAllowedValeu = (fieldId, value) => {
    const newTabs = this.state.tabs;
    newTabs.forEach((tab) => {
      tab.jiraFields
        .filter((field) => field.id === fieldId)
        .forEach((field) => {
          field.allowedValues = value;
        });
    });

    this.setState({ tabs: newTabs });
  };

  updateTabsState = (childs) => {
    if (childs) {
      let newTabs = this.state.tabs;
      let newFormElement = this.state.formelements;
      childs.forEach((child) => {
        newTabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            let childId = "customfield_" + child.id;
            if (
              childId === field.id &&
              field.type !== "Liste de choix (à choix unique)"
            ) {
              field.allowedValues = child.value;
              newFormElement.forEach((el) => {
                if (el.id === field.id) {
                  el.value = field.allowedValues;
                }
              });
              console.log(field.allowedValues);
            }
          });
        });
      });
      this.setState({ tabs: newTabs, formelements: newFormElement });
    }
  };

  changeAllowedValeu = (fieldId, value) => {
    const newTabs = this.state.tabs;
    newTabs.forEach((tab) => {
      tab.jiraFields
        .filter((field) => field.id === fieldId)
        .forEach((field) => {
          field.allowedValues = value;
        });
    });

    this.setState({ tabs: newTabs });
  };

  tabs() {
    const form = (
      <Tabs onChange={(index) => console.log("Selected Tab", index + 1)}>
        <TabList>
          {this.state.tabs.length <= 1 ? (
            <Tab></Tab>
          ) : (
            this.state.tabs
              .filter((tab) => tab.name !== "Expertise")
              .map((tab) => {
                return <Tab>{tab.name}</Tab>;
              })
          )}
        </TabList>

        {this.state.tabs
          .filter((tab) => tab.name !== "Expertise")
          .map((tab) => {
            return (
              <TabPanel key={tab.id}>
                <div className="aui-page-panel-content">
                  {tab.jiraFields.map((field) => {
                    return (
                      <div className="field-group">
                        <Element key={field.id} field={field} />
                      </div>
                    );
                  })}
                  <CommentField
                    onChange={this.onChangeComment}
                    valeu={this.state.comment}
                  />
                </div>
              </TabPanel>
            );
          })}
      </Tabs>
    );

    return form;
  }

  saveFields() {
    const elements = [];
    this.state.tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        let el;
        if (field.id === "customfield_15001") {
          el = { id: field.id, value: "motif", type: field.type };
        } else {
          el = { id: field.id, value: "", type: field.type };
        }
        elements.push(el);
      });
    });
    this.setState({ formelements: elements, loading: false }, () => {
      console.log(this.state);
    });
  }

  onChangeComment = (comment) => {
    this.setState({ comment: comment });
  };

  addcomment = async () => {
    if (this.state.comment.length > 0) {
      console.log(this.state.comment);
      const data = {
        username: AuthenticationService.getCurrentUser().username,
        issuekey: this.props.issue.key,
        comment: this.state.comment,
      };
      await IssueService.addComment(data)
        .then((res) => {
          console.log(res.data.comments);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  async sinistrValidor() {
    let isExist = false;
    let group = this.getGroup("");
    let sinstre = this.getFieldByName("N° Sinistre");
    let query =
      "project = DSC AND issuetype = Sinistre AND 'N° Sinistre' ~ '" +
      sinstre.allowedValues +
      "' AND reporter in (membersOf('" +
      group +
      "')) AND key !=" +
      this.props.issue.key;
    let result;

    const res = await IssueService.executeJql(query)
      .then((res) => {
        result = res.data;
        return res.data;
      })
      .catch((error) => {});

    return res.length > 0 ? false : true;
  }

  getGroup() {
    const firstElementStartingWithCNMA = AuthenticationService.getclim(
      "roles"
    ).find((element) => element.startsWith("CNMA Agence"));

    console.log(firstElementStartingWithCNMA);

    return firstElementStartingWithCNMA;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState(
      { open: false, error: null, isLoadingButton: true },
      () => {}
    );

    // validor N sinistre
    const isSinistreValid = await this.sinistrValidor();

    if (!isSinistreValid) {
      const error = "Le N° de sinistre que vous avez saisi existe déjà.";
      this.setState({
        isLoadingButton: false,
        open: true,
        error: error,
      });
      return false;
    }

    let fields = {};

    this.state.formelements
      .filter((field) => field.id !== "attachment" && field.value !== "")
      .forEach((field) => {
        fields = { ...fields, [field.id]: field.value };
      });

    const file = this.state.formelements.filter(
      (field) => field.id === "attachment"
    );
    const data = { fields: fields };

    let formData = new FormData();

    if (file[0].value.length !== 0 || file[0].value !== "") {
      if (maxFileSizeByList(file, this.state.MaxFileSize)) {
        const errors =
          "La taille maximale du fichier est de " +
          this.state.MaxFileSizeString;

        const error = { errors: errors };

        this.setState({
          open: true,
          error: error,
          isLoadingButton: false,
        });
        return false;
      } else {
        file[0].value.map((fileElement) =>
          formData.append("file", fileElement)
        );
      }
    }

    console.log(fields);

    await TransitionsService.executeUpdateTrannstionById(
      this.props.issue.id,
      fields,
      871
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        this.setState({ error: err.response.data, open: true });
        return false;
      });

    if (formData) {
      await IssueService.saveAttachment(this.props.issue.id, formData)
        .then((response) => {
          console.log(response.date);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }

    await this.addcomment();

    if (!this.state.error) {
      this.refreshPage();
    }
    this.setState({ isLoadingButton: false }, () => {});
  };

  refreshPage = () => {
    window.location.reload();
  };

  showerror = () => {
    if (Array.isArray(this.state.error.errorMessages)) {
      return (
        <div className="error-panel">
          <Banner
            appearance="error"
            icon={<ErrorIcon label="" secondaryColor="inherit" />}
            isOpen
          >
            {this.state.error.errorMessages[0]}
          </Banner>
        </div>
      );
    } else {
      if (this.state.error.errors) {
        return (
          <div className="error-panel">
            <Banner
              appearance="error"
              icon={<ErrorIcon label="" secondaryColor="inherit" />}
              isOpen
            >
              {this.state.error.errors}
            </Banner>
          </div>
        );
      } else {
        return (
          <div className="error-panel">
            <Banner
              appearance="error"
              icon={<ErrorIcon label="" secondaryColor="inherit" />}
              isOpen
            >
              {this.state.error}
            </Banner>
          </div>
        );
      }
    }
  };

  showSuccessful = () => {
    return (
      <Banner appearance="announcement" isOpen>
        <p>save issue with id </p>
        {this.state.message.id}
        <p> and key </p>
        {this.state.message.key}
      </Banner>
    );
  };

  async sqlCustomFieldInitValue(tabs) {
    tabs.forEach((tab) =>
      tab.jiraFields
        .filter((field) => field.type === "SQL Customfield")
        .forEach((field) => {
          const res = SqlCustomFieldService.getSqlFieldsTypologie(field.id);
          res
            .then((val) => {
              const typo = val.data.type;
              if (typo === "SELECT") {
                const depandFields = SqlCustomFieldService.getDF(field.id).then(
                  (dfs) => {
                    return dfs.map((df) => {
                      df.value = this.getCustomFieldValue(df.id);
                      return df;
                    });
                  }
                );
                const options = depandFields.then((val) => {
                  return SqlCustomFieldService.getOptions(field.id, val);
                });

                options.then((val) => {
                  field.allowedValues = val;
                });
              }
            })
            .catch((err) => console.log("error"));
        })
    );

    return tabs;
  }

  getCustomFieldValue(idField) {
    const field = this.state.formelements.filter((elem) => elem.id === idField);
  }
  loading = () => (
    <div style={{ flexWrap: "wrap", alignContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDrection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner size="large" />
      </div>
    </div>
  );

  redirectToShowISsue(id) {
    this.props.history.push("issue/" + id);
  }
  handleCloseNotif = () => {
    this.setState({ openNotif: false });
  };

  setSelectedSqlValeu = (id, values) => {
    const newTab = this.state.tabs;
    newTab.forEach((tab) =>
      tab.jiraFields
        .filter((field) => field.type === "SQL Customfield")
        .forEach((field) => {
          if (field.id === id) {
            field.valeur = values;
          }
        })
    );
    this.setState({ tabs: newTab });
  };

  render() {
    return (
      <div className="updateIssueModalContainer">
        {this.props.openModel && (
          <Modal width="large" className="updateIssueModal">
            <ModalTitle>
              <div className="updateIssueModalHeader">Modifier</div>
            </ModalTitle>
            <ModalBody className="updateIssueModalBody">
              <div>
                <ContentWrapper>
                  {this.state.open ? this.showerror() : null}
                  {this.state.successful ? this.showSuccessful() : null}
                  <FormContext.Provider
                    value={{
                      handleChange: this.handleChange,
                      updateTabs: this.updateTabsState,
                      issue: this.props.issue,
                      setSelectedSqlValeu: this.setSelectedSqlValeu,
                    }}
                  >
                    {this.state.showSpin && this.loading()}
                    <Form onSubmit={(data) => console.log(data)}>
                      {({ formProps }) => (
                        <form {...formProps}>
                          {!this.state.loading ? this.tabs() : <p>loading</p>}
                        </form>
                      )}
                    </Form>
                  </FormContext.Provider>
                </ContentWrapper>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="buttonsContainer">
                <Button
                  type="submit"
                  appearance="link"
                  onClick={this.props.toggleModal}
                  style={{ float: "right" }}
                >
                  Annuler
                </Button>
                <LoadingButton
                  type="submit"
                  isLoading={this.state.isLoadingButton}
                  className="aui-button"
                  isDisabled={this.state.disableCreate}
                  onClick={(e) => this.handleSubmit(e)}
                  style={{ float: "right" }}
                >
                  Modifier
                </LoadingButton>
              </div>
            </ModalFooter>
          </Modal>
        )}
        <Snackbar
          open={this.state.openNotif}
          autoHideDuration={6000}
          onClose={this.handleCloseNotif}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            severity="info"
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={this.handleCloseNotif}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          >
            This is an information message!
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
