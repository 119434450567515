import React from "react";
import { Create } from "@atlaskit/atlassian-navigation";
import { useHistory } from "react-router-dom";
import showButtonCreate from "../../helpers/showCreateButton";
import AuthenticationService from "../../services/AuthenticationService";

const CreateComp = () => {
  const history = useHistory();
  const redirectToCreate = () => {
    history.push("/create");
  };

  const showButtonCreate = () => {
    const roles = AuthenticationService.getclim("roles");
    return roles.includes("Siège GIGA") ? true : false;
  };
  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          paddingTop: "5px",
        }}
      >
        <Create text="Créer" onClick={() => redirectToCreate()} />
      </div>
    </div>
  );
};

export default CreateComp;
