import axios from "axios";
import jwt_decode from "jwt-decode";

class AuthenticationService {
  signin = async (username, password) => {
    return axios
      .post(process.env.REACT_APP_API_URL + "/token/authenticate", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  otpVerfication = async (otp) => {
    return axios
      .post(
        process.env.REACT_APP_API_URL + "/token/authenticate-otp?otp=" + otp
      )
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  sendOtp = async (username) => {
    return axios
      .post(
        process.env.REACT_APP_API_URL + "/token/resend-otp?username=" + username
      )
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  refresshToken = async (refreshToken) => {
    return axios
      .post(process.env.REACT_APP_API_URL + "/token/refreshToken", {
        refreshToken: refreshToken,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((err) => {
        window.location.href = process.env.REACT_APP_BASE_URL + "/login";
        this.signOut();
        return err;
      });
  };

  signOut() {
    localStorage.removeItem("user");
  }

  register = async (firstname, lastname, username, email, password) => {
    return axios.post("/api/auth/signup", {
      firstname,
      lastname,
      username,
      email,
      password,
    });
  };

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  tokenexpierd() {
    const token = this.getCurrentUser().accessToken;
    const decode = jwt_decode(token);
    const exp = decode.exp;
    const expired = Date.now() >= exp * 1000;
    return expired;
  }

  isLogin() {
    //if (localStorage.getItem("user") && !this.tokenexpierd()) {
    if (localStorage.getItem("user")) {
      return true;
    }
    this.signOut();
    return false;
  }

  getclim(clim) {
    if (localStorage.getItem("user")) {
      const token = this.getCurrentUser().accessToken;
      const decode = jwt_decode(token);
      return decode[clim];
    } else {
      return [];
    }
  }
}

export default new AuthenticationService();
