import DynamicTable from "@atlaskit/dynamic-table";
import Lozenge from "@atlaskit/lozenge";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import formatDate from "../../helpers/format-date-fr";
import AuthenticationService from "../../services/AuthenticationService";
import IssueService from "../../services/IssueService";
import Tablemessageerror from "../TableMessageError";

import "./OrderRejete.css";

const Orderrejete = () => {
  const [issues, setissues] = useState([]);
  const [loading, setloading] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createJql = () => {
    //  load  project from token
    const projects = AuthenticationService.getclim("project");

    if (projects.includes("Bris de glace")) {
      return `project = BDG AND issuetype = 'Dossier BDG' AND status = 'ODR rejeté'  ORDER BY key ASC`;
    }

    if (projects.includes("GIGA Bris de glace")) {
      return `project = GIGA AND issuetype = 'Dossier BDG' AND status = 'ODR rejeté'  ORDER BY key ASC`;
    }

    if (projects.includes("CNMA Bris de Glace")) {
      return `project = CNMA AND issuetype = 'Dossier BDG' AND status = 'ODR rejeté'  ORDER BY key ASC`;
    }
    if (projects.includes("CAAR Bris de glace")) {
      return `project = CAAR AND issuetype = 'Dossier BDG' AND status = 'ODR rejeté'  ORDER BY key ASC`;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let jql = null;
      jql = createJql();

      await IssueService.executeJql(jql)
        .then((respons) => {
          setissues(respons.data);
          setloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const head = {
    cells: [
      {
        key: "cle",
        content: "Clé",
        isSortable: true,
        width: 8,
      },
      {
        key: "resume",
        content: "Résumé",
        isSortable: true,
        width: 38,
      },
      {
        key: "etat",
        content: "Etat",
        isSortable: true,
        width: 10,
      },
      {
        key: "motif",
        content: "Motif de rejet",
        isSortable: true,
      },
      {
        key: "rapporteur",
        content: "Rapporteur",
        isSortable: true,
      },
      {
        key: "responsable",
        content: "Responsable",
        isSortable: true,
      },
      {
        key: "creation",
        content: "Création",
        isSortable: true,
      },
    ],
  };

  const rows = issues.map((issue) => ({
    key: uuid(),
    cells: [
      {
        id: issue.key,
        key: issue.key,
        content: (
          <Link className="linkclass" to={"/issue/" + issue.key}>
            {issue.key}
          </Link>
        ),
      },
      {
        id: issue.key,
        key: issue.summary,
        content: (
          <Link className="linkclass" to={"/issue/" + issue.key}>
            {issue.summary}
          </Link>
        ),
      },
      {
        key: issue.etat,
        content: (
          <div>
            <Lozenge>{issue.etat}</Lozenge>
          </div>
        ),
      },
      {
        key: issue.etat,
        content: issue.motifRejet ? (
          <b>
            <p style={{ color: "red", margin: "0px" }}>{issue.motifRejet}</p>
          </b>
        ) : (
          ""
        ),
      },
      {
        key: issue.reporter,
        content: issue.reporter,
      },
      {
        key: issue.assignee,
        content: issue.assignee,
      },
      {
        key: issue.created,
        content: formatDate(issue.created),
      },
    ],
  }));

  return (
    <div className="tablecontent">
      <div className="dashboard-item-header" style={{ background: "#0747a6" }}>
        <h3 className="dashboard-item-titler">
          Résultats du filtre : Ordres de réparation rejetés
        </h3>
      </div>
      <DynamicTable
        head={issues.length > 0 ? head : null}
        rows={rows}
        rowsPerPage={5}
        defaultPage={1}
        isLoading={loading}
        loadingSpinnerSize="large"
        emptyView={<Tablemessageerror />}
        isFixedSize="true"
      />
    </div>
  );
};

export default Orderrejete;
