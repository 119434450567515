/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import "../../pages/Search/search.css";

import { LoadingButton } from "@atlaskit/button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Popover from "@mui/material/Popover";
import SqlCustomFieldService from "../../services/SqlCustomFieldService";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import UserService from "../../services/UserService";
import { DatePicker } from "@atlaskit/datetime-picker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import dateFormat, { masks } from "dateformat";
import InputLabel from "@mui/material/InputLabel";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";

import "./Header.css";

import Button from "@atlaskit/button/standard-button";
import EditorErrorIcon from "@atlaskit/icon/glyph/editor/error";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AuthenticationService from "../../services/AuthenticationService";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const statusBDG = [
  "Clos",
  "ODR envoyé",
  "ODR validé",
  "ODR rejeté",
  "Missionné",
  "Dossiers classés",
];

const statusDSC = [
  "Clos",
  "Initié",
  "En attente Expertise",
  "Dossiers classés",
  "En attente de validation CR",
  "En attente de validation DG",
  "Préparation ordre paiement",
  "Préparation du chèque",
  "Chèque émis",
  "Complément dossier",
  "Expertise refusé",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const arrayProjectBDG = [
  "Bris de glace",
  "CNMA Bris de Glace",
  "GIGA Bris de glace",
];

const projectDSC = [
  { name: "Déclaration des Sinistres  CNMA", key: "DSC" },
  { name: "CNMA Bris de Glace", key: "CNMA" },
];

const Header = ({ updatejql, renderRow, showjql }) => {
  const projects = AuthenticationService.getclim("project");
  const [state, setstate] = useState({
    key: "",
    status: [],
    customfield_10714: "",
    customfield_11101: "",
    customfield_11454: "",
    customfield_11600: "",
    customfield_14721: "",
    customfield_14722: "",
    customfield_14602: "", //dsc police
    customfield_14603: "", //DSCSinistre
    customfield_11462: "", //raison sociele
    customfield_10021: "", //date Sinistre
    customfield_10022: "", // lieu Sinistre

    assignee: [],
    reporter: [],
    createdstart: "",
    createdend: null,
    project: "",
  });
  const [status, setstatus] = useState(statusBDG);
  const [project, setproject] = useState(projectDSC[0].key);
  const [cars, setcars] = useState([]);
  const [models, setmodels] = useState([]);
  const [usersOption, setUsersOption] = useState([]);

  const [openPopo, setopenPopo] = useState(false);
  const [openPopoResponsable, setopenPopoResponsable] = useState(false);
  const [openPopoCreateur, setopenPopoCreateur] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElResponsable, setAnchorElResponsable] = useState(null);
  const [anchorElCreateur, setAnchorElCreateur] = useState(null);

  const [assignee, setAssignee] = useState([]);
  const [reporter, setReporter] = useState([]);

  const [query, setquery] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const param = queryParams.get("jql");

  const getUsersOption = async () => {
    const users = await UserService.getAlLUsers();
    setUsersOption(users);
  };

  useEffect(() => {
    getCars();
    getUsersOption();
    if (queryParams) {
      setquery(param);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCars = async () => {
    const listCars = await SqlCustomFieldService.getOptions(
      "customfield_14721",
      []
    );
    const carOptions = listCars
      .filter((car) => car.value !== "Autre" && car.value !== "")
      .map((car) => car.value);

    setcars(carOptions);
  };

  const getModel = async (car) => {
    const listModels = await SqlCustomFieldService.getOptions(
      "customfield_14722",
      car
    );
    let modelOptions = [];
    modelOptions = listModels
      .filter((model) => model.value !== "Autre" && model.value !== "")
      .map((model) => model.value);
    setmodels(modelOptions);
  };

  const handleChangeCars = (evt) => {
    if (evt.target.name === "customfield_14721") {
      const car = [{ id: "customfield_14722", value: evt.target.value }];
      getModel(car);
      const value = evt.target.value;

      setstate({
        ...state,
        customfield_14721: value,
        customfield_14722: "",
      });
    }
    if (evt.target.name === "customfield_14722") {
      const value = evt.target.value;
      setstate({
        ...state,
        customfield_14722: value,
      });
    }
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    let newSatate = state;
    newSatate = {
      ...state,
      [evt.target.name]: value,
    };
    setstate(newSatate);
  };

  const handleChangeProject = (evt) => {
    const value = evt.target.value;
    setproject(value);
    projectStatus();
  };

  const onChangeReporter = (event, value) => {
    if (value) {
      setReporter(value);
    } else {
      setReporter([]);
    }
  };

  const onChangeAssignee = (event, value) => {
    if (value) {
      setAssignee(value);
    } else {
      setAssignee([]);
    }
  };

  const createJql = () => {
    let jql = "";
    Object.keys(state)
      .filter((key, i) => state[key] !== "" && state[key] !== null)
      .filter(([key, i]) => state[key] !== "project")
      .forEach((key, i) => {
        let val = "";

        if (
          key === "customfield_14721" &&
          state["customfield_14721"].length > 0
        ) {
          val = ` cf[14721] = '${state[key]}'`;
        }

        if (
          key === "customfield_14722" &&
          state["customfield_14722"].length > 0
        ) {
          val = ` cf[14722] = '${state[key]}'`;
        }

        if (key === "customfield_11600") {
          val = `  cf[11600] ~ '*${state[key]}*'`;
        }

        if (key === "customfield_11101") {
          val = ` cf[11101] ~ '*${state[key]}*'`;
        }
        if (key === "customfield_11454") {
          val = ` cf[11454] ~ '*${state[key]}*'`;
        }
        if (key === "customfield_10714") {
          val = ` cf[10714] ~ '*${state[key]}*'`;
        }

        if (key === "customfield_14602") {
          val = ` cf[14602] = '${state[key]}'`;
        }

        if (key === "customfield_14603") {
          val = ` cf[14603] ~ '*${state[key]}*'`;
        }

        if (key === "customfield_11462") {
          val = ` cf[11462] ~ '*${state[key]}*'`;
        }

        if (key === "customfield_10021") {
          val = ` cf[10021] >= '${state[key]}'`;
        }

        if (key === "customfield_10022") {
          val = ` cf[10022] ~ '*${state[key]}*'`;
        }

        if (key === "status" && state["status"].length > 0) {
          val = `${key} in (${makestausQuery(state[key])})`;
        }

        if (key === "reporter" && reporter.length > 0) {
          val = `${key} in (${getNamesUsers(reporter)})`;
        }

        if (key === "assignee" && assignee.length > 0) {
          val = `${key} in (${getNamesUsers(assignee)})`;
        }

        if (key === "createdstart") {
          val = `created >= ${dateFormat(state[key], "yyyy-mm-dd")}`;
        }
        if (key === "createdend") {
          val = `created <= ${dateFormat(state[key], "yyyy-mm-dd")}`;
        }

        // if (key === "key") {
        //   val = ` key ~ '*${state[key]}*'`;
        // }

        if (
          key !== "createdstart" &&
          key !== "createdend" &&
          key !== "status" &&
          key !== "customfield_14721" &&
          key !== "customfield_14722" &&
          key !== "assignee" &&
          key !== "reporter" &&
          key !== "customfield_11600" &&
          key !== "customfield_11101" &&
          key !== "customfield_10714" &&
          key !== "customfield_11454" &&
          key !== "customfield_14602" &&
          key !== "customfield_14603" &&
          key !== "customfield_11462" &&
          key !== "customfield_10021" &&
          key !== "customfield_10022"
          // key !== "key"
        ) {
          val = `${key} = '${state[key]}'`;
        }

        if (val !== "") {
          if (jql === "") {
            jql = val;
          } else {
            jql = jql + " AND " + val;
          }
        }
      });

    let preparQuery = jql === "" ? jql : "AND " + jql;
    const projects = AuthenticationService.getclim("project");
    let proj = null;
    let newQuery = null;

    if (projects.includes("Bris de glace")) {
      newQuery = `project = BDG AND issuetype = 'Dossier BDG' ${preparQuery} ORDER BY updated DESC`;
      proj = "BDG";
    }
    if (projects.includes("GIGA Bris de glace")) {
      newQuery = `project = GIGA AND issuetype = 'Dossier BDG' ${preparQuery} ORDER BY updated DESC`;
      proj = "GIGA";
    }
    if (project === "CNMA") {
      newQuery = `project = CNMA AND issuetype = 'Dossier BDG' ${preparQuery} ORDER BY updated DESC`;
      proj = "CNMA";
    }

    if (project === "DSC") {
      newQuery = `project = DSC AND issuetype = 'Sinistre' ${preparQuery} ORDER BY updated DESC`;
      proj = "DSC";
    }
    console.log(proj);
    updatejql(newQuery);
    renderRow(newQuery, proj);
    setquery(newQuery);
  };

  const makestausQuery = (array) => {
    let query = array.map((val) => `'${val}'`);
    return query.join(", ");
  };

  const getNamesUsers = (list) => {
    let names = list.map((user) => user.name);
    return names.join(", ");
  };

  const openDateModel = (event) => {
    setopenPopo(true);
    setAnchorEl(event.currentTarget);
  };

  const openDateModelResponsable = (event) => {
    setopenPopoResponsable(true);
    setAnchorElResponsable(event.currentTarget);
  };

  const openDateModelCreateur = (event) => {
    setopenPopoCreateur(true);
    setAnchorElCreateur(event.currentTarget);
  };

  const closeDateModel = () => {
    setopenPopo(false);
  };

  const closeDateModelResponsable = () => {
    setopenPopoResponsable(false);
  };

  const closeDateModelCreateur = () => {
    setopenPopoCreateur(false);
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const showField = (arrayProject) => {
    let result = false;
    if (arrayProject === "all") {
      result = true;
    } else {
      arrayProject.forEach((pro) => {
        if (AuthenticationService.getclim("project").includes(pro)) {
          result = true;
        }
      });
    }
    return result;
  };

  const projectStatus = () => {
    project === "DSC" ? setstatus(statusBDG) : setstatus(statusDSC);
  };

  return (
    <div className="header-panel">
      <div className="searchHeader">
        <p
          style={{
            color: "#244fc9",
            paddingTop: "1%",
            paddingLeft: "1%",
            marginBottom: "0%",
          }}
        >
          Recherche
        </p>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "16px" }}>
        {showField(["Déclaration des Sinistres  CNMA"]) && (
          <li>
            <FormControl
              variant="standard"
              style={{ width: "180px" }}
              size="small"
            >
              <InputLabel id="status-label" className="search-label">
                Projet
              </InputLabel>
              <Select
                id="project"
                labelId="status-label"
                label="project"
                name="project"
                value={project}
                defaultValue={projectDSC[0]}
                onChange={handleChangeProject}
                MenuProps={MenuProps}
              >
                {projectDSC.map((proj) => (
                  <MenuItem key={proj.key} value={proj.key} id={proj.key}>
                    <ListItemText primary={proj.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </li>
        )}

        <li>
          <TextField
            id="cle"
            label="Clé"
            variant="standard"
            name="key"
            value={state.key}
            onChange={handleChange}
          />
        </li>
        <li>
          <FormControl
            variant="standard"
            style={{ width: "180px" }}
            size="small"
          >
            <InputLabel id="status-label" className="search-label">
              Etat
            </InputLabel>
            <Select
              id="etat"
              labelId="status-label"
              label="Etat"
              name="status"
              multiple
              value={state.status}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {status.map((name) => (
                <MenuItem key={name} value={name} id={name}>
                  <Checkbox checked={state.status.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </li>

        {projects.includes("Bris de glace") ||
        projects.includes("CNMA Bris de Glace") ? (
          <li>
            <TextField
              id="NSinistreODR"
              label="N° de sinistre ODR"
              variant="standard"
              name="customfield_10714"
              value={state.customfield_10714}
              onChange={handleChange}
            />
          </li>
        ) : null}

        {showField(["Déclaration des Sinistres  CNMA"]) && (
          <li>
            <TextField
              id="NSinistreODR"
              label="N° de sinistre"
              variant="standard"
              name="customfield_14603"
              value={state.customfield_14603}
              onChange={handleChange}
            />
          </li>
        )}
        {showField(["GIGA Bris de glace"]) && (
          <li>
            <TextField
              id="NChassis(VIN)"
              label="N° de chassis (VIN)"
              variant="standard"
              name="customfield_11454"
              value={state.customfield_11454}
              onChange={handleChange}
            />
          </li>
        )}

        {showField(arrayProjectBDG) ? (
          <li>
            <TextField
              id="NPolice"
              label="N°Police"
              variant="standard"
              name="customfield_11101"
              value={state.customfield_11101}
              onChange={handleChange}
            />
          </li>
        ) : null}

        {showField(["Déclaration des Sinistres  CNMA"]) ? (
          <li>
            <TextField
              id="NPolice"
              label="N°Police DSC"
              variant="standard"
              name="customfield_14602"
              value={state.customfield_14602}
              onChange={handleChange}
            />
          </li>
        ) : null}

        {showField(["Déclaration des Sinistres  CNMA"]) && (
          <li>
            <TextField
              id="raisonSocial"
              label="Nom et Prénom"
              variant="standard"
              name="customfield_11462"
              value={state.customfield_11462}
              onChange={handleChange}
            />
          </li>
        )}

        {showField(["Déclaration des Sinistres  CNMA"]) && (
          <li>
            <DatePicker
              locale={"fr-FR"}
              placeholder={" "}
              disableFuture
              label=""
              name="dateSinistre"
              value={state.customfield_10021}
              openTo="day"
              views={["year", "month", "day"]}
              onChange={(newValue) => {
                setstate({ ...state, customfield_10021: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
              id="customfield_10021"
            />
          </li>
        )}

        {showField(["Déclaration des Sinistres  CNMA"]) && (
          <li>
            <TextField
              id="Lieu"
              label="Lieu de sinistre"
              variant="standard"
              name="customfield_10022"
              value={state.customfield_10022}
              onChange={handleChange}
            />
          </li>
        )}

        {showField("all") && (
          <li>
            <TextField
              id="ImmatriculationVehicule"
              label="Immatriculation véhicule"
              variant="standard"
              name="customfield_11600"
              value={state.customfield_11600}
              onChange={handleChange}
            />
          </li>
        )}

        {showField(arrayProjectBDG) && (
          <li>
            <FormControl variant="standard" style={{ width: "150px" }}>
              <InputLabel id="marque-label" className="search-label">
                Marque
              </InputLabel>
              <Select
                labelId="marque-label"
                id="Marque"
                name="customfield_14721"
                value={state.customfield_14721}
                onChange={handleChangeCars}
                label="Marque"
              >
                <MenuItem value="">
                  <em>Aucun</em>
                </MenuItem>
                {cars && cars.length > 0 ? (
                  cars.map((car, i) => (
                    <MenuItem key={i} value={car} id={car}>
                      {car}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem style={{ visibility: "hidden" }}></MenuItem>
                )}
              </Select>
            </FormControl>
          </li>
        )}
        {showField(arrayProjectBDG) && (
          <li>
            <FormControl variant="standard" style={{ width: "150px" }}>
              <InputLabel id="model-label" className="search-label">
                Model
              </InputLabel>
              <Select
                labelId="model-label"
                id="Model"
                name="customfield_14722"
                value={state.customfield_14722}
                onChange={handleChangeCars}
                label="Model"
              >
                <MenuItem value="">
                  <em>Aucun</em>
                </MenuItem>
                {models && models.length > 0 ? (
                  models.map((model) => (
                    <MenuItem value={model} id={model}>
                      {model}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem style={{ visibility: "hidden" }}></MenuItem>
                )}
              </Select>
            </FormControl>
          </li>
        )}

        <li style={{ width: 240 }}>
          <Button
            style={{ width: "200px" }}
            aria-describedby={"responsable-autocomplete"}
            variant="text"
            onClick={openDateModelResponsable}
            appearance="default"
            isSelected={openPopoResponsable}
            id="ResponsableButton"
          >
            {assignee.length > 0 ? (
              <Box>{getNamesUsers(assignee)}</Box>
            ) : (
              <Box>Responsable: Tous</Box>
            )}
          </Button>
          <Popover
            id={"responsablePopo"}
            open={openPopoResponsable}
            anchorEl={anchorElResponsable}
            onClose={closeDateModelResponsable}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="responsableSearch" style={{ width: 320 }}>
              <Autocomplete
                multiple
                id="Responsable"
                name="assignee"
                value={assignee}
                options={usersOption}
                onChange={onChangeAssignee}
                disableCloseOnSelect
                autoHighlight
                getOptionLabel={(option) => option.name}
                //renderTags={() => null}
                style={{ height: 60 }}
                limitTags={2}
                filterOptions={filterOptions}
                noOptionsText="Aucun"
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ width: 300 }} id={option.name}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="."
                    variant="standard"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {usersOption.length > 0 ? null : (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Popover>
        </li>
        <li style={{ width: 240 }}>
          <Button
            style={{ width: "200px" }}
            aria-describedby={"createur-autocomplete"}
            variant="text"
            onClick={openDateModelCreateur}
            appearance="default"
            isSelected={openPopoCreateur}
            id="CreateurButton"
          >
            {reporter.length > 0 ? (
              <Box>{getNamesUsers(reporter)}</Box>
            ) : (
              <Box>Createur: Tous</Box>
            )}
          </Button>
          <Popover
            id={"createurPopo"}
            open={openPopoCreateur}
            anchorEl={anchorElCreateur}
            onClose={closeDateModelCreateur}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="createurSearch" style={{ width: 320 }}>
              <Autocomplete
                multiple
                id="Createur"
                name="reporter"
                value={reporter}
                options={usersOption}
                onChange={onChangeReporter}
                disableCloseOnSelect
                autoHighlight
                getOptionLabel={(option) => option.name}
                //renderTags={() => null}
                style={{ height: 60 }}
                limitTags={2}
                filterOptions={filterOptions}
                noOptionsText="Aucun"
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ width: 300 }} id={option.name}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="."
                    variant="standard"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {usersOption.length > 0 ? null : (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Popover>
        </li>
        <li>
          <Button
            style={{ width: "200px" }}
            aria-describedby={"created"}
            variant="text"
            onClick={openDateModel}
            appearance="default"
            isSelected={openPopo}
            id="DateCreation"
          >
            Date de Création
          </Button>
          <Popover
            id={"createdPopo"}
            open={openPopo}
            anchorEl={anchorEl}
            onClose={closeDateModel}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="datePickerSearch">
              <span style={{ paddingLeft: 5 }}> Entre </span>
              <DatePicker
                locale={"fr-FR"}
                placeholder={" "}
                disableFuture
                label=""
                name="createdstart"
                value={state.createdstart}
                openTo="day"
                views={["year", "month", "day"]}
                onChange={(newValue) => {
                  setstate({ ...state, createdstart: newValue });
                }}
                renderInput={(params) => <TextField {...params} />}
                id="CreatedStart"
              />
              <span style={{ paddingLeft: 5 }}> et </span>
              <DatePicker
                locale={"fr-FR"}
                disableFuture
                placeholder={" "}
                label=""
                name="createdend"
                openTo="day"
                value={state.createdend}
                views={["year", "month", "day"]}
                onChange={(newValue) => {
                  setstate({ ...state, createdend: newValue });
                }}
                renderInput={(params) => <TextField {...params} />}
                id="CreatedEnd"
              />
            </div>
          </Popover>
        </li>
        <li>
          <LoadingButton
            appearance="primary"
            isLoading={false}
            onClick={createJql}
            className="rechercheLi"
            id="SearchButtonSearchPage"
          >
            Recherche
          </LoadingButton>
        </li>
      </div>
      <br />

      <div id="searchAdvanced">
        {query !== "" && (
          <div className="advancedSearchContainer">
            <div className="advancedSearch">
              <div className="iconTextArea">
                <EditorSuccessIcon
                  size="medium"
                  label=""
                  primaryColor="#36B37E"
                  secondaryColor="#FFF"
                />
              </div>
              <div className="closeButtonTextArea">
                <Button
                  spacing="compact"
                  appearance="subtle"
                  className="buttonClose"
                  onClick={() => setquery("")}
                >
                  <EditorErrorIcon />
                </Button>
              </div>
              <div className="borderTextArea">
                <p className="bodyTextArea">{query}</p>
              </div>
            </div>
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
