import { Field } from "@atlaskit/form";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@mui/material/Select";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { FormContext } from "../../pages/FormContext";
import "./MultipleSelectNative.css";

const MultipleSelectNative = ({
  field_id,
  field_label,
  val,
  required,
  hiden,
  description,
}) => {
  const { handleChange, issue } = useContext(FormContext);
  const [value, setvalue] = useState([]);

  useEffect(() => {
    initState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initState = () => {
    if (issue) {
      const issueOption = issue.fields[field_id];
      const defaultValue = issueOption
        ? issueOption.map((option) => option.value)
        : null;
      setvalue(defaultValue);
    }
  };

  const options = () => {
    const list = val.map((d) => ({
      id: d.id,
      value: d.value,
    }));
    return list;
  };

  const DescriptionElement = () => (
    <div className="description">{ReactHtmlParser(description)}</div>
  );

  const handleChangeMultiple = (event) => {
    setvalue(getvalueChangeMultiple(event));
  };

  const getvalueChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    return value;
  };

  const useStyles = makeStyles({
    select: {
      "& ul": {
        backgroundColor: "#cccccc",
      },
      "& li": {
        fontSize: 12,
      },
    },
  });
  const classes = useStyles();

  return (
    <div>
      <Field label={field_label} name={field_id}>
        {() => (
          <Fragment style={{ fontSize: 8 }}>
            <Select
              style={{ paddingTop: "0" }}
              multiple
              native
              inputId={field_id}
              id={field_id}
              value={value}
              MenuProps={{ classes: { paper: classes.select } }}
              onChange={(event) => {
                handleChangeMultiple(event);
                handleChange(field_id, event, getvalueChangeMultiple(event));
              }}
            >
              {options().map((option) => (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              ))}
            </Select>
            <DescriptionElement />
          </Fragment>
        )}
      </Field>
      <br />
    </div>
  );
};

export default MultipleSelectNative;
