import { Checkbox } from "@atlaskit/checkbox";
import { Field } from "@atlaskit/form";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { FormContext } from "../../pages/FormContext";
import "./Input.css";

const CheckboxField = ({
  field_id,
  field_label,
  val,
  required,
  hiden,
  description,
}) => {
  const { handleChange, issue } = useContext(FormContext);

  const [value, setvalue] = useState(false);

  useEffect(() => {
    initState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initState = () => {
    if (issue) {
      const issueOption = issue.fields[field_id];
    }
  };

  const includeId = (element) => {
    if (issue) {
      const array = issue.fields[field_id].filter(
        (field) => field.id === element.id
      );
      return array.length > 0 ? true : false;
    } else {
      return value;
    }
  };

  return (
    <Field label={field_label} name={field_id} defaultValue="">
      {() => (
        <Fragment>
          <div className="checkBox-field">
            {val.map((element) => {
              return (
                <Checkbox
                  label={element.value}
                  name={element.value}
                  onChange={(event) => {
                    setvalue(event.target.value);
                    handleChange(field_id, event);
                  }}
                />
              );
            })}
          </div>
        </Fragment>
      )}
    </Field>
  );
};

export default CheckboxField;
