import { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import striptags from "striptags";
import { FormContext } from "../../pages/FormContext";
import "./Input.css";

const Input = ({
  field_id,
  field_label,
  required,
  val,
  hiden,
  description,
}) => {
  const { handleChange, issue } = useContext(FormContext);
  const [value, setvalue] = useState("");
  useEffect(() => {}, []);

  const setinitValue = () => {
    if (issue) {
      // update From
      setvalue(issue.fields[field_id] ? issue.fields[field_id] : "");
      val = issue.fields[field_id] ? issue.fields[field_id] : "";
    }
  };

  const generateDescription = () => {
    var desc = striptags(description, "<p>");
    if (desc.includes("document.getElementById")) {
      return "";
    } else {
      return desc;
    }
  };

  const DescriptionElement = () => (
    <div className="description">{ReactHtmlParser(generateDescription())}</div>
  );

  return (
    <div>
      {!hiden && (
        <div>
          <Field label={field_label} name={field_id} defaultValue="">
            {() => (
              <Fragment>
                <Textfield
                  id={field_label}
                  isCompact
                  spacing="compact"
                  pattern=".{0,20}"
                  data-testid="nativeFormValidationTest"
                  onChange={(event) => {
                    handleChange(field_id, event);
                  }}
                  value={val ? val : ""}
                />
                <DescriptionElement />
              </Fragment>
            )}
          </Field>
        </div>
      )}
    </div>
  );
};

export default Input;
