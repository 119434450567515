import { convertToRaw } from "draft-js";
import draftToHtmlPuri from "draftjs-to-html";
import { to_jira } from "jira2md";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TurnDownService from "turndown";
import AuthenticationService from "../../services/AuthenticationService";
import UserService from "../../services/UserService";
const CommentEditor = ({
  comment,
  setcomment,
  editorState,
  seteditorState,
}) => {
  const [mentionsArray, setmentionsArray] = useState([
    {
      text: AuthenticationService.getCurrentUser().username,
      value: AuthenticationService.getCurrentUser().username,
      url: AuthenticationService.getCurrentUser().username,
    },
  ]);

  const onEditorStateChange = (neweditorState) => {
    seteditorState(neweditorState);
    const htmlPuri = draftToHtmlPuri(
      convertToRaw(neweditorState.getCurrentContent())
    );
    var turndownService = new TurnDownService();
    var markdown = turndownService.turndown(htmlPuri);
    var textComment = to_jira(markdown);
    var result = transformationMentions(textComment);
    getWords(result);
    setcomment(result);
  };

  const getParts = (sentence, first, last) => {
    let goodParts = [];

    const allParts = sentence.split(first);

    allParts.forEach((part) => {
      if (part.indexOf(last) > -1) {
        const goodOne = part.split(last)[0];
        goodParts = goodParts.concat(goodOne);
      }
    });

    return goodParts;
  };
  const transformationMentions = (text) => {
    const stuffIneed = getParts(text, "[", "]");
    stuffIneed.forEach((element, i) => {
      let userSelected = element.split("@").pop().split("|")[0];
      text = text.replace(element, `~${userSelected}`);
    });
    return text;
  };

  const getMentionsString = (text) => {
    var username = text.substring(text.indexOf("[") + 1, text.lastIndexOf("]"));
    return username;
  };

  const getWords = async (val) => {
    const wordsSatrt = val.match(/@\S+/g);
    if (wordsSatrt) {
      const lastword = wordsSatrt[wordsSatrt.length - 1];
      const word = lastword.replace("@", "");

      const users = await UserService.searchUsers(word);
      if (users) {
        const transformeArray = users.map((user) => {
          const userMention = {
            text: user.name,
            value: user.name,
            url: user.name,
          };
          return userMention;
        });
        setmentionsArray(transformeArray);
      }
    }
  };

  return (
    <Editor
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      mention={{
        separator: " ",
        trigger: "@",
        suggestions: mentionsArray,
      }}
      toolbar={{
        options: ["inline", "blockType", "list", "link", "remove", "history"],
        inline: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ["bold", "italic", "underline"],
        },
        blockType: {
          inDropdown: true,
          options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
        list: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ["unordered", "ordered"],
        },
      }}
    />
  );
};

export default CommentEditor;
