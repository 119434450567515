import axios from "axios";

class RestPasswordService {
  requestRestPassword = async (userName) => {
    return await axios.post(
      process.env.REACT_APP_API_URL + `/users/requestrest?userName=${userName}`
    );
  };

  changePassword = async (token, password) => {
    let data = {
      password: password,
    };
    return await axios.post(
      process.env.REACT_APP_API_URL + `/users/changePassword?token=${token}`,
      data
    );
  };

  changePasswordUserName = async (username, password) => {
    let data = {
      password: password,
      userName: username,
    };
    return await axios.post(
      process.env.REACT_APP_API_URL + `/users/changePassword`,
      data
    );
  };

  getPasswordConstraints = async () => {
    return await axios.get(process.env.REACT_APP_API_URL + `/password`);
  };

  validationPaswordApi = async (token, password) => {
    return await axios.get(
      process.env.REACT_APP_API_URL +
        `/password/validation?token=${token}&password=${password}`
    );
  };
}
export default new RestPasswordService();
