/* eslint-disable indent */
import Banner from "@atlaskit/banner";
import Button, { LoadingButton } from "@atlaskit/button";
import Form from "@atlaskit/form";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@atlaskit/modal-dialog";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import { useEffect, useState } from "react";
import Element from "../../components/Element";
import CommentField from "../../components/elements/CommentField";
import IssueService from "../../services/IssueService";
import TransitionsService from "../../services/TransitionsService";
import { FormContext } from "../FormContext";
import "./TransitionPage.css";
import { id } from "date-fns/locale";
import { is } from "express/lib/request";

const Transitionpage = ({
  title,
  idProject,
  idIssueType,
  idTransition,
  issueId,
  open,
  toggle,
  issueKey,
  showButton,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [isLoading, setIsLoading] = useState(true);
  const [elements, setElements] = useState([]);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [successful, setSuccessful] = useState();
  const [formelements, setformelements] = useState([]);
  const [screen, setscreen] = useState(null);
  const [isLoadingButton, setisLoadingButton] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [comment, setcomment] = useState("");

  useEffect(() => {
    setIsOpen(open);
    getScreen();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getScreen = async () => {
    console.log(idProject);
    await IssueService.getTransionScreen(idProject, idIssueType, idTransition)
      .then((response) => {
        setIsLoading(false);
        setscreen(response.data);
        saveFields(response.data);
        showButton(false);
      })
      .catch((error) => {
        throw error;
      });
  };

  const loadForm = () => {
    const form = (
      <Tabs onChange={(index) => console.log("Selected Tab", index + 1)}>
        <TabList>
          {screen.tabs.length <= 1 ? (
            <Tab></Tab>
          ) : (
            screen.tabs.map((tab, i) => {
              return <Tab key={i}>{tab.name}</Tab>;
            })
          )}
        </TabList>

        {screen.tabs.map((tab) => {
          return (
            <TabPanel key={tab.id}>
              <div className="aui-page-panel-content">
                {tab.jiraFields.map((field, i) => {
                  return (
                    <div key={i} className="field-group">
                      <Element key={field.id} field={field} />
                    </div>
                  );
                })}
                <div className="comment-panel">
                  <CommentField onChange={onChangeComment} valeu={comment} />
                </div>
              </div>
            </TabPanel>
          );
        })}
      </Tabs>
    );
    return form;
  };

  const onChangeComment = (comment) => {
    setcomment(comment);
  };

  const validor = (file) => {};

  const handleChange = async (id, event, val) => {
    const newElements = formelements;
    newElements.forEach((field) => {
      if (id === field.id) {
        switch (field.type) {
          // eslint-disable-next-line
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "SQL Customfield":
            if (event) {
              field["value"] = event.value;
            } else {
              field["value"] = val;
            }
            break;
          case "Champ de système":
            if (id === "attachment") {
              field["value"] = val;
            } else {
              field["value"] = event.value;
            }
            break;
          case "Liste de choix (à choix unique)":
            field["value"] = { value: event.label };
            break;
          case "Liste de choix (à choix multiples)":
            field["value"] = { value: val };
            break;

          case "Sélecteur de date":
          case "Date Picker":
            field["value"] = event;
            break;
          case "Sélectionneur d'utilisateur (utilisateur unique)":
            field["value"] = { name: val };
            break;
          case "Cases à cocher":
            field["value"] = [{ value: event.target.value }];
            break;
          case "Champ numérique":
            field["value"] = Number(event.target.value);
            break;
          default:
            field["value"] = event.target.value;
            break;
        }
      }
    });
    setformelements(newElements);
    console.log(formelements);
  };

  const saveFields = (screenTransition) => {
    const elements = [];
    if (isLoading) {
      screenTransition.tabs?.forEach((tab) => {
        tab.jiraFields.forEach((field) => {
          console.info(field);
          const el = { id: field.id, value: "", type: field.type };
          elements.push(el);
        });
      });
      setformelements(elements);
    }
  };

  const submitTransitoin = async (event) => {
    setisLoadingButton(true);
    seterrorMessage(null);
    let fields = {};

    formelements
      .filter((field) => field.id !== "attachment" && field.value !== "")
      .forEach((field) => {
        fields = { ...fields, [field.id]: field.value };
      });

    const file = formelements.filter((field) => field.id === "attachment");

    const data = { fields: fields };

    let formData = new FormData();
    if (file.length > 0) {
      if (file[0].value !== "") {
        file[0].value.map((fileElement) =>
          formData.append("file", fileElement)
        );
      }
    }

    if (
      idProject === "10700" &&
      (idTransition === "511" || idTransition === "531") &&
      (file[0].value.length === 0 || file[0].value === "")
    ) {
      setisLoadingButton(false);
      seterrorMessage("Veuillez joindre la pièce jointe");
      return false;
    }

    if (
      idProject === "11302" &&
      (idTransition === "291" || idTransition === "301") &&
      (file[0].value.length === 0 || file[0].value === "")
    ) {
      setisLoadingButton(false);
      seterrorMessage("Veuillez joindre la pièce jointe");
      return false;
    }

    if (
      idProject === "11101" &&
      (idTransition === "291" || idTransition === "301") &&
      (file[0].value.length === 0 || file[0].value === "")
    ) {
      setisLoadingButton(false);
      seterrorMessage("Veuillez joindre la pièce jointe");
      return false;
    }

    if (
      idProject === "11100" &&
      (idTransition === "291" || idTransition === "301") &&
      (file[0].value.length === 0 || file[0].value === "")
    ) {
      setisLoadingButton(false);
      seterrorMessage("Veuillez joindre la pièce jointe");
      return false;
    }

    if (
      idProject === "10300" &&
      idTransition === "311" &&
      (!fields.customfield_11468 || fields.customfield_11468 === "")
    ) {
      console.log("error");
      setisLoadingButton(false);
      seterrorMessage("Veuillez mentionner une date.");
      return false;
    }

    let error = null;

    try {
      let data = await TransitionsService.executeUpdateTrannstionById(
        issueId,
        fields,
        idTransition
      );
      if (file.length > 0) {
        await IssueService.saveAttachment(issueId, formData)
          .then((response) => {
            console.log(response.date);
          })
          .catch((error) => {
            console.log(error.response.data);
            error = error.response.data;
            seterrorMessage(error);
          });
      }

      await IssueService.sendcomment(issueKey, comment);
      refreshPage();
    } catch (err) {
      error = err.response.data;
      seterrorMessage(error);
    }
    setisLoadingButton(false);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const showerror = () => {
    if (!errorMessage.errorMessages) {
      return (
        <Banner
          appearance="error"
          icon={<ErrorIcon label="" secondaryColor="inherit" />}
          isOpen
        >
          {errorMessage}
        </Banner>
      );
    } else {
      if (errorMessage.errorMessages.length > 0) {
        return (
          <Banner
            appearance="error"
            icon={<ErrorIcon label="" secondaryColor="inherit" />}
            isOpen
          >
            {errorMessage.errorMessages[0]}
          </Banner>
        );
      } else {
        return (
          <Banner
            appearance="error"
            icon={<ErrorIcon label="" secondaryColor="inherit" />}
            isOpen
          >
            {errorMessage.errors[Object.keys(errorMessage.errors)[0]]}
          </Banner>
        );
      }
    }
  };

  return (
    <div>
      {open && (
        <Modal onClose={toggle} width="large">
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
          <div className="model-body">
            <ModalBody>
              {errorMessage && showerror()}
              <FormContext.Provider
                value={{
                  handleChange: handleChange,
                }}
              >
                <Form onSubmit={(data) => console.log(data)}>
                  {({ formProps }) => (
                    <form {...formProps}>
                      {!isLoading ? loadForm() : <p>loading</p>}
                    </form>
                  )}
                </Form>
              </FormContext.Provider>
            </ModalBody>
          </div>
          <ModalFooter>
            <Button appearance="subtle" onClick={toggle} autoFocus>
              Cancel
            </Button>
            <LoadingButton
              appearance="primary"
              autoFocus
              type="submit"
              onClick={(event) => submitTransitoin(event)}
              isLoading={isLoadingButton}
            >
              {title}
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default Transitionpage;
