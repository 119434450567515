const formatDate = (date, time, weekDay) => {
  let options = {};

  options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  if (time) {
    options.hour = "numeric";
    options.minute = "numeric";
  }

  if (weekDay) {
    options.weekday = "long";
  }

  return new Date(date).toLocaleDateString("fr-FR", options);
};

export default formatDate;
