class CaptchaService {
  incrimentTantative = () => {
    let tantative = localStorage.getItem("tantative");

    if (tantative === null) {
      localStorage.setItem("tantative", 0);
    } else {
      var attempts = parseInt(tantative);
      localStorage.setItem("tantative", ++attempts);
    }
  };

  initTantative = () => {
    localStorage.setItem("tantative", 0);
  };

  getTantative = () => {
    return localStorage.getItem("tantative");
  };

  displayCaptcha = () => {
    let tantative = localStorage.getItem("tantative");

    if (tantative && tantative >= 5) {
      return true;
    }
    return false;
  };
}

export default new CaptchaService();
