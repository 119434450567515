/* eslint-disable indent */
import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import HeaderBanner from "./components/headerBanner/HeaderBanner";
import Navmenu from "./components/navMenu/NavMenu";
import Homepage from "./pages/Home/Homepage";
import HomePageDsc from "./pages/Home/HomePageDsc";
import HomePageDsp from "./pages/Home/HomePageDsp";
import CreateIssuePage from "./pages/Issue/create/CreateIssuePage";
import Showissuepage from "./pages/Issue/show/ShowIssuePage";
import Login from "./pages/Login/Login";
import Notfoundpage from "./pages/NotFoundPage";
import ChangePassword from "./pages/password/ChangePassword";
import Restpassword from "./pages/password/RestPassword";
import Profile from "./pages/profile/Profile";
import Search from "./pages/Search/Search";
import UserList from "./pages/User/UserList";
import AuthenticationService from "./services/AuthenticationService";
import UserService from "./services/UserService";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }
  handleOnIdle(event) {
    AuthenticationService.signOut();
    window.location = "/login";
  }

  render() {
    return (
      <div className="bodyContainer">
        <Router>
          <Switch>
            <Route path="/login" exact={true} component={Login} />
            <Route
              path="/restRequest"
              render={(props) => <Restpassword {...props} />}
            />
            <Route
              path="/rest/:token"
              render={(props) => <ChangePassword {...props} />}
            />
            <Route
              path="/rest"
              render={(props) => <ChangePassword {...props} />}
            />

            <Route path="/" component={Homepage}>
              <IdleTimer
                ref={(ref) => {
                  this.idleTimer = ref;
                }}
                timeout={1000 * 60 * 60}
                onIdle={this.handleOnIdle}
              >
                <Navmenu />
                <div className="appHeaderBanner">
                  <HeaderBanner />
                </div>
                <Route
                  path="/"
                  exact={true}
                  render={(props) =>
                    AuthenticationService.isLogin() ? (
                      <Homepage {...props} />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />

                <Route
                  path="/dashboardDSC"
                  render={(props) =>
                    AuthenticationService.isLogin() ? (
                      <HomePageDsc {...props} />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />

                <Route
                  path="/dashboardDSP"
                  render={(props) =>
                    AuthenticationService.isLogin() ? (
                      <HomePageDsp {...props} />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />

                <Route
                  path="/create"
                  render={(props) =>
                    AuthenticationService.isLogin() ? (
                      <CreateIssuePage {...props} />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
                <Route
                  path="/search"
                  render={() =>
                    AuthenticationService.isLogin() ? (
                      <Search />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/issue/:id"
                  render={(props) =>
                    AuthenticationService.isLogin() ? (
                      <Showissuepage {...props} />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
                <Route
                  path="/users"
                  render={(props) =>
                    AuthenticationService.isLogin() &&
                    AuthenticationService.getclim("roles").includes(
                      "jira-administrators"
                    ) ? (
                      // eslint-disable-next-line react/jsx-indent
                      <UserList {...props} />
                    ) : (
                      <Redirect to="/not-found" />
                    )
                  }
                />
                <Route
                  path="/viewProfile"
                  render={(props) =>
                    AuthenticationService.isLogin() ? (
                      <Profile />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
                <Route path="/not-found" component={Notfoundpage} />
              </IdleTimer>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}
