import axios from "axios";
class ConfigurationService {
  async getConfig() {
    return await axios.get(process.env.REACT_APP_API_URL + "/config");
  }

  async getAllowedExtensions() {
    return await axios.get(
      process.env.REACT_APP_API_URL + "/plugin/allowed-extensions"
    );
  }
}
export default new ConfigurationService();
