import React from "react";
import Footer from "../../components/footer/Footer";
import "./Homepage.css";
import AuthenticationService from "../../services/AuthenticationService";
import HomeSideBare from "./HomeSideBare";
import DspTable from "../../components/home/homeDsp/DspTable";

const jqlHisorique = `project = DSF AND issuetype = Sinistre AND assignee != currentUser() AND ("CDC Orientation" = currentUser() OR "Chef d'agence" = currentUser() OR "CDC Souscription" = currentUser())`;
const jqlDossier = `project = DSF AND issuetype = Sinistre AND status not in (Clos, "Police non valide", Rejetée) AND assignee in (currentUser()) ORDER BY updatedDate DESC`;
const jqlwaiting = `project = DSF AND issuetype = Sinistre AND status = "Soumis CDC" AND ods_joint is EMPTY AND ("CDC Souscription" = currentUser() OR "CDC Orientation" = currentUser() OR "Chef d'agence" = currentUser()) AND assignee != currentUser()`;

const HomePageDsp = () => {
  const projects = AuthenticationService.getclim("project");

  const HomeHeader = () => {
    return (
      <div className="homeHeader">
        <p>Stellantis - Espace CDC</p>
      </div>
    );
  };

  const showSideBar = () => {
    if (projects.length >= 2 || projects.includes("Bris de glace")) return true;
  };

  const tableStyle = () => {
    return showSideBar()
      ? { width: "82vw" }
      : { width: "95vw", marginLeft: "3vw" };
  };

  return (
    <div>
      <div>
        <div className="dashboardContentDSC">
          <table>
            <tr>
              <div layout="fluid">
                {showSideBar() && (
                  <td style={{ borderRight: "1px solid rgb(205, 205, 205)" }}>
                    <div medium={2}>
                      <div>
                        <HomeSideBare />
                      </div>
                    </div>
                  </td>
                )}
                <td>
                  <div medium={10}>
                    <div>
                      <div>{HomeHeader()}</div>
                    </div>
                    <div className="dashboard-shim"></div>
                    <div>
                      <ul className="column first sortable ui-sortable">
                        <li style={tableStyle()}>
                          <DspTable
                            jql={jqlDossier}
                            color="#FF8B00"
                            title="Mes dossiers à traiter - Stellantis"
                          />
                        </li>
                        <li style={tableStyle()}>
                          <DspTable
                            jql={jqlwaiting}
                            color="#028759"
                            title="Dossiers en attente ODS - Stellantis"
                          />
                        </li>
                        <li style={tableStyle()}>
                          <DspTable
                            jql={jqlHisorique}
                            color="#0847A6"
                            title="Historique agence - Stellantis"
                          />
                        </li>
                      </ul>
                      <ul className="column second"></ul>
                      <ul className="column third"></ul>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageDsp;
