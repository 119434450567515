import React from "react";
import { Profile } from "@atlaskit/atlassian-navigation";
import man from "../../assert/avatar/man.png";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "@atlaskit/popup";
import { ButtonItem, PopupMenuGroup, Section } from "@atlaskit/menu";
import AuthenticationService from "../../services/AuthenticationService";
import Avatar from "@atlaskit/avatar";

const NestedPopup = () => {
  const history = useHistory();
  const singOut = () => {
    AuthenticationService.signOut();
    history.push("/login");
  };
  const profile = () => {
    history.push("/viewProfile");
  };
  return (
    <div>
      <Section>
        <ButtonItem onClick={profile}>Profil</ButtonItem>
      </Section>
      <Section hasSeparator>
        <ButtonItem onClick={singOut}>Déconnexion</ButtonItem>
      </Section>
    </div>
  );
};

const ProfileItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-start"
      content={() => <NestedPopup />}
      trigger={(triggerProps) => (
        <Profile
          {...triggerProps}
          tooltip=""
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          icon={<Avatar size="small" />}
        />
      )}
    />
  );
};

export default ProfileItem;
