import { React, Fragment } from "react";
import { AtlassianNavigation } from "@atlaskit/atlassian-navigation";
import ProducthomeComp from "./ProductHomeComp";
import CreateComp from "./CreateComp";
import { PrimaryButton } from "@atlaskit/atlassian-navigation";
import { useHistory } from "react-router-dom";
import ProfileItem from "./ProfileItem";
import Searchitem from "./SearchItem";
import Settingsitem from "./SettingsItem";

import "./NavMenu.css";
import AuthenticationService from "../../services/AuthenticationService";

const Navmenu = () => {
  const history = useHistory();

  const redirectToSearch = () => history.push("/search");

  const redirectToDashboard = () => history.push("/");

  AuthenticationService.getclim("roles").includes("jira-administrators");

  return (
    <div className="navMenuBody">
      <AtlassianNavigation
        renderProductHome={ProducthomeComp}
        primaryItems={[
          <PrimaryButton
            className="PrimaryItem"
            onClick={redirectToDashboard}
            id="TableauxdeBordButtonNavMenu"
          >
            Tableaux de Bord
          </PrimaryButton>,
          <PrimaryButton
            className="PrimaryItem"
            onClick={redirectToSearch}
            id="searchButtonNavMenu"
          >
            Recherche
          </PrimaryButton>,
        ]}
        renderCreate={CreateComp}
        renderProfile={ProfileItem}
        renderSearch={Searchitem}
        renderSettings={
          AuthenticationService.getclim("roles").includes("jira-administrators")
            ? Settingsitem
            : null
        }
      />
    </div>
  );
};

export default Navmenu;
