function formatSize(bytes) {
  const sizes = ["Bytes", "kB", "mB"];

  if (bytes === 0) return "n/a";

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  if (i === 0) return `${bytes} ${sizes[i]}`;

  return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
}

export default formatSize;
