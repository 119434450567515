import React from "react";
import { Container, Row, Col } from "react-grid-system";
import "./NavMenu.css";
import { Link } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";
import SearchIcon from "@atlaskit/icon/glyph/search";
import "./NavMenu.css";

const ShowAll = ({ inputJql }) => {
  const buildJql = () => {
    const projects = AuthenticationService.getclim("project");
    if (projects.includes("Bris de glace")) {
      return `project = BDG AND issuetype = 'Dossier BDG' AND ${inputJql} `;
    }
    if (projects.includes("GIGA Bris de glace")) {
      return `project = GIGA AND issuetype = 'Dossier BDG' AND  ${inputJql} ORDER BY updated DESC`;
    }
    if (projects.includes("CNMA Bris de Glace")) {
      return `project = CNMA AND issuetype = 'Dossier BDG' AND ${inputJql} ORDER BY updated DESC`;
    }

    if (projects.includes("Déclaration des Sinistres  CNMA")) {
      return `project = DSC AND issuetype = 'Sinistre' ${inputJql} ORDER BY updated DESC`;
    }
  };

  const url = "/search?jql=" + buildJql();

  return (
    <div className="elements">
      <Row>
        <Col>
          <Row className="elemenet-summart">
            <SearchIcon size="small" primaryColor="#2966CB" />

            <Link className="linkclass" to={url}>
              Afficher tous les Tickets
            </Link>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ShowAll;
