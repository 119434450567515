import LoadingButton from "@atlaskit/button/loading-button";
import { Field, FormHeader } from "@atlaskit/form";
import PageHeader from "@atlaskit/page-header";
import SectionMessage from "@atlaskit/section-message";
import Textfield from "@atlaskit/textfield";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import RestPasswordService from "../../services/RestPasswordService";
import "./RestPassword.css";

import Footer from "../../components/footer/Footer";
import HeaderBanner from "../../components/headerBanner/HeaderBanner";

const Restpassword = () => {
  const [username, setusername] = useState("");
  const [isSend, setisSend] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [header, setheader] = useState(
    "Vous ne pouvez pas accéder à votre compte ?"
  );

  const handelchange = (event) => {
    setusername(event.target.value);
    console.log(username);
  };

  const onclickhandler = (event) => {
    setisLoading(true);
    RestPasswordService.requestRestPassword(username)
      .then((res) => {
        if (res.status === 200) {
          setisSend(true);
          setisLoading(false);
        }
      })
      .catch((error) => {
        setisSend(true);
        setisLoading(false);
      });
  };

  const requestPage = () => (
    <div className="page-panel-content">
      <div className="FormHeader">
        <FormHeader>
          <p>Vous ne pouvez pas accéder à votre compte ?</p>
        </FormHeader>
      </div>
      <SectionMessage>
        <p>
          Si vous ne pouvez pas accéder à Jira, remplissez ce formulaire et vous
          recevrez un courrier électronique avec les détails pour accéder de
          nouveau à votre compte.
        </p>
      </SectionMessage>

      <div className="fieldGroup">
        <Field
          aria-required={true}
          name="username"
          label="Nom d'utilisateur"
          isRequired={false}
          defaultValue=""
        >
          {({ fieldProps }) => {
            return (
              <Fragment>
                <Textfield
                  name="basic"
                  value={username}
                  onChange={handelchange}
                  aria-label="default text field"
                />
              </Fragment>
            );
          }}
        </Field>
      </div>
      <div className="buttonsContainer">
        <div className="">
          <LoadingButton
            appearance="primary"
            isLoading={isLoading}
            onClick={(event) => onclickhandler(event)}
          >
            Envoyer
          </LoadingButton>

          {"  "}
          <Link className="linkclass" to={"/login"}>
            Annuler
          </Link>
        </div>
      </div>
    </div>
  );

  const infoPage = () => (
    <div className="page-panel-content">
      <div className="headerStyel">
        <PageHeader>
          Lien de réinitialisation de mot de passe envoyé avec succès
        </PageHeader>
      </div>

      <SectionMessage appearance="success">
        <p>
          Un lien de réinitialisation de mot de passe vous a été envoyé par
          courrier électronique. Vous pouvez cliquer sur ce lien et choisir un
          nouveau mot de passe. Si l'e-mail n'arrive pas, Veuillez contacter vos
          administrateurs Jira.
        </p>
      </SectionMessage>
    </div>
  );

  return (
    <div>
      <div className="headerbannerResetPassword">
        <HeaderBanner />
      </div>
      <div className="content">
        <div className="pagePanelGolbal">
          <div className="page-panel-inner">
            {isSend ? infoPage() : requestPage()}
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Restpassword;
