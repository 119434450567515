import React from "react";

const Propreties = ({ propretiesList }) => {
  return (
    <div>
      <div>
        <ul className="properties">
          {propretiesList &&
            propretiesList.map((property) => (
              <li className="propertieList" key={property.name}>
                {" "}
                {property.name + " " + property.value}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Propreties;
