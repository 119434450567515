import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  frFR,
} from "@mui/x-data-grid";
import GroupeService from "../services/GroupeService";
import UserService from "../services/UserService";
import "./SelectUser.css";
import AuthenticationService from "../services/AuthenticationService";

const SelectUser = ({ selectuser, setUser }) => {
  const [options, setOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGroupId = () => {
    const projects = AuthenticationService.getclim("project");
    return projects.includes("Bris de glace") ? 10113 : 12010;
  };

  const getUsers = async () => {
    getGroupId();
    const fetchResult = await UserService.getUsersByGroup(getGroupId());
    setLoading(false);
    setUsers(fetchResult);
  };

  const groupsList = () => {
    GroupeService.getGroups()
      .then((response) => {
        const groupes = response.data.map((group) => {
          return {
            label: group.groupName,
            value: group.id_group,
          };
        });
        setOptions(groupes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      field: "username",
      headerName: "Nom d utilisateur",
      width: 150,
      align: "left",
    },
    { field: "name", headerName: "Nom complet", width: 180 },
    { field: "mail", headerName: "Adresse électronique", width: 150 },
  ];

  const rows = users.map((user) => ({
    id: user.id, // Assuming user.id is unique
    username: user.name,
    name: `${user.firstname} ${user.lastname} `,
    mail: user.emailAddress,
  }));

  const onRowClick = (rowData) => {
    const user = rowData.row;
    setSelectedRow(user.username);
    setUser(user);
  };

  const getRowId = (row) => {
    return row.username;
  };

  const getRowClassName = (params) => {
    return params.id === selectedRow ? "selected-row" : "";
  };

  return (
    <div>
      <div id="table" style={{ height: "30vw", width: "100%" }}>
        <DataGrid
          rowHeight={40}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={rows}
          loading={loading}
          loadingSpinnerSize="large"
          onRowClick={(rowData) => onRowClick(rowData)}
          getRowId={getRowId}
          getRowClassName={getRowClassName}
          components={{
            Toolbar: CustomToolbar,
          }}
          sx={{
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#bfbfbf",
            },
          }}
        />
      </div>
    </div>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export default SelectUser;
