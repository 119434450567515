import axios from "axios";

class UserService {
  async getAlLUsers() {
    return await axios
      .get(process.env.REACT_APP_API_URL + "/users", {})
      .then((response) => {
        const users = response.data;
        return users;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async getUsersByGroup(idGroup) {
    return await axios
      .get(process.env.REACT_APP_API_URL + `/group/${idGroup}/users`, {})
      .then((response) => {
        const users = response.data;
        return users;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async getAllUsersNames() {
    return await axios
      .get(process.env.REACT_APP_API_URL + "/users", {})
      .then((response) => {
        const users = response.data;
        const namesUsers = users.map((user) => ({
          name: user.name,
          id_user: user.id_user,
        }));
        return namesUsers;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async searchUsers(user) {
    return await axios
      .get(process.env.REACT_APP_API_URL + "/users/search", {
        params: {
          user: user,
        },
      })
      .then((response) => {
        const users = response.data;
        return users;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async getUserByName(user) {
    return await axios
      .get(process.env.REACT_APP_API_URL + "/users", {
        params: {
          name: user,
        },
      })
      .then((response) => {
        const users = response.data;
        return users;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async activateUser(username) {
    return await axios.post(
      process.env.REACT_APP_API_URL + "/users/activate?username=" + username,
      {}
    );
  }
}

export default new UserService();
