import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import { v4 as uuid } from "uuid";

import Lozenge from "@atlaskit/lozenge";
import "./dsp.css";

import Tablemessageerror from "../../TableMessageError";
import formatDate from "../../../helpers/format-date-fr";
import IssueService from "../../../services/IssueService";
import { Link } from "react-router-dom";

const DspTable = ({ jql, color, title }) => {
  const [issues, setissues] = useState([]);
  const [loading, setloading] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      await IssueService.executeJql(jql)
        .then((respons) => {
          setissues(respons.data);
          setloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const head = {
    cells: [
      {
        content: "Clé",
        width: 8,
      },
      {
        key: "resume",
        content: "Résumé",
        isSortable: true,
        width: 20,
      },

      {
        content: "Création",
        width: 10,
      },

      {
        content: "Etat",
        width: 10,
      },
      {
        content: "Date du sinistre",
        width: 10,
      },
    ],
  };

  const rows = issues.map((issue) => ({
    key: uuid(),
    cells: [
      {
        id: issue.key,
        key: issue.key,
        content: (
          <Link className="linkclass" to={"/issue/" + issue.key}>
            {issue.key}
          </Link>
        ),
      },
      {
        id: issue.key,
        key: issue.summary,
        content: (
          <Link className="linkclass" to={"/issue/" + issue.key}>
            {issue.summary}
          </Link>
        ),
      },
      {
        key: issue.created,
        content: formatDate(issue.created),
      },

      {
        key: issue.etat,
        content: <Lozenge appearance="inprogress">{issue.etat}</Lozenge>,
      },

      {
        key: issue.date_sinistre,
        content: formatDate(issue.date_sinistre),
      },
    ],
  }));

  return (
    <div className="tablecontentprocessed">
      <div className="dashboard-dsp" style={{ background: color }}>
        <h3 className="dashboard-item-titler">{title}</h3>
      </div>
      <DynamicTable
        className="tablecontent"
        head={issues.length > 0 ? head : null}
        rows={rows}
        defaultSortKey="number"
        defaultSortOrder="ASC"
        rowsPerPage={5}
        defaultPage={1}
        isLoading={loading}
        loadingSpinnerSize="large"
        emptyView={<Tablemessageerror />}
        isFixedSize="true"
      />
    </div>
  );
};
export default DspTable;
