import Avatar from "@atlaskit/avatar";
import Comment, { CommentAuthor, CommentTime } from "@atlaskit/comment";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { to_markdown } from "jira2md";
import React from "react";
import formatDate from "../../helpers/format-date-fr";
import "./CommentItem.css";

const Commentitem = ({ comment }) => {
  const getParts = (sentence, first, last) => {
    let goodParts = [];

    const allParts = sentence.split(first);

    allParts.forEach((part) => {
      if (part.indexOf(last) > -1) {
        const goodOne = part.split(last)[0];
        goodParts = goodParts.concat(goodOne);
      }
    });

    return goodParts;
  };
  const transformationMentions = (text) => {
    const stuffIneed = getParts(text, "[~", "]");
    stuffIneed.forEach((element, i) => {
      text = text.replace(`[~${element}]`, `[${element}| ]`);
    });
    return text;
  };

  const commentBody = (body) => {
    return (
      <div>
        <MarkdownPreview
          source={to_markdown(transformationMentions(body))}
          rehypeRewrite={(node, index, parent) => {
            if (
              node.tagName === "a" &&
              parent &&
              /^h(1|2|3|4|5|6)/.test(parent.tagName)
            ) {
              parent.children = [parent.children[1]];
            }
          }}
        />
      </div>
    );
  };

  return (
    <div data-testid="comment" className="commentBoxItem">
      <Comment
        avatar={<Avatar name="John Smith" size="xsmall" />}
        author={
          <div className="CommentAuthor">
            <CommentAuthor>{comment.author.displayName}</CommentAuthor>
          </div>
        }
        time={
          <CommentTime>
            {"a ajouté un commentaire  -" + formatDate(comment.updated, true)}
          </CommentTime>
        }
        content={
          <div className="contentCommentary">{commentBody(comment.body)}</div>
        }
      />
    </div>
  );
};

export default Commentitem;
