/* eslint-disable indent */
import AuthenticationService from "../services/AuthenticationService";

const getnameProjectByUser = () => {
  const projets = AuthenticationService.getclim("project");
  console.log(projets);

  switch (projets[0]) {
    case "Bris de glace":
      return "BDG";
    case "GIGA Bris de glace":
      return "GIGA";
    case "CNMA Bris de Glace":
      return "CNMA";
    case "CAAR Bris de glace":
      return "CAAR";
    default:
      return "";
  }
};

export default getnameProjectByUser;
