import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import React, { useContext, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import striptags from "striptags";
import { FormContext } from "../../pages/FormContext";

const SqlCustomField = ({
  field_id,
  field_label,
  required,
  val,
  description,
  selectedValeu,
}) => {
  const { handleChange, issue, setSelectedSqlValeu } = useContext(FormContext);

  const sqlSelect = () => {
    return (
      <Field label={field_label} name={field_id} defaultValue="">
        {() => (
          <Select
            spacing="compact"
            inputId={field_label}
            className="single-select"
            classNamePrefix="react-select"
            options={val}
            value={selectedValeu}
            placeholder="Choisissez une valeur ..."
            onChange={(event) => {
              handleChange(field_id, event);
              setSelectedSqlValeu(field_id, event);
            }}
          />
        )}
      </Field>
    );
  };

  return <div>{sqlSelect()}</div>;
};

export default SqlCustomField;
