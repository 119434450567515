import React from "react";
import { Container, Row, Col } from "react-grid-system";
import compactString from "../../helpers/compact-string";
import BDGAvatar from "../../images/viewavatar.svg";
import "./NavMenu.css";

const RowSearch = ({ title, issuekey }) => {
  const url = "/issue/" + issuekey;
  return (
    <a href={url}>
      <div className="elements">
        <Row>
          <div className="logo">
            <img alt="" src={BDGAvatar} align="center"></img>
          </div>

          <Col style={{ maxWidth: "92%" }}>
            <Row className="elemenet-summart">{compactString(title, 30)}</Row>
            <Row className="element-key">
              <span>{issuekey}</span>
            </Row>
          </Col>
        </Row>
      </div>
    </a>
  );
};

export default RowSearch;
