/* eslint-disable indent */
import Banner from "@atlaskit/banner";
import Button, { LoadingButton } from "@atlaskit/button";
import Form, { FormFooter } from "@atlaskit/form";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import Spinner from "@atlaskit/spinner";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import axios from "axios";
import convertSize from "convert-size";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Element from "../../../../components/Element";
import maxFileSizeByList from "../../../../helpers/max-file-size-ByList";
import AuthenticationService from "../../../../services/AuthenticationService";
import ConfigurationService from "../../../../services/ConfigurationService";
import SqlCustomFieldService from "../../../../services/SqlCustomFieldService";
import { FormContext } from "../../../FormContext";
import "../CreateIssuePage.css";

export default class CreateIssueFormGIGA extends Component {
  static contextType = FormContext;
  constructor(props) {
    super(props);
    this.state = {
      disableCreate: true,
      showSpin: false,
      project: [],
      loading: true,
      selectOptions: [],
      id: props.idIssueType,
      name: props.nameIssueType,
      selectOptionsProject: [],
      idProject: props.idProject,
      nameproject: props.projectName,
      fields: [],
      tabs: [],
      formelements: [],
      open: false,
      error: null,
      successful: false,
      message: null,
      isLoadingButton: false,
      showsuivant: true,
      MaxFileSize: 0,
      MaxFileSizeString: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    console.log("hello");
    /*this.setState(
      {
        idProject: 10200,
        nameproject: "Bris de glace",
        name: "Dossier BDG",
        id: 10301,
      },
      
    );*/
    this.getFields();
  }

  async getFields() {
    this.setState({ showSpin: true, loading: true });
    const res1 = await axios.get(
      process.env.REACT_APP_API_URL +
        "/screen/form/" +
        this.state.idProject +
        "/" +
        this.state.id
    );
    const data1 = res1.data;
    const screen = data1[0];
    console.info(screen.tabs);

    var MaxFileSizeString = "";

    const res = await ConfigurationService.getConfig()
      .then((res) => {
        MaxFileSizeString = res.data.maxfilesize;
      })
      .catch((error) => {});

    MaxFileSizeString =
      MaxFileSizeString.slice(0, -2) + " " + MaxFileSizeString.slice(-2);

    const maxFileSize = convertSize(MaxFileSizeString);

    this.sqlCustomFieldInitValue(screen.tabs);
    this.addHidentoFields(screen.tabs);
    this.setState({
      tabs: screen.tabs,
      disableCreate: false,
      showSpin: false,
      showsuivant: false,
      MaxFileSize: maxFileSize,
      MaxFileSizeString: MaxFileSizeString,
    });
    this.saveFields();
  }

  addHidentoFields(tabs) {
    tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (
          field.name === "Autre Modèle" ||
          field.name === "Autre Marque" ||
          field.name === "Type du véhicule"
        ) {
          field.hiden = true;
        } else {
          field.hiden = false;
        }
      });
    });
  }

  getvalue(idField) {
    console.log(this.state.formelements);
    console.log(idField);
    const field = this.state.formelements.filter((f) => f.id === idField);
    console.log(field);
    return field[0].value;
  }

  showMarqueAndAutre() {
    const vlMarque = this.getvalue(
      this.getFieldByName("Marque du véhicule").id
    );
    const vlModele = this.getvalue(
      this.getFieldByName("Modèle du véhicule").id
    );
    const newtabs = this.state.tabs;

    if (vlMarque === "Autre") {
      newtabs.forEach((tab) => {
        tab.jiraFields.forEach((field) => {
          if (
            field.name === "Autre Modèle" ||
            field.name === "Autre Marque" ||
            field.name === "Type du véhicule"
          ) {
            field.hiden = false;
          }
        });
      });
    } else {
      if (vlMarque !== "Autre" && vlModele === "Autre") {
        newtabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            if (field.name === "Autre Modèle") {
              field.hiden = false;
            }
            if (field.name === "Autre Marque") {
              field.hiden = true;
            }
            if (field.name === "Type du véhicule") {
              field.hiden = false;
            }
          });
        });
      } else {
        newtabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            if (
              field.name === "Autre Modèle" ||
              field.name === "Autre Marque" ||
              field.name === "Type du véhicule"
            ) {
              field.hiden = true;
              if (
                field.name === "Autre Modèle" ||
                field.name === "Autre Marque"
              ) {
                field.allowedValues = "";
              }
            }
          });
        });
      }
    }
    this.setState({ tabs: newtabs }, console.log(this.state.tabs));
  }

  async initValeu(tabs) {
    tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        // if element is sqlCustomField
        if (field.type === "SQL Customfield") {
          //  get typologie
          const typo = this.gettypo(field.id);
          console.log(typo);
          if (typo.type === "SELECT") {
            // select allowedValue
            const df = SqlCustomFieldService.getDF(field.id).then(
              (response) => {
                return response.data;
              }
            );
            const value = SqlCustomFieldService.getOptions(field.id, df);
          }
        }
      });
    });
  }

  async gettypo(fieldid) {
    const typo = await SqlCustomFieldService.getSqlFieldsTypologie(fieldid);
    return typo;
  }

  handleChangeProject(e) {
    this.setState({ idProject: e.value, nameproject: e.label });
    this.getIssueTypes(e.label);
  }

  handleChangeIssueType(e) {
    this.setState({ id: e.value, name: e.label });
  }

  getModel = async (car) => {
    const listModels = await SqlCustomFieldService.getOptions(
      this.getFieldByName("Modèle du véhicule").id,
      car
    );

    return listModels;
  };

  getFieldByName = (name) => {
    var newField = null;
    this.state.tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (field.name === name) return (newField = field);
      });
    });
    return newField;
  };

  handleChange = async (id, event, val) => {
    const newElements = [...this.state.formelements];

    if (id === this.getFieldByName("Marque du véhicule").id) {
      const car = [
        {
          id: this.getFieldByName("Marque du véhicule").id,
          value: event.value,
        },
      ];
      const models = await this.getModel(car);
      const newTab = this.state.tabs;
      newTab.forEach((tab) =>
        tab.jiraFields
          .filter((field) => field.type === "SQL Customfield")
          .forEach((field) => {
            if (field.id === this.getFieldByName("Modèle du véhicule").id) {
              field.allowedValues = models;
            }
          })
      );
      this.setState({ tabs: newTab });
    }

    newElements.forEach((field) => {
      if (id === field.id) {
        switch (field.type) {
          // eslint-disable-next-line
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "SQL Customfield":
            if (event) {
              field["value"] = event.value;
            } else {
              field["value"] = val;
            }
            break;
          case "Champ de système":
            if (id === "attachment") {
              field["value"] = val;
            } else {
              field["value"] = event.value;
            }
            break;
          case "Liste de choix (à choix unique)":
            field["value"] = { value: event.label };
            break;
          case "Liste de choix (à choix multiples)":
            field["value"] = val.map((v) => {
              const newv = { value: v };
              return newv;
            });
            break;

          case "Sélecteur de date":
            field["value"] = event;
            break;
          default:
            field["value"] = event.target.value;
            this.changeFieldValinTab(id, event.target.value);
            break;
        }
      }
    });
    this.setState({ formelements: newElements });
    this.showMarqueAndAutre();
  };

  updateTabsState = (childs) => {
    this.updateChild(childs);
  };

  updateChild(childs) {
    if (childs) {
      let newTabs = this.state.tabs;
      let newFormElement = this.state.formelements;
      if (childs === "empty") {
        newTabs.forEach((tab) => {
          tab.jiraFields.forEach((field) => {
            if (
              field.name === "Numéro de police" ||
              field.name === "Date d'effet" ||
              field.name === "Date d'échéance" ||
              field.name === "Nom" ||
              field.name === "Prénom" ||
              field.name === "Adresse du client"
            ) {
              field.allowedValues = "";
            }
          });
        });
      } else {
        childs.forEach((child) => {
          newTabs.forEach((tab) => {
            tab.jiraFields.forEach((field) => {
              let childId = "customfield_" + child.id;
              if (
                childId === field.id &&
                field.type !== "Liste de choix (à choix unique)"
              ) {
                field.allowedValues = child.value;
                newFormElement.forEach((el) => {
                  if (el.id === field.id) {
                    el.value = field.allowedValues;
                  }
                });
              }
            });
          });
        });
      }
      this.setState({ tabs: newTabs, formelements: newFormElement });
    }
  }

  changeFieldValinTab(idField, val) {
    let newTabs = this.state.tabs;
    newTabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        if (field.id === idField) {
          field.allowedValues = val;
        }
      });
    });

    this.setState({ tabs: newTabs });
  }

  tabs() {
    const form = (
      <Tabs onChange={(index) => console.log("Selected Tab", index + 1)}>
        <TabList>
          {this.state.tabs.length <= 1 ? (
            <Tab></Tab>
          ) : (
            this.state.tabs.map((tab) => {
              return <Tab>{tab.name}</Tab>;
            })
          )}
        </TabList>

        {this.state.tabs.map((tab) => {
          return (
            <TabPanel key={tab.id}>
              <div className="aui-page-panel-content">
                {tab.jiraFields.map((field) => {
                  return (
                    <div className="field-group">
                      <Element key={field.id} field={field} />
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          );
        })}
      </Tabs>
    );

    return form;
  }

  saveFields() {
    const elements = [];
    this.state.tabs.forEach((tab) => {
      tab.jiraFields.forEach((field) => {
        const el = { id: field.id, value: "", type: field.type };
        elements.push(el);
      });
    });
    this.setState({ formelements: elements, loading: false }, () => {
      console.log(this.state);
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState(
      { open: false, error: null, isLoadingButton: true },
      () => {}
    );

    let fields = {
      project: {
        id: this.state.idProject,
      },
      summary: "resumé",
      issuetype: {
        name: this.state.name,
      },
      reporter: { name: AuthenticationService.getCurrentUser().username },
    };

    this.state.formelements
      .filter((field) => field.id !== "attachment" && field.value !== "")
      .forEach((field) => {
        fields = { ...fields, [field.id]: field.value };
      });

    const file = this.state.formelements.filter(
      (field) => field.id === "attachment"
    );
    const data = { fields: fields };

    let formData = new FormData();

    if (file[0].value.length === 0 || file[0].value === "") {
      const errors = "Veuillez joindre L'ordre de réparation.";
      this.props.displayError(errors);
      this.setState({
        isLoadingButton: false,
      });
      return false;
    } else if (maxFileSizeByList(file, this.state.MaxFileSize)) {
      const errors =
        "La taille maximale du fichier de l'ordre de réparation est de " +
        this.state.MaxFileSizeString;
      this.props.displayError(errors);
      this.setState({
        isLoadingButton: false,
      });
      return false;
    } else {
      file[0].value.forEach((fileElement) => {
        formData.append("file", fileElement);
      });
    }

    formData.append("issue", JSON.stringify(data));
    console.log(JSON.stringify(data));
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + "/search/addIssue",
        formData,
        config
      )
      .then(
        (response) => {
          if (response.data === "") {
            this.setState({ open: true, error: "-" }, () => {});
            this.props.displayError("-");
          } else {
            if ("errors" in response.data) {
              console.log(response.data);
              const error = response.data.errors;
              this.props.displayError(error);
            } else {
              this.setState({
                successful: true,
                message: response.data,
                isLoadingButton: false,
                open: false,
                error: "",
              });
              console.log(response.data.key);
              this.redirectToShowISsue(response.data.key);
            }
          }
        },
        (err) => {
          this.props.displayError(err.response.data);
          this.setState({
            open: true,
            error: err.response.data,
            isLoadingButton: false,
          });
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  showerror = () => {
    if (Array.isArray(this.state.error.errorMessages)) {
      return (
        <Banner
          appearance="error"
          icon={<ErrorIcon label="" secondaryColor="inherit" />}
          isOpen
        >
          {this.state.error.errorMessages[0]}
        </Banner>
      );
    } else {
      return (
        <Banner
          appearance="error"
          icon={<ErrorIcon label="" secondaryColor="inherit" />}
          isOpen
        >
          {this.state.error.errors}
        </Banner>
      );
    }
  };

  showSuccessful = () => {
    return (
      <Banner appearance="announcement" isOpen>
        <p>save issue with id </p>
        {this.state.message.id}
        <p> and key </p>
        {this.state.message.key}
      </Banner>
    );
  };

  async sqlCustomFieldInitValue(tabs) {
    tabs.forEach((tab) =>
      tab.jiraFields
        .filter((field) => field.type === "SQL Customfield")
        .forEach((field) => {
          const res = SqlCustomFieldService.getSqlFieldsTypologie(field.id);
          res
            .then((val) => {
              const typo = val.data.type;
              if (typo === "SELECT") {
                const depandFields = SqlCustomFieldService.getDF(field.id).then(
                  (dfs) => {
                    return dfs.map((df) => {
                      df.value = this.getCustomFieldValue(df.id);
                      return df;
                    });
                  }
                );
                const options = depandFields.then((val) => {
                  return SqlCustomFieldService.getOptions(field.id, val);
                });

                options.then((val) => {
                  field.allowedValues = val;
                });
              }
            })
            .catch((err) => console.log("error"));
        })
    );

    return tabs;
  }

  async sqlCustomFieldInitValueState() {
    const newTab = this.state.tabs;
    newTab.forEach((tab) =>
      tab.jiraFields
        .filter((field) => field.type === "SQL Customfield")
        .forEach((field) => {
          const res = SqlCustomFieldService.getSqlFieldsTypologie(field.id);
          res
            .then((val) => {
              const typo = val.data.type;
              if (typo === "SELECT") {
                const depandFields = SqlCustomFieldService.getDF(field.id).then(
                  (dfs) => {
                    return dfs.map((df) => {
                      df.value = this.getCustomFieldValue(df.id);
                      return df;
                    });
                  }
                );
                const options = depandFields.then((val) => {
                  return SqlCustomFieldService.getOptions(field.id, val);
                });

                options.then((val) => {
                  field.allowedValues = val;
                });
              }
            })
            .catch((err) => console.log("error"));
        })
    );
    this.setState({ tabs: newTab });
  }

  getCustomFieldValue(idField) {
    const field = this.state.formelements.filter((elem) => elem.id === idField);
    return field[0].value;
  }

  loading = () => (
    <div style={{ flexWrap: "wrap", alignContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDrection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner size="large" />
      </div>
    </div>
  );

  redirectToShowISsue(id) {
    this.props.history.push("issue/" + id);
  }

  render() {
    return (
      <div>
        <FormContext.Provider
          value={{
            handleChange: this.handleChange,
            updateTabs: this.updateTabsState,
            initChilds: this.initChilds,
          }}
        >
          {this.state.showSpin && this.loading()}
          <Form onSubmit={(data) => console.log(data)}>
            {({ formProps }) => (
              <form {...formProps}>
                {!this.state.loading ? this.tabs() : <p></p>}
                <FormFooter align="start" className="formFooter">
                  <div className="buttonsContainer">
                    <LoadingButton
                      id="Envoyer_a_IPA_CreateIssue"
                      type="submit"
                      isLoading={this.state.isLoadingButton}
                      className="auiButton"
                      isDisabled={this.state.disableCreate}
                      onClick={(e) => this.handleSubmit(e)}
                    >
                      Envoyer à IPA
                    </LoadingButton>

                    <Button type="submit" appearance="link">
                      <Link className="linkclass" to="/">
                        Annuler
                      </Link>
                    </Button>
                  </div>
                </FormFooter>
              </form>
            )}
          </Form>
        </FormContext.Provider>
      </div>
    );
  }
}
