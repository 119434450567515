import LoadingButton from "@atlaskit/button/loading-button";
import { Field } from "@atlaskit/form";
import InlineDialog from "@atlaskit/inline-dialog";
import InlineMessage from "@atlaskit/inline-message";
import PageHeader from "@atlaskit/page-header";
import SectionMessage, {
  SectionMessageAction,
} from "@atlaskit/section-message";
import Textfield from "@atlaskit/textfield";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import HeaderBanner from "../../components/headerBanner/HeaderBanner";
import RestPasswordService from "../../services/RestPasswordService";
import "./RestPassword.css";

const uppercaseRegExp = /(?=.*?[A-Z])/;

const ChangePassword = (props) => {
  let params = useParams();
  const [loading, setloading] = useState(true);
  const [response, setresponse] = useState(null);
  const [password, setpassword] = useState("");
  const [confirmePassword, setconfirmePassword] = useState("");
  const [lastPassword, setlastPassword] = useState("");
  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState([]);
  const [apiError, setapiError] = useState(null);
  const [seccesslChange, setseccesslChange] = useState(false);
  const [passwordLength, setpasswordLength] = useState(false);
  const [upperCaseLenght, setupperCaseLenght] = useState(false);
  const [lowerCaseLenght, setlowerCaseLenght] = useState(false);
  const [chifferLenght, setchifferLenght] = useState(false);
  const [specialCharLength, setspecialCharLength] = useState(false);
  const [lastPasswordValidation, setlastPasswordValidation] = useState(false);
  const [differentToUsername, setdifferentToUsername] = useState(false);
  const [passwordPolicyValidation, setpasswordPolicyValidation] =
    useState(false);
  const [dialogOpen, setdialogOpen] = useState(false);

  useEffect(() => {
    getConstraints();
  }, []);

  const getConstraints = async () => {
    await RestPasswordService.getPasswordConstraints()
      .then((res) => {
        var respons = res.data;
        console.log(respons);
        setresponse(respons);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeHandler = (event) => {
    const value = event.target.value;
    setpassword(event.target.value);

    testLength(value);
    testUpperCase(value);
    testLowerrCase(value);
    testChiffer(value);
    testSpecialCahr(value);
    testLastPassword(value);
    testDifferentUsername(value);
  };

  const testLength = (password) => {
    if (isActive("lengthMin") || isActive("lengthMin")) {
      if (
        password.length >= getConstrantValueByName("lengthMin") &&
        password.length <= getConstrantValueByName("lengthMax")
      ) {
        setpasswordLength(true);
      } else {
        setpasswordLength(false);
      }
    } else {
      setpasswordLength(true);
    }
  };

  const testUpperCase = (password) => {
    if (isActive("upper")) {
      var numUpper = (password.match(/[A-Z]/g) || []).length;
      if (numUpper >= getConstrantValueByName("upper")) {
        setupperCaseLenght(true);
      } else {
        setupperCaseLenght(false);
      }
    } else {
      setupperCaseLenght(true);
    }
  };

  const testLowerrCase = (password) => {
    if (isActive("lower")) {
      var numLower = password
        .split("") // convert into array
        .filter((char) => char.match(/[a-z]/)).length; // filter out all lowercase chars // take the length
      numLower >= getConstrantValueByName("lower")
        ? setlowerCaseLenght(true)
        : setlowerCaseLenght(false);
    } else {
      setlowerCaseLenght(true);
    }
  };

  const testChiffer = (password) => {
    if (isActive("digit")) {
      const digitCount = password.match(/\d/g)?.length || 0;
      digitCount >= getConstrantValueByName("digit")
        ? setchifferLenght(true)
        : setchifferLenght(false);
    } else {
      setchifferLenght(true);
    }
  };

  const testSpecialCahr = (password) => {
    if (isActive("digit")) {
      var count =
        // eslint-disable-next-line no-useless-escape
        password.match(/[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)?.length || 0;

      count >= getConstrantValueByName("specialChars")
        ? setspecialCharLength(true)
        : setspecialCharLength(false);
    } else {
      setspecialCharLength(true);
    }
  };

  const testLastPassword = (password) => {
    if (isActive("lastPassword")) {
      if (typeof props.history.location.state !== "undefined") {
        validatPasswordState(password);
      } else {
        validatPasswordToken(password);
      }
    } else {
      setlastPasswordValidation(true);
    }
  };

  const validatPasswordState = (password) => {
    if (password === props.history.location.state.password) {
      setlastPasswordValidation(false);
    } else {
      setlastPasswordValidation(true);
    }
  };

  const validatPasswordToken = async (password) => {
    var respons = await RestPasswordService.validationPaswordApi(
      params.token,
      password
    );
    if (respons.data.passwordIsValid) {
      setlastPasswordValidation(true);
    } else {
      setlastPasswordValidation(false);
    }
  };

  const testDifferentUsername = (password) => {
    if (isActive("userInfo")) {
      if (typeof props.history.location.state !== "undefined") {
        validationUserNameState(password);
      } else {
        validationUsernameToken(password);
      }
    } else {
      setdifferentToUsername(true);
    }
  };

  const validationUserNameState = (password) => {
    if (
      password === props.history.location.state.username ||
      password === props.history.location.state.email ||
      password === props.history.location.state.firstname ||
      password === props.history.location.state.lastname
    ) {
      setdifferentToUsername(false);
    } else {
      setdifferentToUsername(true);
    }
  };

  const validationUsernameToken = async (password) => {
    var respons = await RestPasswordService.validationPaswordApi(
      params.token,
      password
    );
    console.log(respons.data);
    if (respons.data.usernameIsValid) {
      setdifferentToUsername(true);
    } else {
      setdifferentToUsername(false);
    }
  };

  const onchangeHandlerConfimePassword = (event) => {
    const value = event.target.value;
    setconfirmePassword(value);
    if (password === value) {
      seterror(false);
    } else {
      seterror(true);
    }
  };

  const onchangeHandlerLastPassword = (event) => {
    const value = event.target.value;
    setlastPassword(value);
  };

  const changePasswordHandler = async () => {
    if (
      !error &&
      specialCharLength &&
      chifferLenght &&
      lowerCaseLenght &&
      upperCaseLenght &&
      passwordLength
    ) {
      if (props.history.location.state) {
        await RestPasswordService.changePasswordUserName(
          props.history.location.state.username,
          password
        )
          .then((res) => {
            if (res.status === 200) {
              setseccesslChange(true);
            }
          })
          .catch((err) => {
            console.log(err.response.data);
            setapiError(err.response.data);
          });
      } else {
        await RestPasswordService.changePassword(params.token, password)
          .then((res) => {
            if (res.status === 200) {
              setseccesslChange(true);
            }
          })
          .catch((err) => {
            console.log(err.response);
            setapiError(err.response.data);
          });
      }
    } else {
      console.log("Non valide");
    }
  };

  const getConstrantValueByName = (name) => {
    if (loading) {
      return 0;
    } else {
      var element = response.filter((element) => element.name === name);
      return element[0].value;
    }
  };

  const isActive = (name) => {
    if (loading) {
      return 0;
    } else {
      var element = response.filter((element) => element.name === name);
      if (element.length > 0) return element[0].active;
    }
  };

  const PasswordValidator = () => {
    return (
      <div>
        <h2> Le nouveau mot de pas doit inclure </h2>
        <SectionMessage appearance={passwordLength ? "success" : null}>
          <p>Entre 10 et 64 Caractéres</p>
        </SectionMessage>

        <SectionMessage appearance={upperCaseLenght ? "success" : null}>
          <p>
            {loading ? null : getConstrantValueByName("upper")} Lettre(s)
            Miniscule(s)
          </p>
        </SectionMessage>

        <SectionMessage appearance={lowerCaseLenght ? "success" : null}>
          <p>1 Lettre(s) Majuscule(s)</p>
        </SectionMessage>

        <SectionMessage appearance={chifferLenght ? "success" : null}>
          <p>1 Chiffre(s) </p>
        </SectionMessage>

        <SectionMessage appearance={specialCharLength ? "success" : null}>
          <p>1 Caractéres Speciaux</p>
        </SectionMessage>

        <SectionMessage appearance={differentToUsername ? "success" : null}>
          <p>
            Différences de votre nom d'utilisateur votre nome , votre prénom ,et
            mail
          </p>
        </SectionMessage>
      </div>
    );
  };

  const ErrorFromApi = () => (
    <SectionMessage title="Mot de passe est incorrect" appearance="error">
      {apiError.isArray ? (
        apiError.map((msg) => <p>{msg}</p>)
      ) : (
        <p>{apiError}</p>
      )}
    </SectionMessage>
  );

  const SuccessfulUpdate = () => (
    <SectionMessage
      title="réinitialisation du mot de passe"
      appearance="success"
      actions={[
        <SectionMessageAction href="/login">Login</SectionMessageAction>,
      ]}
    >
      <p>Votre mot de passe à été changé avec succès</p>
    </SectionMessage>
  );

  const ConfirmationPasswordMessage = () => (
    <InlineMessage
      type="error"
      iconLabel="Error! This name is already in use. Try another."
      secondaryText="la confirmation du mot de passe ne correspond pas au mot de passe"
    >
      <p>La confirmation du mot de passe ne correspond pas au mot de passe</p>
    </InlineMessage>
  );

  const toggleDialog = () => setdialogOpen(!dialogOpen);

  return (
    <div className="content">
      <div className="headerbannerResetPassword">
        <HeaderBanner />
      </div>
      <div className="pagePanelGolbal">
        <div className="page-panel-inner">
          <div className="page-panel-content">
            {!seccesslChange ? (
              <div>
                <PageHeader className="header">
                  Mettre à jour votre mot de passe.
                </PageHeader>
                {apiError && <ErrorFromApi />}
                <div className="fieldGroup">
                  <Field
                    aria-required={true}
                    name="username"
                    label="Mot De Passe"
                    isRequired={false}
                    defaultValue=""
                  >
                    {({ fieldProps }) => {
                      return (
                        <Fragment>
                          <InlineDialog
                            onClose={() => {
                              setdialogOpen(false);
                            }}
                            content={
                              !loading && (
                                <div>
                                  <h2> Le nouveau mot de pas doit inclure </h2>
                                  {(isActive("lengthMin") ||
                                    isActive("lengthMin")) && (
                                    <SectionMessage
                                      appearance={
                                        passwordLength ? "success" : null
                                      }
                                    >
                                      <p>
                                        Entre{" "}
                                        {getConstrantValueByName("lengthMin")}{" "}
                                        et{" "}
                                        {getConstrantValueByName("lengthMax")}{" "}
                                        Caractéres
                                      </p>
                                    </SectionMessage>
                                  )}
                                  {isActive("lower") && (
                                    <SectionMessage
                                      appearance={
                                        lowerCaseLenght ? "success" : null
                                      }
                                    >
                                      <p>
                                        {getConstrantValueByName("lower")}{" "}
                                        Lettre(s) Miniscule(s)
                                      </p>
                                    </SectionMessage>
                                  )}
                                  {isActive("upper") && (
                                    <SectionMessage
                                      appearance={
                                        upperCaseLenght ? "success" : null
                                      }
                                    >
                                      <p>
                                        {getConstrantValueByName("upper")}{" "}
                                        Lettre(s) Majuscule(s)
                                      </p>
                                    </SectionMessage>
                                  )}
                                  {isActive("digit") && (
                                    <SectionMessage
                                      appearance={
                                        chifferLenght ? "success" : null
                                      }
                                    >
                                      <p>
                                        {getConstrantValueByName("digit")}
                                        Chiffre(s)
                                      </p>
                                    </SectionMessage>
                                  )}
                                  {isActive("specialChars") && (
                                    <SectionMessage
                                      appearance={
                                        specialCharLength ? "success" : null
                                      }
                                    >
                                      <p>
                                        {getConstrantValueByName(
                                          "specialChars"
                                        )}
                                        Caractéres Speciaux
                                      </p>
                                    </SectionMessage>
                                  )}
                                  {isActive("lastPassword") && (
                                    <SectionMessage
                                      appearance={
                                        lastPasswordValidation
                                          ? "success"
                                          : null
                                      }
                                    >
                                      <p>
                                        Différences de vos précédent mot de
                                        passe{" "}
                                      </p>
                                    </SectionMessage>
                                  )}

                                  {isActive("userInfo") && (
                                    <SectionMessage
                                      appearance={
                                        differentToUsername ? "success" : null
                                      }
                                    >
                                      <p>
                                        Différences de votre nom d'utilisateur
                                        votre nome , votre prénom ,et mail
                                      </p>
                                    </SectionMessage>
                                  )}
                                </div>
                              )
                            }
                            placement="right"
                            isOpen={dialogOpen}
                          >
                            <Textfield
                              type="password"
                              name="password"
                              value={password}
                              maxLength={64}
                              onClick={toggleDialog}
                              aria-label="default text field"
                              onChange={(event) => onchangeHandler(event)}
                            />
                          </InlineDialog>
                        </Fragment>
                      );
                    }}
                  </Field>
                  <Field
                    aria-required={true}
                    name="username"
                    label="Confirmation Mot De Passe"
                    isRequired={false}
                    defaultValue=""
                  >
                    {({ fieldProps }) => {
                      return (
                        <Fragment>
                          <Textfield
                            type="password"
                            name="confirmPassword"
                            value={confirmePassword}
                            aria-label="default text field"
                            onChange={(event) =>
                              onchangeHandlerConfimePassword(event)
                            }
                          />
                          {error && <ConfirmationPasswordMessage />}
                        </Fragment>
                      );
                    }}
                  </Field>
                </div>
                <div className="buttonsContainer">
                  <div className="">
                    <LoadingButton
                      appearance="primary"
                      onClick={() => changePasswordHandler()}
                    >
                      Envoyer
                    </LoadingButton>{" "}
                    <Link className="linkclass" to={"/login"}>
                      Annuler
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <SuccessfulUpdate />
            )}
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default ChangePassword;
