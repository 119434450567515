import React from "react";
import "./error-page.css";
import error from "../../images/error.svg";

const Errorpage = () => {
  return (
    <div id="issue-content">
      <div className="issue-body-content issue-error-content">
        <div className="issue-error">
          <div
            style={{
              backgroundImage: `url(${error})`,
              backgroundRepeat: "no-repeat",
              width: "10%",
              height: "100px",
              color: "blue",
            }}
          ></div>
          <h1>Impossible d'afficher ce ticket.</h1>
          <p>
            Il a peut-être été supprimé, ou vous n'êtes pas autorisé(e) à le
            consulter.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Errorpage;
