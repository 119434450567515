import React from "react";
//import "../../pages/Login/Login.css";
import "./Footer.css";
import { AtlassianLogo } from "@atlaskit/logo";
import { B200, B400, N100, N600, N400, N700 } from "@atlaskit/theme/colors";
import { JiraServiceManagementIcon } from "@atlaskit/logo";

import logoKepler from "../../images/logoKepler.png";

const Footer = () => {
  return (
    <footer className="footer">
      <section className="footer-body">
        <table align="center">
          <tbody>
            <tr align="center">
              <td style={{ fontSize: 16, alignItems: "center" }}>Powered by</td>

              <td
                style={{ fontSize: 16, alignItems: "center", paddingTop: 10 }}
              >
                <img
                  alt="Kepler's logo"
                  src={logoKepler}
                  width="20"
                  height="20"
                />
              </td>
              <td style={{ fontSize: 16, alignItems: "center" }}>
                {" "}
                Kepler technologies (V 1.0.0)
              </td>
              <td width="20"> . </td>
              <td style={{ fontSize: 16 }}>
                {" "}
                <a
                  className="a"
                  id="footer-kepler-website-link"
                  rel="noreferrer"
                  href="https://www.kepler-technologies.com/"
                  target="_blank"
                >
                  Notre site
                </a>{" "}
              </td>
              <td width="15"> . </td>
              <td style={{ fontSize: 15 }}>
                <a
                  className="a"
                  id="footer-report-problem-link"
                  rel="noreferrer"
                  href="https://support.kepler-technologies.com/servicedesk/customer/portal/12"
                  target="_blank"
                >
                  Signaler un problème
                </a>
              </td>
            </tr>
            <tr height="40">
              <td></td>
            </tr>
          </tbody>
        </table>
      </section>
    </footer>
  );
};

export default Footer;
