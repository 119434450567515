import React, { Fragment, useEffect, useState } from "react";

import LoadingButton from "@atlaskit/button/loading-button";
import Form, { Field, FormHeader, FormSection } from "@atlaskit/form";
import SectionMessage from "@atlaskit/section-message";
import TextField from "@atlaskit/textfield";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";
import CaptchaService from "../../services/CaptchaService";
import CryptoJS from "crypto-js";

import "./LoginForm.css";
import OtpForm from "./OtpForm";

const LoginForm = () => {
  const [error, setError] = useState(false);
  const [validationError, setvalidationError] = useState(null);
  const [unauthorizedUser, setunauthorizedUser] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [showCaptcha, setshowCaptcha] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [passwordExpierd, setpasswordExpiered] = useState(false);
  const history = useHistory();
  const recaptchaRef = React.createRef();
  const [user, setuser] = useState("");

  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    setshowCaptcha(CaptchaService.displayCaptcha());
    autoConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const BannerWarningExample = () => {
    return (
      <div className="SectionMessage">
        <SectionMessage appearance="error">
          Échec de connexion . Vérifiez vos identifiants
        </SectionMessage>
      </div>
    );
  };

  const autoConnection = () => {
    const username_param = queryParams.get("username");
    const password_param = queryParams.get("password");
    if (username_param && password_param) {
      let data = {
        username: username_param,
        password: password_param,
      };
      connecte(data);
    }
  };

  const UnauthorizedUser = () => {
    return (
      <div className="SectionMessage">
        <SectionMessage appearance="error">
          Vous n’êtes pas autorisé à accéder au portail IPA
        </SectionMessage>
      </div>
    );
  };

  const Validation = () => {
    return (
      <div className="SectionMessage">
        <SectionMessage appearance="error">{validationError}</SectionMessage>
      </div>
    );
  };

  const connecte = async (data) => {
    setunauthorizedUser(false);
    setError(false);
    let res = await AuthenticationService.signin(data.username, data.password);
    console.log(res);

    if (res === "Code de vérification envoyé vers votre email") {
      console.log("show  otp ");
      CaptchaService.initTantative();
      setuser(data.username);
      setShowOtp(true);
    } else {
      if (res.accessToken) {
        history.push("/");
      } else {
        loginException(res, data);
      }
    }
  };

  const onChangeRecaptcha = (value) => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  };

  const loginException = (res, data) => {
    if (recaptchaRef.current !== null) {
      recaptchaRef.current.reset();
    }
    if (res.response.status === 401) {
      if (res.response.data.message === "le mot de pass est expire") {
        history.push({
          pathname: "/rest",
          state: {
            username: data.username,
            password: data.password,
            email: res.response.data.email,
            firstname: res.response.data.firstname,
            lastname: res.response.data.lastname,
          },
        });
      }
      setunauthorizedUser(true);
    } else {
      setvalidationError(false);
      setError(true);
    }

    CaptchaService.incrimentTantative();
    setshowCaptcha(CaptchaService.displayCaptcha());
  };

  const onSubmit = async (data) => {
    setunauthorizedUser(false);
    setError(false);
    setvalidationError(null);
    if (data.username === "" && data.password === "") {
      setvalidationError("Veuillez renseigner tous les champs");
    } else {
      if (data.username === "") {
        setvalidationError("Veuillez renseigner le nom d'utilisateur");
      } else {
        if (data.password === "") {
          setvalidationError("Veuillez renseigner le mot de passe");
        } else {
          if (showCaptcha) {
            if (captchaValue === null) {
              setvalidationError("captcha");
              CaptchaService.incrimentTantative();
            } else {
              connecte(data);
            }
          } else {
            connecte(data);
          }
        }
      }
    }
  };

  return (
    <div>
      {" "}
      {showOtp ? (
        <OtpForm username={user} />
      ) : (
        <section className="login-panel">
          <div className="FormHeader">
            <FormHeader>
              <p>Bienvenue dans Workflow IPA</p>
            </FormHeader>
            {error && <BannerWarningExample />}
            {validationError && <Validation />}
            {unauthorizedUser && <UnauthorizedUser />}
          </div>
          <div className="formContainer">
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <FormSection style={{ marginTop: 0 }}>
                    <div className="formFields">
                      <Field
                        aria-required={true}
                        name="username"
                        label="Nom d'utilisateur"
                        isRequired={false}
                        defaultValue=""
                      >
                        {({ fieldProps }) => (
                          <div className="textField">
                            <Fragment>
                              <TextField
                                className="fieldInput"
                                autoComplete="off"
                                {...fieldProps}
                              />
                            </Fragment>
                          </div>
                        )}
                      </Field>
                      <Field
                        aria-required={true}
                        name="password"
                        label="Mot de passe"
                        defaultValue=""
                        isRequired={false}
                      >
                        {({ fieldProps }) => {
                          return (
                            <div className="textField">
                              <Fragment>
                                <TextField
                                  className="fieldInput"
                                  type="password"
                                  {...fieldProps}
                                />
                              </Fragment>
                            </div>
                          );
                        }}
                      </Field>
                    </div>

                    <div>
                      <br />
                      {showCaptcha ? (
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey="6LfJtZsfAAAAALPszPBt3beOuvzCDSk5D_n7VqS8"
                          onChange={onChangeRecaptcha}
                        />
                      ) : null}
                      <div>
                        Pas encore membre ? Pour demander un compte,
                        <br /> Veuillez contacter vos administrateurs Jira.
                      </div>
                      <div className="loginButton">
                        <LoadingButton
                          type="submit"
                          appearance="primary"
                          isLoading={submitting}
                          id="connexionButton"
                        >
                          Connexion
                        </LoadingButton>
                      </div>

                      <div>
                        <a className="linkclass" href="/restRequest">
                          Mot de passe oublié ?
                        </a>
                      </div>
                    </div>
                  </FormSection>
                </form>
              )}
            </Form>
          </div>
        </section>
      )}
    </div>
  );
};

export default LoginForm;
