import React, { useState, useEffect } from "react";
import Searchform from "../../components/User/SearchForm";
import Usertabl from "../../components/User/UserTabl";
import UserService from "../../services/UserService";

const Userlist = () => {
  const [users, setusers] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async (user) => {
    const fetchResult = await UserService.getAlLUsers();
    setusers(fetchResult);
    setloading(false);
  };

  const changeState = (username, newstate) => {
    const newusers = users;
    newusers
      .filter((user) => user.name === username)
      .map((user) => (user.active = newstate));
    setusers(newusers);
  };

  return (
    <div style={{ marginLeft: 50 }}>
      <Searchform getUsers={getUsers} />
      <Usertabl users={users} loading={loading} changestate={changeState} />
    </div>
  );
};

export default Userlist;
