import React from "react";
import Footer from "../../components/footer/Footer";
import HeaderBanner from "../../components/headerBanner/HeaderBanner";
import LoginForm from "../../components/loginComponents/LoginForm";
import "./Login.css";

const FormDefaultExample = () => {
  return (
    <div>
      <div className="headerbannerLogin">
        <HeaderBanner />
      </div>
      <div style={{ backgroundColor: "#f4f5f7", marginTop: 150 }}>
        <div id="form" className="formstyle">
          <LoginForm />
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default FormDefaultExample;
