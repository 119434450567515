import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserService from "../services/UserService";
import { useHistory } from "react-router-dom";

const ITEM_HEIGHT = 48;

export default function LongMenu({ username, state, changestate }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowProfile = () => {
    history.push("/viewProfile?username=" + username);
  };

  const chnageSatat = async () => {
    console.log("change state");

    await UserService.activateUser(username)
      .then((response) => {
        if (response.status === 201) {
          changestate(username, !state);
          console.log("updatetd");
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={chnageSatat}>
          {state ? "DesActiver" : "Activer"}
        </MenuItem>
        <MenuItem onClick={handleShowProfile}>Profile</MenuItem>
      </Menu>
    </div>
  );
}
