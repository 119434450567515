import Panel from "@atlaskit/panel";
import React, { useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import formatDate from "../../helpers/format-date-fr";

const Dates = ({
  created,
  updated,
  effect,
  echeance,
  validation,
  sinistreDate,
  expertiseDate,
  admissionDate,
  recupreDate,
}) => {
  const [screen, setScreen] = useState({});
  const [loading, setLoading] = useState(true);

  const showDates = () => {
    return screen.tabs.map((tab) => {
      return tab.jiraFields
        .filter((field) => field.type === "Sélecteur de date")
        .map((field) => {
          return (
            <Row key={field.name}>
              <Col style={textTitle}>
                <span>{field.name}:</span>
              </Col>
              <Col style={textval}>
                <span>{formatDate(field.allowedValues, false)}</span>
              </Col>
            </Row>
          );
        });
    });
  };

  const textTitle = {
    fontSize: 14,
    color: "#6b778c",
    textAlign: "left",
    fontWeight: "400",
    maxWidth: "40%",
    paddingTop: "1%",
  };

  const textval = {
    fontSize: 14,
    color: "#172b4d",
    textAlign: "left",
    fontWeight: "400",
    paddingTop: "1%",
    marginLeft: "-5%",
  };

  const panelHeader = {
    fontSize: 14,
    color: "#0052CC",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "14px",
  };

  const Header = <span style={panelHeader}>Dates</span>;

  return (
    <div>
      <Panel header={Header} isDefaultExpanded={true}>
        <Container>
          <Row>
            <Col style={textTitle}>Création:</Col>
            <Col style={textval}> {formatDate(created, true)}</Col>
          </Row>

          <Row>
            <Col style={textTitle}>Mise à jour: </Col>
            <Col style={textval}> {formatDate(updated, true)}</Col>
          </Row>
          {effect && (
            <Row>
              <Col style={textTitle}>Date d'effet: </Col>
              <Col style={textval}> {formatDate(effect)}</Col>
            </Row>
          )}
          {echeance && (
            <Row>
              <Col style={textTitle}>Date d'échéance: </Col>
              <Col style={textval}> {formatDate(echeance)}</Col>
            </Row>
          )}
          {validation && (
            <Row>
              <Col style={textTitle}>Date validation ODR: </Col>
              <Col style={textval}> {formatDate(validation)}</Col>
            </Row>
          )}
          {sinistreDate && (
            <Row>
              <Col style={textTitle}>Date du sinistre: </Col>
              <Col style={textval}> {formatDate(sinistreDate)}</Col>
            </Row>
          )}
          {expertiseDate && (
            <Row>
              <Col style={textTitle}>Date prévue de l’expertise: </Col>
              <Col style={textval}> {formatDate(expertiseDate)}</Col>
            </Row>
          )}
          {admissionDate && (
            <Row>
              <Col style={textTitle}>Admission expertise prévisionnelle: </Col>
              <Col style={textval}> {formatDate(admissionDate)}</Col>
            </Row>
          )}
          {recupreDate && (
            <Row>
              <Col style={textTitle}>Client récupère son véhicule le: </Col>
              <Col style={textval}> {formatDate(recupreDate)}</Col>
            </Row>
          )}
        </Container>
      </Panel>
    </div>
  );
};

export default Dates;
