import React from "react";
import { CustomProductHome } from "@atlaskit/atlassian-navigation";
import logoIPA from "../../images/logoIPA.png";

const ProducthomeComp = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return <CustomProductHome href="/" logoAlt="JIRA IPA" logoUrl={logoIPA} />;
};

export default ProducthomeComp;
