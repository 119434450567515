/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import IssueService from "../../services/IssueService";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import formatDate from "../../helpers/format-date-fr";
import AuthenticationService from "../../services/AuthenticationService";

import Lozenge from "@atlaskit/lozenge";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  frFR,
} from "@mui/x-data-grid";

import "./SearchResult.css";
import { isSameYear } from "date-fns";

const Searchresult = ({ issues, loading, project }) => {
  const projects = AuthenticationService.getclim("project");
  const getResumeLink = (params) => {
    return (
      <div className="resumeContent">
        <Link className="linkclass" to={"/issue/" + params.row.cle}>
          {params.row.resume}
        </Link>
      </div>
    );
  };

  const getEtat = (params) => {
    return <Lozenge>{params.row.etat}</Lozenge>;
  };

  const columnsBDG = [
    {
      field: "cle",
      headerName: "Clé",
      width: 150,
      renderCell: (param) => (
        <Link className="linkclass" to={"/issue/" + param.value}>
          {param.value}
        </Link>
      ),
    },
    {
      field: "resume",
      headerName: "Résumé",
      width: 400,
      renderCell: getResumeLink,
    },
    {
      field: "etat",
      headerName: "Etat",
      width: 150,
      renderCell: getEtat,
    },

    // { field: "etat", headerName: "Etat", width: 150 },
    { field: "agence", headerName: "Code agence", width: 150, align: "left" },
    { field: "sinistre", headerName: 'N° de sinistre "ODR"', width: 180 },
    { field: "police", headerName: "N° Police", width: 150 },
    {
      field: "immatruculation",
      headerName: "Immatriculation véhicule",
      width: 150,
    },
    { field: "nom", headerName: "Nom", width: 150 },
    { field: "prenom", headerName: "Prénom", width: 150 },
    { field: "tel", headerName: "N° tél 1", width: 150 },
    {
      field: "adressClient",
      headerName: "Adresse du client",
      width: 400,
    },
    { field: "marque", headerName: "Marque du véhicule", width: 150 },
    {
      field: "modele",
      headerName: "Modèle du véhicule",
      width: 150,
    },
    { field: "responsable", headerName: "Responsable", width: 200 },
    { field: "rapporteur", headerName: "Rapporteur", width: 200 },
    { field: "creation", headerName: "Création", width: 150 },
    { field: "update", headerName: "Mise à jour", width: 150 },
  ];

  const columnsCNMA = [
    {
      field: "cle",
      headerName: "Clé",
      width: 150,
      renderCell: (param) => (
        <Link className="linkclass" to={"/issue/" + param.value}>
          {param.value}
        </Link>
      ),
    },
    {
      field: "resume",
      headerName: "Résumé",
      width: 400,
      renderCell: getResumeLink,
    },
    {
      field: "etat",
      headerName: "Etat",
      width: 150,
      renderCell: getEtat,
    },

    // { field: "etat", headerName: "Etat", width: 150 },
    { field: "agence", headerName: "Code agence", width: 150, align: "left" },
    { field: "sinistre", headerName: 'N° de sinistre "ODR"', width: 180 },
    { field: "police", headerName: "N° Police", width: 150 },
    {
      field: "immatruculation",
      headerName: "Immatriculation véhicule",
      width: 150,
    },
    { field: "nom", headerName: "Nom", width: 150 },
    { field: "prenom", headerName: "Prénom", width: 150 },
    { field: "tel", headerName: "N° tél 1", width: 150 },
    {
      field: "adressClient",
      headerName: "Adresse du client",
      width: 400,
    },
    { field: "marque", headerName: "Marque du véhicule", width: 150 },
    {
      field: "modele",
      headerName: "Modèle du véhicule",
      width: 150,
    },
    { field: "responsable", headerName: "Responsable", width: 200 },
    { field: "rapporteur", headerName: "Rapporteur", width: 200 },
    { field: "creation", headerName: "Création", width: 150 },
    { field: "update", headerName: "Mise à jour", width: 150 },
  ];

  const columnsGIGA = [
    {
      field: "cle",
      headerName: "Clé",
      width: 150,
      renderCell: (param) => (
        <Link className="linkclass" to={"/issue/" + param.value}>
          {param.value}
        </Link>
      ),
    },
    {
      field: "resume",
      headerName: "Résumé",
      width: 400,
      renderCell: getResumeLink,
    },
    {
      field: "etat",
      headerName: "Etat",
      width: 150,
      renderCell: getEtat,
    },

    // { field: "etat", headerName: "Etat", width: 150 },
    { field: "agence", headerName: "Code agence", width: 150, align: "left" },
    { field: "police", headerName: "N° Police", width: 150 },
    {
      field: "immatruculation",
      headerName: "Immatriculation véhicule",
      width: 150,
    },
    { field: "nom", headerName: "Nom", width: 150 },
    { field: "prenom", headerName: "Prénom", width: 150 },
    { field: "tel", headerName: "N° tél 1", width: 150 },
    {
      field: "adressClient",
      headerName: "Adresse du client",
      width: 400,
    },
    {
      field: "vin",
      headerName: "Numéro de chassis (VIN)",
      width: 200,
    },

    { field: "marque", headerName: "Marque du véhicule", width: 150 },
    {
      field: "modele",
      headerName: "Modèle du véhicule",
      width: 150,
    },
    { field: "responsable", headerName: "Responsable", width: 200 },
    { field: "rapporteur", headerName: "Rapporteur", width: 200 },
    { field: "creation", headerName: "Création", width: 150 },
    { field: "update", headerName: "Mise à jour", width: 150 },
  ];

  const columnsDSC = [
    {
      field: "cle",
      headerName: "Clé",
      width: 150,
      renderCell: (param) => (
        <Link className="linkclass" to={"/issue/" + param.value}>
          {param.value}
        </Link>
      ),
    },
    {
      field: "resume",
      headerName: "Résumé",
      width: 400,
      renderCell: getResumeLink,
    },
    {
      field: "etat",
      headerName: "Etat",
      width: 150,
      renderCell: getEtat,
    },
    { field: "responsable", headerName: "Responsable", width: 200 },
    { field: "rapporteur", headerName: "Rapporteur", width: 200 },
    { field: "creation", headerName: "Création", width: 150 },

    { field: "policeDSC", headerName: "Numéro de police", width: 150 },
    { field: "sinistreDSC", headerName: "N° Sinistre", width: 150 },
    {
      field: "raison_sociale",
      headerName: "Nom et Prénom (Raison sociale)",
      width: 150,
    },
    {
      field: "immatriculationDSC",
      headerName: "Immatriculation véhicule",
      width: 150,
    },
    { field: "sinistreDate", headerName: "Date du sinistre", width: 150 },
    { field: "place", headerName: "Lieu du sinistre	", width: 150 },
  ];

  const rows = issues.map((issue) => {
    return {
      id: uuid(),
      cle: issue.key,
      resume: issue.summary,
      etat: issue.etat,
      agence: issue.codeAgence,
      sinistre: issue.NumSinistreODR,
      police: issue.Npolice,
      immatruculation: issue.immatriculation,
      nom: issue.nom,
      prenom: issue.prenom,
      tel: issue.tel1,
      adressClient: issue.adress,
      marque: issue.marque,
      modele: issue.modele,
      vin: issue.vin,
      responsable: issue.assignee ? issue.assignee : "",
      rapporteur: issue.reporter,
      creation: formatDate(issue.created),
      update: formatDate(issue.updated),
      policeDSC: issue.numero_police,
      sinistreDSC: issue.numero_sinistre,
      raison_sociale: issue.raison_sociale,
      immatriculationDSC: issue.immatriculation_vehicule_txt,
      sinistreDate: formatDate(issue.date_sinistre),
      place: issue.lieu_sinistre,
    };
  });

  const chooseColumns = () => {
    if (issues && issues.length > 0) {
      let firstIssue = issues[0];
      console.log(firstIssue.project);

      switch (firstIssue.project) {
        case "BDG":
          return columnsBDG;
        case "GIGA":
          return columnsGIGA;
        case "CNMA":
          return columnsCNMA;
        case "DSC":
          return columnsDSC;
        default:
          return columnsBDG;
      }
    } else {
      return columnsBDG;
    }
  };

  return (
    <div style={{ height: "38vw", width: "100%", backgroundColor: "white" }}>
      <DataGrid
        rowHeight={40}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={chooseColumns()}
        loading={loading}
        initialState={{ pinnedColumns: { left: ["cle"] } }}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: "Liste des tickets-" + formatDate(new Date(), true),
          delimiter: ";",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
}

export default Searchresult;
