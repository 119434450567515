import Lozenge from "@atlaskit/lozenge";
import React from "react";
import { Col, Container, Row } from "react-grid-system";
import SinistreAvatar from "../../images/SinistreAvatar.svg";
import BDGAvatar from "../../images/viewavatar.svg";

import "./InfoIssue.css";

const textTitle = {
  fontSize: 13,
  color: "#97A0AF",
  textAlign: "left",
  fontWeight: "bold",
};

const textval = {
  fontSize: 12,
  color: "#5E6C84",
  textAlign: "left",
};

const handleStyle = (colorName) => {
  console.log(colorName);
  if (colorName === "yellow") {
    return {
      backgroundColor: "#0052CC",
      color: "#FFF",
    };
  } else if (colorName === "blue-gray") {
    return {
      backgroundColor: "#42526e",
      color: "#FFF",
    };
  } else if (colorName === "green") {
    return {
      backgroundColor: "#00875a",
      color: "#FFF",
    };
  } else {
    if (colorName === "inprogress") {
      return {
        display: "none",
      };
    } else {
      return {
        backgroundColor: colorName,
        color: "#FFF",
      };
    }
  }
};

const Infoissue = ({ issueType, issueState, security }) => {
  const issueTypeAvatar = () => {
    if (issueType.name === "Dossier BDG") {
      return <img alt="" src={BDGAvatar} align="center"></img>;
    } else {
      return <img alt="" src={SinistreAvatar} align="center"></img>;
    }
  };
  return (
    <Container>
      <Row>
        <Col style={textTitle} xs={3}>
          <div className="issueInfo">Type</div>
        </Col>

        <Col xs={3}>
          {issueTypeAvatar()}
          {issueType.name}
        </Col>
        <Col style={textTitle} xs={3}>
          <div className="issueInfo">Etat</div>
        </Col>
        <Col xs={3}>
          <Lozenge style={handleStyle(issueState.statusCategory.colorName)}>
            {issueState.name}
          </Lozenge>
        </Col>
      </Row>
    </Container>
  );
};

export default Infoissue;
