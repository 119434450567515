import Panel from "@atlaskit/panel";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import { css } from "@emotion/core";
import React, { useState } from "react";
import { N20, N200 } from "@atlaskit/theme/colors";
import { token } from "@atlaskit/tokens";
import "../../pages/Issue/show/showIssue.css";
import Addcomment from "./AddComment";
import ShowComment from "./ShowComment";

const borderRadius = 5;
const gridSize = 20;

const panelStyles = css({
  display: "flex",
  marginTop: `${gridSize * 2}px`,
  marginBottom: `${gridSize}px`,
  padding: `${gridSize * 4}px`,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  flexGrow: 1,
  backgroundColor: token("color.background.subtleNeutral.resting", N20),
  borderRadius: `${borderRadius}px`,
  color: token("color.text.lowEmphasis", N200),
  fontSize: "4em",
  fontWeight: 500,
});

const panelHeader = {
  fontSize: 16,
  color: "#0052CC",
  textAlign: "left",
  fontWeight: "600",
  paddingLeft: "2px",
};

const Header = (
  <div>
    <span style={panelHeader}>Activité</span>
  </div>
);
const TabBody = () => <div css={panelStyles}></div>;

const Activity = ({ comment, issuekey }) => {
  var commentsfilter = comment.comments.filter((com) => !com.visibility);
  const [comments, setcomments] = useState(commentsfilter);
  return (
    <div>
      <Panel header={Header} isDefaultExpanded={true}>
        <div className="tablistHeader">
          <Tabs>
            <TabList>
              <Tab>Commentaires</Tab>
            </TabList>

            <TabPanel>
              <div className="commentpanel">
                <ShowComment comments={comments} />
                <Addcomment issuekey={issuekey} setComments={setcomments} />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Panel>
    </div>
  );
};

export default Activity;
